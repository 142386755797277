
//Docs for Google analytics / google tags are at https://matteo-gabriele.gitbook.io/vue-gtag/
import VueGtag from "vue-gtag";
import { bootstrap } from 'vue-gtag';
const ageBrackets = ['18-24', '25-34', '35-44', '45-54', '55-64', '65+'].reverse();
export default class GoogleInsights {
	constructor(app, state) {
		if (process.env.VUE_APP_ENV_GA_KEY) {
			var config = {
				id: process.env.VUE_APP_ENV_GA_KEY,
				send_page_view: false, //we send page views ourselves
				enabled: window.allowCookies == true || (state && state.user && state.user.profileId) || (document.cookie?.includes('ac=true'))
			};
			this.buildParams(config);

			this.state = state;
			this.params = config.params;
			app.use(VueGtag, {
				pageTrackerEnabled: false,
				bootstrap: false,
				config: config,
			});
			let bootstrapAll = (resolve) => {
				bootstrap().then((gtag) => {
					//gtag is undefined for some reason...
					this.appInsights = app.config.globalProperties.$gtag;
					resolve(this.appInsights);
					window.document.documentElement.addEventListener("click", (e) => {
						try {
							if (e.target.matches(".btn") || e.target.matches("button") || e.target.fpListeners?.length) {
								var gdata = e.target.getAttribute("gdata");
								this.appInsights.event("select_content",
									{
										"category": "engagement",
										"label": gdata ?? e.target.textContent
									});
							}
						} catch { }
					});
				});
			};
			let settingAllowed = !(process.env.VUE_APP_ENV_USE_COOKIE_WALL === true || process.env.VUE_APP_ENV_USE_COOKIE_WALL === 'true' || process.env.VUE_APP_ENV_USE_COOKIE_WALL === '1');

			if (settingAllowed) {
				window.allowCookies = true;
			}
			let setInsights = () => {
				this.insightsAwait = new Promise((resolve, reject) => {
					if (window.allowCookies == true || (state && state.user && state.user.profileId) || (document.cookie?.includes('ac=true'))) {
						bootstrapAll(resolve);
					} else {
						resolve(null);
					}
				});
			};
			window.addEventListener("cookiesAcceptanceChanged", (e) => {
				if (e.detail?.cookiesAllowed) {
					setInsights();
				} else {
					app.config.globalProperties.$gtag.optOut();
					let cookies = document.cookie?.split(';');
					if (cookies) {
						cookies.forEach(c => {
							let s = c.split("=")[0];
							if (s.indexOf("_ga") == 0 || s.indexOf("__ga") == 0) {
								let d = new Date(0);
								document.cookie = s + "=empt;expires=" + d.toUTCString() + ";path=/";
							}
						});
					}
				}
			});
			setInsights();
		}
	}
	startTrackPage(route) {
		//Google can't really start tracking a page.
	}
	async stopTrackPage(route, envelope) {
		await this.buildCustom();
		//We log a page only when we have the envelope data to send
		let gEnv = this.buildEnvelope(route, envelope);
		//multiple awaits (promise.then) will just keep resulting in the first resolved object.
		(await this.insightsAwait)?.event('page_view', gEnv); //need to use event() with 'page_view' otherwise we can't send the custom mapped data with the pageview.
	}

	async trackEvent(name, envelope) {
		await this.buildCustom();
		let gEnv = this.buildEnvelope(null, envelope);
		gEnv.label = name;
		(await this.insightsAwait)?.event(name, gEnv);
	}
	async buildCustom() {
		(await this.insightsAwait);
		if (!this.appInsights)
			return;


		this.appInsights.customMap({ 'dimension1': 'active_organization' });
		this.appInsights.customMap({ 'dimension2': 'employment_position' });
		this.appInsights.customMap({ 'dimension3': 'activity' });
		this.appInsights.customMap({ 'dimension4': 'organization' });
		this.appInsights.customMap({ 'dimension5': 'talent_match' });
		this.appInsights.customMap({ 'dimension6': 'activity_type' });
		this.appInsights.customMap({ 'dimension7': 'product_identifier' });
		this.configured = true;


		try {
			let newConfig = {};
			this.buildParams(newConfig);
			this.appInsights.config(newConfig?.params ?? {});
			this.params = newConfig.params;

		} catch (e) { console.error(e); }
	}

	buildEnvelope(route, envelope) {
		let gEnv = {};
		if (this.state.organization)
			gEnv.active_organization = this.state.organization.id;
		if (this.state.user?.profileId)
			gEnv.user_id = this.state.user.profileId;
		if (envelope.organization?.id)
			gEnv.organization = envelope.organization.id;
		if (envelope.activity?.id) {
			if (!gEnv.organization && envelope.activity.organizationId)
				gEnv.organization = envelope.activity.organizationId;
			gEnv.activity = envelope.activity.id;
			gEnv.activity_type = envelope.activity.type;
		}
		if (envelope.employmentPosition?.id) {
			if (!gEnv.organization && envelope.employmentPosition.organizationId)
				gEnv.organization = envelope.employmentPosition.organizationId;
			gEnv.employment_position = envelope.employmentPosition.id;
		}
		if (envelope.talentMatch) {
			gEnv.talent_match = envelope.talentMatch.profileId;
		}
		if (envelope.product) {
			if (this.state.user) {
				try {
					var d = new Date();
					gEnv.transaction_id = (state.user.profileId.split("-")[0]) + envelope.product.identifier.slice(-3) + d.getMonth() + "_" + d.getDate();
				} catch { }
			}
			gEnv.product_identifier = envelope.product.identifier;
			gEnv.value = envelope.product.price;
			gEnv.items = [
				{
					item_id: envelope.product.identifier,
					item_name: envelope.product.name,
					price: envelope.product.price,
					quantity: envelope.product.quantity
				}
			];
		}
		if (route) {
			gEnv.page_title = route.name;
			gEnv.page_path = route.path;
		}
		gEnv.page_location = document.location.href;
		return gEnv;
	}
	buildParams(config) {
		if (this.state && this.state.user && this.state.user.profileId) {
			config.params = { user_id: this.state.user.profileId };
			config.user_id = this.state.user.profileId;
			config.params.user_properties = {}; //https://developers.google.com/analytics/devguides/collection/ga4/reference/config#user_properties
			if (this.state.currentProfile) {
				let pr = this.state.currentProfile;
				config.params.user_properties.user_id = pr.id;
				//note that sending your actual gender to google analytics is like super iffy
				if (pr.gender?.toLowerCase() == "male")
					config.params.user_properties.gender = "male";
				else if (pr.gender?.toLowerCase() == "female")
					config.params.user_properties.gender = "female";
				let birth = pr.dateOfBirth ?? pr.birthday; //this will change name at some point.
				if (birth) { //https://support.google.com/analytics/answer/9268042?sjid=3847753561659420599-EU
					let date = new Date(birth);
					var ageDifMs = Date.now() - date.getTime();
					var ageDate = new Date(ageDifMs); // miliseconds from epoch
					let age = Math.abs(ageDate.getUTCFullYear() - 1970); //yeah yeah, can be off by a few hours but that really doesn't matter
					config.params.user_properties.age = ageBrackets.find( //since we reversed the array, the first you find with a starting age smaller than your age is the bracket
						t => parseInt(t) <= age //using the magic of how parsing integers works in javascript
					);
				}
				if (pr.seniority) {
					config.params.user_properties.seniority = pr.seniority;
				}
				if (pr.countryOfBirth) {
					config.params.user_properties.country_of_birth = pr.countryOfBirth;
				}
				if (pr.memberships && pr.memberships.length) {
					config.params.membership = pr.memberships.join(',');
				}
				if (pr.isAnalyticsActive !== undefined) {
					config.params.user_properties.is_active = pr.isAnalyticsActive;
				} else
					config.params.user_properties.is_active = false;
				config.params.user_properties.member_since = pr.createdAt;
			}
			if (this.state.userHasOrganizations) {
				config.params.user_properties.has_organizaton = true;
			}
		}
		else config.params = {};

	}
}