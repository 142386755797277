import { CreditSheet } from "@/core/scoped/entities/Root/Community/Creditsheet";
export class AdminCreditSheet extends CreditSheet {
    static get JSONLDType() { return "CreditSheet"; }
    constructor() {
        super();
    }

    load(source) {
        super.load(source, false);
    }
}