<template>
  <component :is="$attrs.href? 'a':'button'" style="font-size:14px" class="btn btn-logo" :logo="logo"
    :logo-after="logoAfter">
    <img v-if="logo" :src="require('@/assets/logos/' + logo + '.svg')" />
    <div>
      <slot></slot>
    </div>
    <img v-if="logoAfter" :src="require('@/assets/logos/' + logo + '.svg')" />
  </component>
</template>

<script>
export default {
	name: "logo-button",
	props: {
		btnClass: String,
		logo: String,
		logoAfter: String,
	},
	data() {
		return {};
	},
	mounted() { },
};
</script>

