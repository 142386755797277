import { Alert, Tooltip } from "bootstrap";

export class PopupGenerator {
    constructor() {

    }
    btAlert(content, type = "warning", timeout = 3000) {
        var alertString = `<div id="globalAlert" class="alert alert-${type} bg-${type} alert-dismissible fade show" role="alert">
  ${content}
  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>`;
        var myAlert = window.document.getElementById("globalAlert");
        if (!myAlert) {
            var alert = document.createElement("div");
            alert.style.position = "fixed";
            alert.style.right = "20px";
            alert.style.top = "20px";
            alert.style.zIndex = "999";
            alert.innerHTML = alertString;
            window.document.body.append(alert);
        }
        myAlert = window.document.getElementById("globalAlert");
        var alert = Alert.getInstance(myAlert);
        if (!alert) alert = new Alert(myAlert);
        if (timeout)
            setTimeout(() => {
                if (alert) {
                    try {
                        alert.close();
                    } catch { }
                }
            }, timeout);
    }

}
export default {
    methods: {
        btAlert() {
            if (!this.$root.popupGenerator) {
                this.$root.popupGenerator = new PopupGenerator();
            }
            this.$root.popupGenerator.btAlert(...arguments);//makes it that the function follows the signature of the PopupGenerator class
        }
    }
};