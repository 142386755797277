import { Role, RoleArray } from "./Role";

import ActivityApi from "@/core/scoped/services/api/activity";
import CommentApi from "@/core/scoped/services/api/comment";
import OrganizationApi from "@/core/scoped/services/api/organization";
import { BaseModel } from "@/core/scoped/entities/BaseModel";
import { Resource } from "@/core/scoped/entities/Root/Resource";
import { Post } from "@/core/scoped/entities/Root/Post";
import SkillTreeApi from "@/core/scoped/services/api/skilltree";
import state from "@/core/global/state/state";
import { Organization } from "./Organization";

export const ActivityTypes = ["challenge", "bootcamp", "track", "event", "meeting", "masterclass", "symposium", "borrel", "system", "lesson", "journey"];

export class Activity extends BaseModel {
    static get JSONLDType() { return "Activity"; }/** this is just an example for if the type needs to be different from the class name */
    static api = new ActivityApi();
    static skillApi = new SkillTreeApi();
    static organizationApi = new OrganizationApi();
    static commentApi = new CommentApi();
    #headerThumbUrl;
    #currentRoles;
    #relevantRoles;
    #linkedResources;
    #customId;
    #hasReviewed;
    #timeline;
    #storedProgress;
    #relatedSkills;

    get customId() {
        if (this.#customId)
            return this.#customId;
        else if (this.title)
            return this.title.toLowerCase().slice(0, 28).trim().replaceAll(" ", "-");
    }

    get isLearningType() {
        return this.type == 'lesson' || this.type == 'masterclass' || this.type == 'bootcamp' || this.type == 'tutorial';
    }
    get hasCredit() {
        var bucket = this.type; //Eventually we want to create a mapping for this or something
        return state.activeMemberships && state.activeMemberships.find(m =>
            m.activePeriod.remainingActivityCredit &&
            (Object.keys(m.activePeriod.remainingActivityCredit).some(key => key.toLowerCase() === bucket && m.activePeriod.remainingActivityCredit[key] != null && m.activePeriod.remainingActivityCredit[key] > 0) ||
                !Object.keys(m.activePeriod.remainingActivityCredit).some(key => key.toLowerCase() === bucket))
        ) != null;
    }

    set customId(value) {
        this.#customId = value;
    }

    get durationText() {
        if (this.start && this.end) {
            return this.start.getDifferenceString(this.end, 3);
        }
        return null;
    }
    get hasReviewed() {
        return this.#hasReviewed;
    }

    set hasReviewed(value) {
        this.#hasReviewed = value;
    }

    get rateable() {
        if (!this.publishDateTime) return false;
        if (this.end && this.end > new Date()) return false;
        return true;
    }

    get canRate() {
        return state.user && this.rateable && this.currentRoles.hasLevel("member", "active") && !this.hasReviewed;
    }

    async getOrganization() {
        if (!this.organizationId) return null;
        return await Activity.organizationApi.get(this.organizationId);
    }

    get customRouterId() {
        return this.customId?.split("/");
    }

    get publishedUpdates() {
        if (this.updates && this.updates.length > 0) {
            return this.updates.filter((f) => {
                return f.isPublished;
            });
        }
    }

    get isOnDemand() {
        return this.start == null && this.end == null;
    }

    get currentRoles() {
        if (!this.#currentRoles)
            this.#currentRoles = new RoleArray([]);
        return this.#currentRoles;
    }

    set currentRoles(value) {
        if (value instanceof RoleArray)
            this.#currentRoles = value;
        else this.#currentRoles = new RoleArray(value);
    }

    get relevantRoles() {
        if (!this.#relevantRoles)
            this.#relevantRoles = new RoleArray([]);
        return this.#relevantRoles;
    }

    set relevantRoles(value) {
        if (value instanceof RoleArray)
            this.#relevantRoles = value;
        else this.#relevantRoles = new RoleArray(value);
    }

    get openForSubscribtion() {
        if (!this.subscribeDeadline) return false;
        var diff = this.subscribeDeadline.getTime() - new Date().getTime();
        if (diff < 0) return false;
        return true;
    }

    get allowComments() {
        return state.user && this.commentableBy == "open";
    }

    get hasTriedToApply() {
        return state.user && this.currentRoles &&
            (this.currentRoles.hasLevel("member", "accessRequested") ||
                this.currentRoles.hasLevel("member", "blocked") ||
                this.currentRoles.hasLevel("member", "accessDenied") ||
                this.currentRoles.hasLevel("member", "active")
            );
    }

    get canJoin() {
        return this.openForSubscribtion && !this.currentRoles.hasLevel("member", "accessRequested") && state.user;
    }

    get isFollowed() {
        return this.currentRoles.hasLevel("viewer");
    }

    get canEdit() {
        return this.currentRoles.hasLevel("superAdmin", "admin", "owner");
    }

    get typeName() {
        return this.type.charAt(0).toUpperCase() + this.type.slice(1);

    }

    get linkedResources() {
        return this.#linkedResources;
    }

    set linkedResources(value) {
        this.#linkedResources = value;
        this.orderResources();
    }

    get untillSubscribeDeadlineText() {
        var diff = new Date(this.subscribeDeadline).getTime() - new Date().getTime();
        diff = diff / (1000 * 60 * 60 * 24);
        diff = Math.round(diff);
        if (diff > 0) {
            if (diff > 7) {
                return Math.round(diff / 7) + " week" + (diff >= 14 ? "s" : "");
            } else {
                return diff + " day" + (diff >= 2 ? "s" : "");
            }
        } else {
            if (this.type === "challenge") return "Closed";
            else return null;
        }
    }

    get startDateText() {
        if (this.start) {
            return this.start.toShortText(true);
        } else return '';
    }
    get startDateLongText() {
        if (this.start) {
            return this.start.longTimezoneDate;
        } else return '';
    }

    get faqs() {
        if (this.type != "challenge") return [];
        return [
            {
                question: "What does an AI for Good Challenge mean?",
                answer: "An AI for Good Challenge is a 10-week-long project where we solve real-world data problems with AI. Each Challenge has to meet our AI for Good criteria:\n\n- The problem addresses one of the UN 17 Sustainable Development Goals.\n- The Challenge involves a stakeholder that has a direct advantage of the Challenge results.\n"
            },
            {
                question: "Why Challenge Based Learning (CBL)?",
                answer: "Challenge Based Learning is an engaging approach where you tackle real-world problems, fostering critical thinking and problem-solving skills. Instead of traditional lectures, it emphasizes hands-on experiences, collaboration, communication, planning and creative solutions. It empowers you to apply your knowledge to address practical challenges, making the learning experience more dynamic and applicable to the world around you."
            },
            {
                question: "What does an AI for Good Challenge timeline look like?",
                answer: "Every AI for Good Challenge takes about 10 weeks time. You start with 2 weeks of Masterclasses & Research to get the essential theory.\n\nThen it's time to put theory into practice! We start experimenting with various solutions until we have found the most promising path to take. For the rest of the Challenge we work on implementing that solution and documenting the progress.\n\nAfter completing a Challenge a peer assessment will take place which accredits your new professional skills. Based on the assessment and the evaluation with your mentor you'll get certified.\n\nIf you're curious about a specific time line of a Challenge, please see the timeline of the Challenge of your choice."
            },
            {
                question: "I do not operate in the field of AI (Engineering), can I start a Challenge as well?",
                answer: "Yes you can! Next to AI and software engineers we need various talents to solve a Challenge. We need challenge-specific domain experts (wind energy, predictive maintenance, remote sensing, etc.), great communicators and storytellers, coordinators and project & product managers."
            },
            {
                question: "What does it cost to participate in a Challenge and achieve my Learning goals?",
                answer: "Our goal is to make AI education available for people all over the world and create a valuable learning experience for everyone involved. Therefore we offer several pricing plans. Our one-off pricing starts at €249 and the subscription plans at €79 a month."
            },
            {
                question: "What is the difference between the Basic and Premium subscription plan?",
                answer: "Our goal is to meet your learning needs at all times. Both Basic and Premium plans will allow you to join unlimited Challenges, but we offer you two different learning methods you can choose from:\n\n- Basic: you will learn peer-to-peer from experts that are carefully selected to guide you through all phases of the Challenge to reach your learning goals.\n\n- Premium: next to the peer-to-peer learning you will get personal 1-on-1 mentoring. Together with your mentor you do a skill assessment and set corresponding learning goals. You will get a personalised year path to make sure you reach your goals."
            },
            {
                question: "How much practical knowledge on AI implementation would I be able to gain if I participate in a Challenge?",
                answer: "Past participants say they’ve gained an average of 2.5 points on a scale of 0 (no knowledge of AI) to 10 (absolute AI expert). So for example: if you start with basic Python and data science skills, you’ll be able to learn enough in a Challenge to implement AI in the real world by the end."
            },
            {
                question: "Can I participate in a Challenge for free?",
                answer: "We've got limited free seats reserved for experts in the field and engineers who bring an exceptional skill set to the table and can guide junior AI specialists.\n\nIf you want to join as an expert we'll determine, based on your current skill set, whether you will be accepted to the Challenge."
            },
            {
                question: "Is it possible to start a Challenge with colleagues to form a team?",
                answer: "Yes you can. A lot of companies choose FruitPunch AI to educate their employees on new and exciting AI technologies. Our enterprise offering allows subscribing in teams so let's get in touch!"
            },
            {
                question: "How much time do I need to invest if I participate in a Challenge?",
                answer: "4-8 hours a week over 10 weeks."
            },
            {
                question: "How many people are accepted for a Challenge?",
                answer: "For each challenge, we organize a group of up to 30 AI engineers to address the issue and come up with a production-ready AI in 10-weeks time. It’s worth noting that participants that are following a paid plan receive priority to join a Challenge."
            },
            {
                question: "Do I get a certificate after completing a Challenge?",
                answer: "Yes you do! If you're on the paid plan you'll get an official FruitPunch certificate that accredit the specific skills you showed and excelled at during the Challenge. Once awarded you can connect this to your LinkedIn to show the world what you can do."
            },
            {
                question: "Can I pay by invoice?",
                answer: "Yes you sure can. Please send an email to sales@fruitpunch.ai"
            },
            {
                question: "Is it possible to change the billing address so I can use the self-deployment budget from my employer?",
                answer: "Yes, definitely! Please send an email to sales@fruitpunch.ai"
            }
        ];
    }

    get timingStatus() {
        return this.statusForDate(this.start, this.publishDateTime ?? null, this.end)
    }
    get timingBadge() {
        let c = new Date();

        if (!this.publishDateTime || this.publishDateTime > c)
            return { text: 'Not published', color: 'danger' };
        if (this.end < c)
            return { text: 'Completed', color: 'tertiary-600' };
        if (this.start < c)
            return { text: 'In progress', color: 'dark' };
        if (this.subscribeDeadline > c)
            return { text: 'Submissions open', color: 'info' };
        let f = this.timingStatus;
        f.color = f.status;
        //return f;
        return null;


    }

    get headerThumbUrl() {
        if (this.#headerThumbUrl == null) {
            // Load the image        
            if (this.resources) {
                var thumb = this.resources.find(f => {
                    this.#headerThumbUrl = f.resourceLocation && f.resourceLocation.searchParams && f.resourceLocation.searchParams.get("locationIdentifier") == "headerthumb";
                });
                if (thumb) {
                    thumb.resourceLocation.searchParams.set("redirect", "1");
                    this.#headerThumbUrl = thumb.resourceLocation;
                }
            }
            if (this.headerImage != null) {
                let url = this.headerImage.resourceLocation;
                url.searchParams.set("redirect", "1");
                this.#headerThumbUrl = url;
            }
            else this.#headerThumbUrl = new URL(window.apiSettings.URL + "/activity/" + this.id + "/resource?locationIdentifier=headerthumb&redirect=1");
        }
        return this.#headerThumbUrl;
    }
    get backupHeaderUrl() {
        return window.apiSettings.URL + "/activity/" + this.id + "/resource?locationIdentifier=header&redirect=1";
    }

    get canonicalUrl() {
        return process.env.VUE_APP_ENV_BASE_URL + "/" + this.type + "/" + (this.customId ?? this.id);
    }

    get timeline() {
        if (this.#timeline !== null && this.#timeline !== undefined)
            return this.#timeline;
        var line = [];
        if (this.start) line.push(this.getTimelineItem(this.id + "-start", this.start, this.type + " kickoff", this.title + " kickoff", null, null, null, this.toRoute));
        if (this.subscribeDeadline)
            line.push(this.getTimelineItem(this.id + "-deadline", this.subscribeDeadline, "application deadline", this.title + " application deadline", null, null, null, this.toRoute));
        if (this.end) line.push(this.getTimelineItem(this.id + "-end", this.end, this.type + " end", this.title + " end", null, null, null, this.toRoute));
        if (this.childActivities) {
            for (var i = 0; i < this.childActivities.length; i++) {
                if (this.childActivities[i].start) {
                    line.push(
                        this.getTimelineItem(
                            this.childActivities[i].id,
                            this.childActivities[i].start,
                            this.childActivities[i].title,
                            this.title + " - " + this.childActivities[i].title,
                            this.childActivities[i].subtitle,
                            this.childActivities[i].end,
                            this.childActivities[i].locationDescription,
                            this.childActivities[i].toRoute

                        )
                    );
                }
            }
        }
        line.sort(function (a, b) {
            return a.date - b.date;
        });
        this.#timeline = line;
        return line;
    }

    get completedPercentage() {
        if (this.#storedProgress !== null)
            return this.#storedProgress;
        else {
            if (this.end != null) {

                let nd = new Date().getTime();
                if (nd - this.end.getTime() > 0)
                    return 100;
                if (this.start != null) {
                    let diff = this.end.getTime() - this.start.getTime();
                    let norm = nd - this.end.getTime();
                    if (norm <= 0)
                        return null;
                    else return Math.min(100, (norm / diff) * 100);
                }
            }
            return 0;
        }
    }
    set completedPercentage(v) {
        this.#storedProgress = v;
    }
    get toRoute() {
        return { name: 'activityFullPage', params: { type: this.type ?? 'activity', id: this.customRouterId ?? this.id } }
    }
    constructor() {
        super();
    }

    load(source, freeze = true) {

        super.load(source, false);
        super.makeTypeGetSet('childActivities', Array.of(Activity));
        super.makeDateGetSet('publishDateTime');
        super.makeDateGetSet('start');
        super.makeDateGetSet('end');
        super.makeDateGetSet('requestedPublishDateTime');
        super.makeDateGetSet('subscribeDeadline');
        //Next bits should no longer be needed when using @type everywhere
        super.makeTypeGetSet('headerImage', Resource);
        super.makeTypeGetSet('mainPageVideo', Resource);
        super.makeTypeGetSet('mainVideoThumbnail', Resource);
        super.makeTypeGetSet('updates', Array.of(Post));
        super.makeTypeGetSet('results', Array.of(Post));
        super.makeTypeGetSet('resources', Array.of(Resource));
        super.makeTypeGetSet('partners', Array.of(Organization));

        // Commented because watchers don't trigger for entities.
        //if (freeze)
        //    Object.preventExtensions(this);
    }
    statusForDate(start, publishDateTime, end) {
        var now = new Date();

        if (publishDateTime === null || publishDateTime > now) {
            return {
                text: "Not published",
                status: "danger",
                showsDate: false,
                hasStarted: false
            };
        }

        if (start) {
            if (now < start) {
                var daysDifferenceUntilStart = Math.round((start - now) / (1000 * 60 * 60 * 24));

                if (daysDifferenceUntilStart == 0) {
                    return {
                        text: "Starts today",
                        status: 'warning',
                        showsDate: false
                    };
                } else if (daysDifferenceUntilStart > 0 && daysDifferenceUntilStart <= 10) {
                    return {
                        text: "Starts in " + daysDifferenceUntilStart + " days",
                        status: daysDifferenceUntilStart <= 3 ? 'warning' : 'info',
                        showsDate: false
                    };
                } else {
                    let month = (start.getMonth() + 1);
                    if (month < 10)
                        month = "0" + month;
                    let date = start.getDate();
                    if (date < 10)
                        date = "0" + date;
                    return {
                        text: "Starts " + date + "/" + month + "/" + start.getFullYear(),
                        status: 'info',
                        showsDate: true
                    };
                }
            } else {
                if (end && now > end) {
                    return {
                        text: "Completed",
                        status: 'tertiary-600',
                        showsDate: false,
                        hasStarted: true
                    };
                } else {
                    return {
                        text: "In progress",
                        status: 'gray',
                        showsDate: false,
                        hasStarted: true
                    };
                }
            }
        } else if (end) {
            if (now < end) {
                return {
                    text: "In progress",
                    status: 'gray',
                    showsDate: false,
                    hasStarted: true
                };
            }
        }
        return null;
    }
    getTimelineItem(id, date, name, calTitle, description, endDate, location, to) {
        let end = null;
        if (endDate) end = new Date(endDate);
        if (date && !date.getDate)
            date = new Date(date);
        let format = {
            hour12: false,
            day: "2-digit",
            weekday: "short",
            month: "short",
            hour: "2-digit",
            minute: "2-digit",
            timeZoneName: "shortGeneric", //On some mobile devices they don't know what shortGeneric means, no clue why
        };
        let longerDate = null;
        try {
            longerDate = `${date.toLocaleDateString('en-GB', format)}`;
        } catch {
            try {
                format.timeZoneName = 'short';
                longerDate = `${date.toLocaleDateString(undefined, format)}`;

            } catch {
                format.timeZoneName = undefined;
                longerDate = `${date.toLocaleDateString(undefined, format)}`;
            }
        }
        return {
            date: date,
            id: id,
            end: end,
            name: name,
            to: to,
            calTitle: calTitle,
            location: location,
            description: description ? description : calTitle,
            dateString: date ? date.toLongString() : "",
            longDateString: longerDate,
            timingStatus: this.statusForDate(date, undefined, end)
        };
    }

    async getRelatedSkills() {
        if (this.relatedSkills)
            if (this.#relatedSkills == null) {
                let allSkills = await Activity.skillApi.getAllSkills();
                this.#relatedSkills = Object.values(allSkills).filter(v => this.relatedSkills.find(r => r == v.id) !== undefined);
            }
        return this.#relatedSkills;
    }

    removeRoles() {
        this.currentRoles = this.currentRoles.filter(r => r.type != 'activity');
    }

    orderResources() {
        let srt = (a, b) => { if (a.name && b.name) return a.name.localeCompare(b.name); else return a.url.toString().localeCompare(b.url.toString()); };
        this.allResources = this.arrayResources().sort(srt);

        super.makeTypeGetSet('videos', Array.of(Resource));
        super.makeTypeGetSet('notebooks', Array.of(Resource));
        super.makeTypeGetSet('otherData', Array.of(Resource));
        this.videos = this.allResources.filter((r) => r.dataType == "video").sort(srt);
        this.notebooks = this.allResources.filter((r) => r.dataType == "notebook").sort(srt);
        this.otherData = this.allResources.filter((r) => r.dataType != "notebook" && r.dataType != "video").sort(srt);
        let overviewString = "";
        if (this.videos.length) overviewString += this.videos.length + " video" + (this.videos.length > 1 ? "s" : "");
        if (this.notebooks.length) {
            if (this.videos.length) overviewString += " • ";
            overviewString += this.notebooks.length + " notebook" + (this.notebooks.length > 1 ? "s" : "");
        }
        if (this.otherData.length) {
            if (this.videos.length || this.notebooks.length) overviewString += " • ";
            overviewString += this.otherData.length + " Other data";
        }
        this.overviewString = overviewString;
    }

    arrayResources(source = this.linkedResources, result = []) {
        if (source && source.contents) {
            source.contents.forEach((dir) => {
                if (dir.isFolder) this.arrayResources(dir, result);
                else result.push(dir);
            });
        }
        return result;
    }
}

export class ActivityIndex extends Activity {
    static get JSONLDType() { return "activityIndex"; }/** this is just an example for if the type needs to be different from the class name */
}
