import ProfileApi from "@/core/scoped/services/api/profile.js";
import apiClient from "./api";
import state from "@/core/global/state/state";
export default class CommunityProfileApi extends ProfileApi {

    constructor() {
        super();
    }

    async getProfile(ignoreCache) {
        if (state.prfd == 1 || state.prfd == '1')
            ignoreCache = true;
        state.unregisterStored('prfd');
        if (state.currentProfile && !ignoreCache) {
            if (!ProfileApi.currentProfile) {
                ProfileApi.currentProfile = apiClient.modelBuilder.getModel(state.currentProfile);
            }
            return ProfileApi.currentProfile;
        }
        const response = await apiClient.standardGet(this.subURL);
        if (response == null || !response['@type']) {
            apiClient.clearCache(this.subURL);
            return response;
        }
        state.registerStored('currentProfile', response);
        apiClient.clearCache("/career/journey/base");
        if (response.activeJourneyIds && response.activeJourneyIds.length) {
            for (let i = 0; i < response.activeJourneyIds.length; i++) {
                if (response.activeJourneyIds[i].journeyActivityId)
                    apiClient.clearCache("/career/active/" + response.activeJourneyIds[i].journeyActivityId);
            }
        }

        ProfileApi.currentProfile = apiClient.modelBuilder.getModel(state.currentProfile);
        return ProfileApi.currentProfile;
    }
    markProfileDirty() {
        state.registerStored('prfd', '1');
    }
    async getFollowing(max = 3) {
        return await apiClient.standardGet(this.subURL + "activities/following?take=" + max);
    }
    async deleteEmail(emailToRemove) {
        if (emailToRemove && emailToRemove.length > 0) {
            return await apiClient.delete(this.subURL + "email?email=" + emailToRemove);
        }
    }

    async addPublicExternalProfileSource(provider, publicUrl) {
        return await apiClient.standardPost(this.subURL + "sources/external/public?provider=" + provider + "&publicUrl=" + publicUrl);
    }

    async hasCompletedOnboarding() {
        return await apiClient.standardGet(this.subURL + "onboarding/profile/completed");
    }
}
