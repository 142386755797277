//The state class allows manipulation of the static state of the application
//We have both a session state and a globally persistent state.
//if you add a property to the state it becomes session state by default.
//You can add a stored variable by doeing registerStored.
//If you change any value on any property or object you have registerd on the state it always gets automatically stored.
//This whole state works fine with Vue in that changing a value in one component also causes the change in any other componenet
//There's no need for any eventing.
import EventEmitter from 'events';
import { BaseModel } from "@/core/scoped/entities/BaseModel";
import { reactive, watch, ref, toRaw, unref } from 'vue'
class State extends EventEmitter {
    #storedChangeTimer;
    #sessionChangeTimer;
    constructor() {
        super();
        this._evProps = [];
        this._evProps.push("_evProps");
        for (var n in this) {
            this._evProps.push(n);
        }
        this.storedState = {};
        this.sessionState = {};
        this.IEStoredState = {};
        this.IESessionState = {};
        this.proxyUndefined = !window.Proxy;
        let me = this;
        if (!window.staticClient) {
            //we do the next bit to prevent circular referencing to the api and it's modelbuilder.
            //We only need the modelbuilder and we only need it to make proper models from the models in the state.
            //So when we see that the client is set we reinitialize to get all the proper models
            Object.defineProperties(window, {
                _staticClient: {
                    value: null,
                    writable: true
                },
                staticClient: {
                    get: () => {
                        let me = this;
                        if (window._staticClient != null && window._staticClient.entitiesUpdated == null) {
                            window._staticClient.entitiesUpdated = () => {
                                me.initializeState();
                            }
                        }
                        return window._staticClient;
                    },
                    set: (val) => {
                        window._staticClient = val;
                        let me = this;
                        window._staticClient.entitiesUpdated = () => {
                            me.initializeState();
                        }
                        this.initializeState();
                    }
                }
            });
        }
        if (window.Proxy) {
            this.proxy = new Proxy(this, {
                get(target, name, receiver) {
                    let result = undefined;
                    if (target.sessionState[name]) {
                        result = target.sessionState[name];
                    }
                    if (target.storedState[name]) {
                        result = target.storedState[name];
                    }
                    if (result !== undefined) {
                        if (result.value !== undefined)
                            return unref(result);
                        return result;
                    }
                    return Reflect.get(target, name, receiver);
                },
                set(target, name, value, receiver) {
                    if (value == target || value == target.storedState || value == target.sessionState) {
                        return;
                    } else if (name == "storedState") {
                        return;
                    }
                    if (target._evProps.includes(name)) {
                        return Reflect.set(target, name, value, receiver);//some of the properties of the event emitter getting set.
                    }
                    if (target.storedState[name]) {
                        target.storedState[name] = value;
                        target.stateChanged(true);
                    } else if (target.sessionState[name]) {
                        target.sessionState[name] = value;
                        target.stateChanged();
                    } else {
                        target.registerSession(name, value);
                    }
                    target.emit('changed', name, value);
                    return true;
                },
                getPrototypeOf(target) {
                    return me;
                }
            });
        }
        this.firstMessageReceived = false;
        this.finishInitialization();
    }
    finishInitialization() {
        let me = this;
        this.initializeState();
        if (me.storedState['user']) {
            if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
                navigator.serviceWorker.controller.postMessage({ bearerToken: me.storedState['user'].bearerToken });
            }
        }
        if ('serviceWorker' in navigator && navigator.serviceWorker) {
            navigator.serviceWorker.addEventListener('message', (m) => { this.newSessionReceived(m); });
            if (navigator.serviceWorker.controller) {
                navigator.serviceWorker.controller.postMessage({ action: "setSessionStorage" });//make sure we keep our session storages the same accross all open windows
            } else {
                setTimeout(() => {
                    if (navigator.serviceWorker.controller) {
                        navigator.serviceWorker.controller.postMessage({ action: "setSessionStorage" });//make sure we keep our session storages the same accross all open windows
                    }
                }, 300);
            }
        }
        window.addEventListener("storage", (ev) => { //Happens whenever another window makes a storage.
            me.blockStorageAction = true; //Need to prevent this window from writing to storage as it's already in storage
            try {
                let newState = localStorage.getItem("state");
                if (newState != undefined) {
                    newState = JSON.parse(newState);
                    if ((newState.user && !me.storedState.user) || (!newState.user && me.storedState.user) ||
                        (newState.organization && !me.storedState.organization) || (!newState.organization && me.storedState.organization)) {
                        me.unregisterSession("accessChecked");
                        document.location.reload(); //went from user to no user or organization to no organization or other way around
                        return;
                    }
                    if (newState.organization && me.storedState.organization) {
                        if (newState.organization.id != me.storedState.organization.id) {
                            me.unregisterSession("accessChecked");
                            document.location.reload();//changed organization
                            return;
                        }
                    }
                    let initFunc = (obj, v) => {
                        //This function may seem cumbersome but this is to make sure that any watchers on inner object values get triggered
                        //The ideal situation would be that we can "call" other tabs through the state.
                        if (obj instanceof Object) {
                            for (let n in v) {
                                if (v[n] instanceof Object) {
                                    try {
                                        if (obj[n] !== undefined)
                                            initFunc(obj[n], v[n]); //proxy setters already set
                                        else {
                                            obj[n] = v[n];
                                            obj[n] = me.recursiveCreateObjectListeners(obj[n], true);
                                        }
                                    } catch (e) {
                                        console.error(e);
                                        obj[n] = v[n];
                                    }
                                } else obj[n] = v[n];
                            }
                            for (var n in obj) {
                                if (v[n] === null || v[n] === undefined)
                                    obj[n] = null;
                            }
                        }
                    };
                    initFunc(me.storedState, newState);
                    //some other window made a localStorage change, reinitialize the entire storage
                } else if (me.storedState?.user) {
                    document.location.reload(); //no new state but this window had a state so effectively log out.
                    return;
                }
                me.emit('externalUpdate', this);
            } finally {
                me.blockStorageAction = false;
            }
            //this, not me
        });
        setTimeout(() => {
            me.firstMessageReceived = true;
        }, 4000); //Wait a while for a message about new sessions. should be soon since we "request one"
    }
    newSessionReceived(message) {
        if (message.data.action) {
            if (message.data.action == 'setSessionStorage') {
                this.firstMessageReceived = true;
                if (message.data.value) {
                    let json = JSON.stringify(message.data.value);
                    sessionStorage.setItem("state", json);
                    this.servicedUpdate = true; //block us from posting message about our session storage updating
                    this.initializeState(true);
                    setTimeout(() => {
                        this.servicedUpdate = false; //just to be safe, unblock with a delay
                    }, 50);
                }
            }
        }
    }

    deleteAll() {
        for (let n in this.storedState) this.unregisterStored(n);
        for (let n in this.sessionState) this.unregisterSession(n);
    }

    registerStored(name, object) {
        object = this.recursiveCreateObjectListeners(object, true);
        this.storedState[name] = object;
        this.stateChanged(true);
        return object;
    }
    registerSession(name, object) {
        object = this.recursiveCreateObjectListeners(object, false);
        this.sessionState[name] = object;
        this.stateChanged(false);
        return object;
    }
    unregisterStored(name) {
        localStorage.removeItem(name);
        delete this.storedState[name];
        this.stateChanged(true);
    }
    unregisterSession(name) {
        sessionStorage.removeItem(name);
        delete this.sessionState[name];
        this.stateChanged(false);
    }
    getSession(name) {
        return this.sessionState[name];
    }
    getStored(name) {
        return this.storedState[name];
    }

    setSessionState(obj) {
        for (var n in obj) {
            this.sessionState[n] = obj[n];
        }
        this.stateChanged(false);
    }
    setStoredState(obj) {
        for (var n in obj) {
            this.storedState[n] = obj[n];
        }
        this.stateChanged(true);
    }

    recursiveCreateObjectListeners(object, stored) {
        if (object == this || object instanceof State)
            return;
        let apiClient = window.staticClient;
        if (apiClient && apiClient.modelBuilder) {
            if (!object['@type'] || !object['@type'].toLowerCase().includes('profileJourney'))
                object = apiClient.modelBuilder.getModel(object);
        }
        let cobj = null;
        try {
            if (object instanceof Object || Array.isArray(object))
                cobj = reactive(object);
            else
                cobj = ref(object);
        } catch {
            cobj = ref(object);
        }
        watch(cobj, (o, n) => {
            if (!this.blockStorageAction)
                this.stateChanged(stored);
            window.setTimeout(() => {
                this.emit('changed', o, n);
            }, 0);
        })
        return cobj;

    }
    stateChanged(stored) {
        let me = Object.getPrototypeOf(this);
        try {
            if (!(Object.getPrototypeOf(this) instanceof State) && (this instanceof State)) {
                me = this;
            }
        } catch (e) { console.error(e); }
        if (stored) {
            let change = () => {
                me.#storedChangeTimer = null;
                let json = JSON.stringify(this.toRaw(this.storedState));
                localStorage.setItem("state", json);
            };
            if (!me.#storedChangeTimer) { //timers are used to prevent too many calls to json stringify
                change();
                me.#storedChangeTimer = setTimeout(() => { me.#storedChangeTimer = null; }, 1);
            } else {
                clearTimeout(me.#storedChangeTimer);
                me.#storedChangeTimer = setTimeout(change, 1);
            }
        } else {
            let change = () => {
                me.#sessionChangeTimer = null;
                let json = JSON.stringify(this.toRaw(this.sessionState));
                sessionStorage.setItem("state", json);
                if (!this.servicedUpdate && this.firstMessageReceived) {
                    if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
                        navigator.serviceWorker.controller.postMessage({ action: "setSessionStorage", value: JSON.parse(json) });
                    }
                }
            };
            if (!me.#sessionChangeTimer) { //timers are used to prevent too many calls to json stringify
                change();
                me.#sessionChangeTimer = setTimeout(() => { me.#sessionChangeTimer = null; }, 1);
            } else {
                clearTimeout(me.#sessionChangeTimer);
                me.#sessionChangeTimer = setTimeout(change, 1);
            }

        }
    }


    initializeState(sessionOnly = false) {
        let sessionStateJson = sessionStorage.getItem("state");
        if (sessionStateJson) {
            try {
                this.sessionState = JSON.parse(sessionStateJson);
                for (let n in this.sessionState) {
                    this.sessionState[n] = this.recursiveCreateObjectListeners(
                        this.sessionState[n],
                        false
                    );
                }
            } catch (ex) {
                console.error("session state loading failed", ex);
                this.sessionState = {};
            }
        }
        let storedStateJson = null;
        if (!sessionOnly)
            storedStateJson = localStorage.getItem("state");
        if (storedStateJson) {
            try {
                this.storedState = JSON.parse(storedStateJson);
                if (this.storedState.currentJourney)
                    delete this.storedState.currentJourney
                for (let n in this.storedState) {
                    this.storedState[n] = this.recursiveCreateObjectListeners(
                        this.storedState[n],
                        true
                    );
                }
            } catch (ex) {
                console.error("stored state loading failed");
                this.storedState = {};
            }
        }
    }
    toRaw(stateitem) {
        let item = {};
        for (let n in stateitem) {
            item[n] = toRaw(unref(stateitem[n]));
        }
        return item;
    }
}
export const stateObject = new State();
if (!window.globalState) {
    window.globalState = stateObject.proxy;
}
export default window.globalState;
