import { BaseModel } from "@/core/scoped/entities/BaseModel";
import state from "@/services/state/state";
import ActivityApi from "@/core/scoped/services/api/Community/activity";
import CommentApi from "@/core/scoped/services/api/Community/comment";
import AdminCommentApi from "@/core/scoped/services/api/SysAdmin/comment";

export class CommunityComment extends BaseModel {

    static get JSONLDType() { return "Comment"; }
    #activityApi = new ActivityApi();
    #commentApi = new CommentApi();
    #adminCommentApi = new AdminCommentApi();
    #forceDeleted;
    #likeCount;
    #liked;
    #showingReplySection;

    constructor() {
        super();
        this.makeTypeGetSet('replies', Array.of(CommunityComment));
    }

    get liked() {
        if (this.#liked == null || this.#liked == undefined) {
            if (this.replies && this.replies.length && state.user && state.user.profileId) {
                this.#liked = this.replies.find((f) => f.profileId == state.user.profileId && f.commentType == "like") != null;
            } else {
                this.#liked = false;
            }
        }
        return this.#liked;
    }

    set liked(value) {
        this.#liked = value;
    }

    get likeCount() {
        if (this.#likeCount == null || this.#likeCount == undefined) {
            this.#likeCount = this.replies?.filter((f) => f.commentType == "like")?.length ?? 0;
        }
        return this.#likeCount;
    }

    set likeCount(value) {
        this.#likeCount = value;
    }

    get textReplies() {
        return this.replies?.filter((f) => f.commentType == "text" || !f.commentType);
    }

    get showingReplySection() {
        return this.#showingReplySection;
    }

    set showingReplySection(value) {
        this.#showingReplySection = value;
    }

    get forceDeleted() {
        return this.#forceDeleted;
    }

    set forceDeleted(value) {
        this.#forceDeleted = value;
    }

    like(parentActivityId, childId, postId) {
        var originalLiked = this.#liked;
        this.liked = !this.liked;
        this.#activityApi.likeComment(parentActivityId, this.id, childId, postId).then((data) => {
            if (this.#liked)
                this.likeCount++;
            else
                this.likeCount--;
        }).catch(() => {
            this.#liked = originalLiked;
        });
    }

    //This method is implemented in a way that is not intended.
    //Currently we don't have actual admin designs yet and the admin functionalities are mixed in with the community stuff.
    //Think of things such as perma-deleting a comment as admin, or deleting your own comment as a community member in this case.
    //In the future we should have a separate admin view for deleting comments, which makes use of the adminCommentApi which has an override
    //of the communityApi.delete() function, which then executes a perma-delete.
    delete(isAdmin) {
        if (confirm("Are you sure you wish to permanently delete this comment?")) {
            if (isAdmin) {
                this.#adminCommentApi.deleteCommentAdmin(this.id).then(() => {
                    this.forceDeleted = true;
                });
            } else {
                this.#commentApi.deleteComment(this.id).then(() => {
                    this.isDeleted = true;
                });
            }
        }
    }
}