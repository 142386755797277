
import { BaseModel } from "@/core/scoped/entities/BaseModel";
export class JourneyStep extends BaseModel { //Community because this will get your own profile only, so you require to be logged in
    static get JSONLDType() { return "JourneyStep"; }
    constructor() {
        super();
        this.makeTypeGetSet('nextSteps', Array.of(JourneyStep));
    }

    getDescendantSteps() {
        let steps = [];
        steps.push(this);
        if (this.nextSteps != null) {
            for (let i = 0; i < this.nextSteps.length; i++) {
                var next = this.nextSteps[i].getDescendantSteps();
                for (let y = 0; y < next.length; y++) {
                    if (!steps.find(d => d.id == next[y].id))
                        steps.push(next[y]);
                }
            }
        }
        return steps.filter((value, index, self) => self.indexOf(self.find(v => v.id == value.id)) === index);
    }

    /*
    
        public string Name { get; set; }
        public string Description { get; set; }
        public bool AllowFromSkip { get; set; }
        public VisiblityRequirement PreviousVisibilityRequirement { get; set; }
    */
}