import baseApi from "../baseApi";
import apiClient from "./api";

export default class AdminCommentApi extends baseApi {
  
	constructor() {
		super();
		this.adminSubURL = "/admin/comment/";
	}

	async deleteCommentAdmin(guid) {
		const data = await apiClient.delete(this.adminSubURL + "admin/" + guid);
		return data.data;
	}
  
}