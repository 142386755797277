import CommentApi from "../comment";
import apiClient from "./api";

export default class CommunityCommentApi extends CommentApi {

    constructor() {
        super();
    }

    async getComments(targetType, targetId) {
        return await apiClient.standardGet(this.subURL + targetType + "/" + targetId);
    }

    async addComment(target, targetType, content, subTarget = null, replyTo = null) {
        return await apiClient.standardPost(this.subURL + target, {
            subTarget: subTarget,
            targetType: targetType,
            content: content,
            replyTo: replyTo
        });
    }

    async rateEntity(targetType, targetId, ratingDecimal, reviewText) {
        return await apiClient.standardPost(`${this.subURL}rate/${targetId}`,
            {
                rating: ratingDecimal,
                targetType: targetType,
                content: reviewText
            });
    }

    async like(target, targetType, subTarget = null, replyTo = null) {
        return await apiClient.standardPost(this.subURL + target, {
            subTarget: subTarget,
            targetType: targetType,
            type: 'like',
            replyTo: replyTo
        });
    }

    async dislike(target, targetType, subTarget = null, replyTo = null) {
        return await apiClient.standardPost(this.subURL + target, {
            subTarget: subTarget,
            targetType: targetType,
            type: 'dislike',
            replyTo: replyTo
        });
    }

    async deleteComment(guid) {
        const data = await apiClient.delete(this.subURL + guid);
        return data.data;
    }

}
