import apiClient from "./api";
import baseApi from "./baseApi";

export default class CommentApi extends baseApi {
  
	constructor() {
		super();
		this.subURL = "/comment/";
	}

	async getTarget(guid) {
		return await apiClient.standardGet(this.subURL + guid + "/target");
	}

	async getReviewsForEntity(targetId, targetType, skip, take) {
		return await apiClient.standardGet(`${this.subURL}reviews/${targetType}/${targetId}`, { 
			params: {
				skip: skip,
				take: take
			}
		});
	}
}
