import { BaseModel } from "@/core/scoped/entities/BaseModel";
export class Product extends BaseModel { //Community because this will get your own profile only, so you require to be logged in
    static get JSONLDType() { return "Product"; }
    #effortIndication = null;
    constructor() {
        super();
    }

    get includesPersonalCoaching() {
        return this.includes?.find(i => {
            i = i.toLowerCase();
            return i.includes("coaching") || i.includes("mentoring");
        });
    }

    get effortIndication() {
        return (this.#effortIndication == null) ? "4 hours p/w" : this.#effortIndication;
    }
    set effortIndication(v) {
        this.#effortIndication = v;
    }


}