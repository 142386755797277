import { Activity } from "@/core/scoped/entities/Root/Activity";
import ActivityApi from "@/core/scoped/services/api/Community/activity";
import MembershipActivityApi from "@/core/scoped/services/api/Community/Membership/activity";
import { PopupGenerator } from "@/core/global/UI/Popups";
import state from "@/core/global/state/state";

export class CommunityActivity extends Activity {

    static get JSONLDType() { return "Activity"; }

    static communityApi = new ActivityApi();
    static membershipActivityApi = new MembershipActivityApi();
    #popupGenerator = new PopupGenerator();

    constructor() {
        super();
    }

    async toggleComplete() {
        this.userCompleted = !this.userCompleted;
        try {
            await CommunityActivity.communityApi.complete(this.id, !this.userCompleted);
        }
        catch {
            this.userCompleted = !this.userCompleted;
        }
    }

    communityActivityAction() {
        console.log("only logged in members can do this");
    }

    async follow() {
        var followResult = await CommunityActivity.communityApi.follow(this.id);
        if (followResult) {
            this.currentRoles.push(followResult);
            window.globalInsights?.activity?.trackFollow(this);
        } else {
            window.globalInsights?.activity?.trackFollow(this, "unfollowed");
        }
    }

    async join(joinData) {
        var joinResponse = await CommunityActivity.communityApi.join(this.id, joinData);
        if (!this.currentRoles) this.currentRoles = [];
        this.currentRoles.push(joinResponse);
        window.globalInsights?.activity?.trackApply(this);
    }

    async joinViaMembership(joinData) {
        var joinResponse = await CommunityActivity.membershipActivityApi.joinViaMembership(this.id, joinData);
        if (!this.currentRoles) this.currentRoles = [];
        this.currentRoles.push(joinResponse);
        window.globalInsights?.activity?.trackApply(this);
    }


    get hasAppliedRole() {
        return this.currentRoles && (this.currentRoles.hasLevel('accessRequested') || this.currentRoles.hasLevel('member') || this.currentRoles.hasLevel('blocked') || this.currentRoles.hasLevel('accessDenied'));
    }

    get hasEligibleMemberships() {
        return this.eligibleMembership == true;
    }

    hasRoleStatus(roleStatus, roleLevel) {
        if (!this.currentRoles) return false;
        return this.currentRoles.find((r) => {
            if (roleLevel == null || roleLevel == undefined) {
                return r.status == roleStatus;
            } else {
                return r.status == roleStatus && r.level.includes(roleLevel);
            }
        }) != null;
    }

    get joinStatusText() {
        if (this.currentRoles.hasLevel('member')) {
            return "Joined";
        }
        else if (this.currentRoles.hasLevel('accessRequested')) {
            return "Awaiting approval";
        } else if (this.currentRoles.hasLevel('accessDenied')) {
            return "You've not been selected";
        } else if (this.currentRoles.hasLevel('blocked')) {
            return "You've been blocked";
        } else {
            return null;
        }
    }

    async rate(ratingDecimal, reviewText) {
        var result = await CommunityActivity.communityApi.rate(this.id, ratingDecimal, reviewText);
        if (result != null && result != undefined) {
            this.hasReviewed = true;
            this.#popupGenerator.btAlert('Succesfully added review', 'success', 2000);
        }
        return result;
    }

    async revokeRequest() {
        CommunityActivity.communityApi.unjoin(this.id).then(() => {
            this.currentRoles = [];
        });
    }

    async unfollow() {
        await CommunityActivity.communityApi.unfollow(this.id);
        this.currentRoles = this.currentRoles.filter((r) => r.level != 'viewer');
    }

    async getRelatedSummaries() {
        return await CommunityActivity.communityApi.getRelatedActivitySummaries(this.id);
    }



    // We update the properties this way below because there is a lot of additional logic and checks on the backend which is done when fetching
    // activity posts. Such as whether you are allowed to comment on a post. I think it is excessive to do all these checks again on the backend
    // for these simple operations. To elaborate; We first fetch the PostDTOs for the activity, and then do all sorts of logic in the controller to append
    // properties to this DTO. -> See ActivityController:981.

    requestPublishPost(postId) {
        CommunityActivity.communityApi.requestPublishPost(this.id, postId, new Date().toISOString()).then(() => {
            var changed = this.updates.find(x => x.id == postId);
            var index = this.updates.indexOf(changed);
            changed.requestedPublishDateTime = new Date().toISOString();
            changed.requestedUnpublishDateTime = null;
            this.updates[index] = changed;
            this.#popupGenerator.btAlert("Requested post publishment succesfully.", "success", 3000);
        });
    }

    requestUnpublishPost(postId) {
        CommunityActivity.communityApi.requestUnpublishPost(this.id, postId).then(() => {
            var changed = this.updates.find(x => x.id == postId);
            var index = this.updates.indexOf(changed);
            changed.requestedPublishDateTime = null;
            changed.requestedUnpublishDateTime = new Date().toISOString();
            this.updates[index] = changed;
            this.#popupGenerator.btAlert("Requested post unpublishment succesfully.", "success", 3000);
        });
    }
}