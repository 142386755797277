
import state from "@/core/global/state/state";
export default {
	install: (app, options) => {
		const envkey = process.env.VUE_APP_ENV_CLARITY_KEY;
		if (!envkey)
			return;

		window["clarity"] = window["clarity"] || function () { (window["clarity"].q = window["clarity"].q || []).push(arguments); };
		const clarityFunc = (function (c, l, a, r, i, t, y) {
			c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments); };
			t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
			y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
		});
		clarityFunc(window, document, "clarity", "script", envkey);
		window.addEventListener("cookiesAcceptanceChanged", (e) => {
			if (e.detail?.cookiesAllowed)
				window.clarity("consent");
		});
		let pc = process.env.VUE_APP_ENV_USE_COOKIE_WALL === true || process.env.VUE_APP_ENV_USE_COOKIE_WALL === 'true' || process.env.VUE_APP_ENV_USE_COOKIE_WALL === '1';
		if (!pc)
			window.clarity("consent");
		if (app.config.globalProperties.$getCookie) {
			let c = app.config.globalProperties.$getCookie('ac');
			if (c == true || c == 'true' || c == 1 || c == '1') {
				window.clarity("consent");
			}
		}

		try {
			let nouse = process.env.VUE_APP_ENV_CLARITY_IGNORE;
			nouse = nouse?.split(",");
			if (state.user?.profileId) {
				let identify = true;
				if (nouse && nouse.length) {
					if (nouse.find(u => u.trim() == state.user.profileId)) {
						identify = false;
						window.clarity("stop"); //allows you to filter out some profile ids such that they don't get logged
					}
				}
				if (identify) {
					let name = null;
					if (state.currentProfile)
						name = state.currentProfile?.firstName ?? "" + " " + state.currentProfile?.lastName ?? "";
					window.clarity("identify", state.user.profileId, null, null, name);
				}
			}
		} catch (e) { console.error(e); }

	}
};
