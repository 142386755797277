import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import Insights from './Insights';
import state from "@/core/global/state/state";
export default class FruitPunchInsights extends Insights {
    constructor(app, state) {
        super(app, state);
        let ikey = process.env.VUE_APP_ENV_FAKE_INSIGHTS_CONNECTION_STRING;
        this.config = {
            connectionString: ikey,
            autoTrackPageVisitTime: true,
            enableAutoRouteTracking: false,
            enableRequestHeaderTracking: true,
            enableResponseHeaderTracking: true,
            enableAjaxErrorStatusText: true,
            appId: "FruitPunchFrontEnd",
            appName: "FrontEnd_" + (window.apiSettings.URL?.includes('https://api.fruitpunch.ai') ? 'LIVE' : 'DEV')
        };
        this.appInsights = new ApplicationInsights({
            config: this.config
        });
        this.appInsights.loadAppInsights();
        this.appInsights.addTelemetryInitializer(async (env) => { await this.userTelemetryInitializer(env); });
    }
    startTrackPage(route) {
        this.appInsights.startTrackPage(route.name);
    }
    stopTrackPage(route, envelope) {
        let properties = this.getProperties(envelope);
        this.appInsights.stopTrackPage(route.name, null, properties, null);
    }

    getProperties(envelope) {
        let properties = {
        };
        properties.environment = (window.apiSettings.URL?.includes('https://api.fruitpunch.ai') ? 'LIVE' : 'DEV')
        if (envelope?.activity) {
            properties.activity = envelope.activity.id;
        }
        if (envelope?.employmentPosition) {
            properties.employment_position = envelope.employmentPosition.id;
            properties.organization = envelope.employmentPosition.id;
        }
        if (envelope?.organization) {
            properties.organization = envelope.organization.id;
        }
        if (envelope?.talentMatch) {
            properties.talent_match = envelope.talentMatch.profileId;
        }
        if (envelope?.success !== null && envelope?.success !== undefined) {
            properties.success = envelope.success;
        }
        return properties;
    }

    async userTelemetryInitializer(envelope) { //https://learn.microsoft.com/en-us/azure/azure-monitor/app/api-filtering-sampling?tabs=sdkloaderscript#javascript-telemetry-initializers
        //https://github.com/microsoft/ApplicationInsights-JS#telemetry-initializers
        envelope.tags['build'] = process.env.VUE_APP_ENV_BUILD ?? 'DEV';
        envelope.tags["ai.cloud.role"] = this.config.appName;
        if (this.state.user) {
            envelope.ext.user.id = this.state.user.profileId;
            envelope.tags['profile_id'] = this.state.user.profileId;
            envelope.tags['loggedIn'] = '1';
            if (!envelope.data)
                envelope.data = {};
            if (state.currentProfile) {
                let pr = state.currentProfile;
                //note that sending your actual gender to google analytics is like super iffy
                if (pr.gender?.toLowerCase() == "male")
                    envelope.data.profile_gender = "Male";
                else if (pr.gender?.toLowerCase() == "female")
                    envelope.data.profile_gender = "female";
                let birth = pr.dateOfBirth ?? pr.birthday; //this will change name at some point.
                if (birth) {
                    envelope.data.profile_date_of_birth = birth;

                }
                if (pr.seniority) {
                    envelope.data.profile_seniority = pr.seniority;
                }
                if (pr.countryOfBirth) {
                    envelope.data.profile_country_of_birth = pr.countryOfBirth;
                }
                envelope.data.profile_member_since = pr.createdAt;
            }
            if (state.userHasOrganizations) {
                envelope.data.profile_has_organizaton = true;
            }
            if (state.organization) {
                envelope.data.active_organization = state.organization.id;
            }
        }
        return true;

    }

    trackEvent(name, envelope) {
        let properties = this.getProperties(envelope);
        let obj = { name: name, properties: properties };
        if (envelope.success !== undefined) {
            obj.success = envelope.success;
        }
        this.appInsights.trackEvent(obj);
    }
    startTrackEvent(name, envelope) {
        this.appInsights.startTrackEvent(name);
    }
    stopTrackEvent(name, envelope) {
        let properties = this.getProperties(envelope);
        if (envelope.success !== undefined) {
            properties.success = envelope.success;
        }
        this.appInsights.stopTrackEvent(name, null, properties, null);
    }
}
