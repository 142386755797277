import apiClient from "./api";
import baseApi from "./baseApi";

export default class OrganizationApi extends baseApi {
    
	constructor() {
		super();
		this.subURL = "/organization/";
	}

	async get(id) {
		return await apiClient.standardGet(this.subURL + id);
	}

	async getMany(ids) {
		ids = [...new Set(ids)]; //unique
		return await apiClient.standardGet(this.subURL + "info", { params: { ids: ids } });
	}
}

