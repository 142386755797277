<template>
<div class="d-flex">
    <h4 v-if="icon" class="fw-light d-inline-flex me-1" style="margin-top:-2px" :icon="icon"></h4>
    <h4 class="fw-bold d-inline-flex">
        <slot></slot>
    </h4>
    <h4 v-if="iconAfter" class="fw-light d-inline-flex ms-1" style="margin-top:-2px" :icon="iconAfter"></h4>
</div>
</template>
<script>
export default {
    props: {
        icon: {
            type: String,
            required: false
        },
        iconAfter: {
            type: String,
            required: false
        }
    }

}
</script>