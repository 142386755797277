import { MembershipPricing } from "@/core/scoped/entities/Root/MembershipPricing.js";
import { Product } from "@/core/scoped/entities/Root/Product.js";
export class MembershipProduct extends Product {
    static get JSONLDType() { return "MembershipProduct"; }

    #recurringPrices;
    #subscriptionLevels;
    get recurringPrices() {
        if (this.#recurringPrices == null)
            this.#recurringPrices = this.pricings?.filter(p => p.recurringPriceSettings?.interval != null);
        return this.#recurringPrices;
    }
    get hasRecurringPrice() {
        return this.recurringPrices?.length > 0;
    }

    get subscriptionLevels() {
        if (this.#subscriptionLevels == null) {
            let pricings = this.recurringPrices;
            if (pricings == null)
                return null;
            if (pricings.length == 1)
                return pricings;
            const oar = ["day", "week", "month", "year"];
            pricings.sort((a, b) => {
                if (a.recurringPriceSettings.interval == b.recurringPriceSettings.interval) {
                    return a.recurringPriceSettings.intervalCount - b.recurringPriceSettings.intervalCount;//shortest interval comes first. Longer intervals saves
                } else {
                    return oar.indexOf(a.recurringPriceSettings.interval) - oar.indexOf(b.recurringPriceSettings.interval);
                }
            });
            for (let i = 1; i < pricings.length; i++) {
                let prev = pricings[i - 1];
                let n = pricings[i];
                let psp = prev.singleYearPrice;
                let nsp = n.singleYearPrice;
                n.savings = Math.round(((psp - nsp) / psp) * -100);
            }
            this.#subscriptionLevels = pricings;
        }
        return this.#subscriptionLevels;
    }



    get cheapestPrice() {
        if (!this.pricings?.length)
            return null;
        let max = Math.min(...this.pricings.map(o => o.singleYearPrice))
        return this.pricings.find(f => f.singleYearPrice == max);
    }

    constructor() {
        super();
    }
    load(source, freeze = false) {
        super.load(source, freeze);

        super.makeTypeGetSet('pricings', Array.of(MembershipPricing));
    }
}