import { BaseModel } from "@/core/scoped/entities/BaseModel";
export class TalentMatch extends BaseModel {
    static get JSONLDType() { return "TalentMatch" };
    #matchPercentage
    constructor() {
        super();
    }

    get matchPercentage() {
        return this.#matchPercentage;
    }
    get occupationAtString() {
        if (this.occupation) {
            if (this.occupation.host == this.occupation.title)//onboarding works like this
                return (this.occupation.description ?? this.occupation.title) + (this.occupation?.host ? ' @ ' + this.occupation.host : '');
            else if (!this.occupation.host) { //this is how the old (wrong) experience edit entry card worked.
                return this.occupation.title;//no use to use description
            } else {
                return this.occupation.title + (this.occupation?.host ? ' @ ' + this.occupation.host : '');
            }
        }
        return null;
    }


    setMatchPercentage(skillIds, accreditedOnly = false) {
        if (!skillIds || skillIds.length == 0)
            this.#matchPercentage = 100;
        else {
            var matching = skillIds?.filter(s => { var mySkill = this.skills.find(ms => ms.id == s); return mySkill && (!accreditedOnly || mySkill.value > 0); }).length ?? 0;
            var perc = matching / skillIds.length;
            this.#matchPercentage = Math.round(perc * 100);
        }
        return this.#matchPercentage;
    }
}