<template>
<div v-if="showNotifier" class="card card-body py-2" :style="conditionalStyle">
    <div class="header-row d-flex flex-row flex-nowrap" @click="$emit('containerClick')">
        <div style="width: 30px; margin-right: 10px;">
            <!-- In this slot you can put whatever you want appended before the notifier. This can be an icon, profile picture or whatever. 
                     By default an icon is tried to be put in based on the status you provided. For example info, warning, success or danger. -->
            <slot name="prependIconSection" v-if="$slots.prependIconSection"></slot>
            <span v-else-if="conditionalIcon" :icon="conditionalIcon" :style="{
    color: `var(--bs-${this.status})`,
    fontSize: '17pt'
}"></span>
        </div>
        <div>
            <div class="d-flex flex-row flex-nowrap" style="width: 100%;">
                <div :style="closeable ? { width: 'calc(100% - 30px)' } : { width: '100%' }">
                    <p v-if="title?.length" class="fw-bold p-0 m-0 pt-1">{{ title }}</p>
                    <slot name="title" style="margin-left: 30px;"></slot>
                </div>
                <div v-if="closeable" class="pt-0 pb-0" style="width: 20px; margin-left: 10px; cursor: pointer;"
                    @click="close">
                    <span icon="close-circle" :style="{ color: `var(--bs-${this.status})` }"></span>
                </div>
            </div>
            <div class="content-row mt-2" v-if="$slots.content" @click="$emit('containerClick')">
                <!-- In this slot you can put whatever content you want. This can be simply a line of text, or a card which displays a reply to a comment etc. -->
                <slot name="content" style="margin-left: 30px;"></slot>
            </div>
        </div>


    </div>
    <div class="content-row mt-2" v-if="actionButton">
        <button class="btn mt-2" :class="actionButton.class ? actionButton.class : 'text-link-underlined'"
            style="width: fit-content;" @click="$emit('buttonClick')">{{ actionButton.text }}</button>
    </div>
</div>
</template>

<script>
export default {
    components: {},
    name: "notifier",
    emits: ["close", "buttonClick", "containerClick"],
    props: {
        show: {
            type: Boolean,
            required: true,
            default: true
        },
        status: {
            type: String,
            required: false,
            default: "info"
        },
        closeable: {
            type: Boolean,
            required: false,
            default: false
        },
        title: {
            type: String,
            required: false,
        },
        actionButton: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            showNotifier: false,
            conditionalStyle: null,
            conditionalIcon: null
        };
    },
    mounted() {
        this.showNotifier = this.show;
        this.conditionalStyle = {
            backgroundColor: `var(--bs-color-${this.status}-light)`, //violently wrong  this but whatever
            border: `1px solid var(--bs-color-${this.status})`
        };
        if (this.status.toLowerCase() == "info") {
            this.conditionalIcon = "info-circle-fill";
        } else if (this.status.toLowerCase() == "warning") {
            this.conditionalIcon = "warning-circle-fill";
        } else if (this.status.toLowerCase() == "danger") {
            this.conditionalIcon = "close-circle-fill";
        } else if (this.status.toLowerCase() == "success") {
            this.conditionalIcon = "tick-circle-fill";
        }
    },
    methods: {
        close() {
            this.showNotifier = false;
            this.$emit("close");
        }
    },
};
</script>
<style scoped lang="scss">
@import "/src/styles/bootstrap/fp_colors.scss";
</style>