import { register } from "register-service-worker";

export function RegisterServiceWorker() {
    return new Promise((resolve, reject) => {

        var timeout = setTimeout(() => {
            reject("Registration timed out");
        }, 5000);
        let buildnr = process.env.VUE_APP_ENV_BUILD ?? 'DEV';
        let issame = true;
        let oldBuild = localStorage.getItem("build");
        if (!oldBuild) {
            localStorage.setItem("build", buildnr);
        } else {
            if (oldBuild != buildnr)
                issame = false;
            localStorage.setItem("build", buildnr);
        }
        const environmentConfig = new URLSearchParams({
            storageUrl: window.apiSettings.storageURL,
            apiUrl: window.apiSettings.URL,
            build: buildnr,
            cacheApi: process.env.VUE_APP_ENV_CACHE_API,
            cacheFrontend: process.env.VUE_APP_ENV_CACHE_FRONTEND,
            reloadFrontendOnCache: process.env.VUE_APP_ENV_RELOAD_CACHE_FRONTEND,
        }).toString();

        if (process.env.NODE_ENV === "production") {
            register(`${process.env.BASE_URL}service-worker-base-live.js?${environmentConfig}`, {
                ready(registration) {
                    clearTimeout(timeout);
                    if (navigator.serviceWorker.controller)
                        window.activeWorker = registration.active;

                    if (!issame) {
                        registration.update().then(() => {
                            resolve();
                            document.location.reload();
                        });
                    } else
                        resolve();
                    return registration;
                },
                registered(r) {
                    console.log("Service worker has been registered.");
                    clearTimeout(timeout);
                    resolve();
                    return r;
                },
                cached() {
                    console.log("Content has been cached for offline use.");
                },
                updatefound() {
                    console.log("New content is downloading.");
                },
                updated() {
                    console.log("New content is available; please refresh.");
                },
                offline() {
                    console.log(
                        "No internet connection found. App is running in offline mode."
                    );
                },
                error(error) {
                    clearTimeout(timeout);
                    reject(error);
                    console.error("Error during service worker registration:", error);
                },
            });
        } else {
            if (navigator && navigator.serviceWorker) {
                navigator.serviceWorker.register(`/service-worker-base.js?${environmentConfig}`).then(function (registration) {
                    let ready = () => {
                        navigator.serviceWorker.ready.then((readyReg) => {
                            clearTimeout(timeout);
                            resolve(readyReg);
                            if (navigator.serviceWorker.controller)
                                window.activeWorker = readyReg.active;
                        });
                    };
                    if (issame) {
                        ready();
                    }
                    else {
                        registration.update().then(() => {
                            ready();
                            document.location.reload();
                        });
                    }
                }).catch(function (err) {
                    clearTimeout(timeout);
                    reject(err);
                });
            }
            else {
                clearTimeout(timeout);
                reject();
            }
        }
    });
}
