import { createRouter, createWebHistory } from "vue-router";
import { createRouterLayout } from "vue-router-layout";
import routes from "vue-auto-routing";
import state from "@/core/global/state/state";
let AllLayouts = null;
const RouterLayout = createRouterLayout(async (layout) => {
    if (!AllLayouts)
        AllLayouts = (await import("@/layouts/all-layouts.js")).AllLayouts; //Have to import here instead of at top, because otherwise you get a weird error saying that "export default" is not initialized
    //You don't import more layouts this way then you would when using only the import("@/layouts/" + layout + ".vue"); line. RouterLayout will fully initialize all pages at the beginning.
    //Off course you will also import layouts you don't actually use this way, which you wouldn't if you only use import("@/layouts/" + layout + ".vue");
    //But unused layouts should arguably be removed from the codebase anyway
    let selectedLayout = AllLayouts.find(f => f.name == layout); //This allows importing by name instead of file-name
    if (selectedLayout)
        return selectedLayout.component;
    return import("@/layouts/" + layout + ".vue");
});
routes.forEach(r => {
    if (r.meta?.overridePath) {
        r.path = r.meta.overridePath;
    }
});
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        {
            path: "/",
            component: RouterLayout,
            children: routes, //change this when having multiple layouts (for admin view)
        },
    ],
});
//Don't like the tokens in here so we remove them
let replaceTokens = (to) => {
    const url = new URL(
        process.env.VUE_APP_ENV_BASE_URL + to.fullPath
    );


    let hasRefreshToken = url.searchParams.has("refreshToken");
    let hasToken = url.searchParams.has("token");
    let hasCreatedAccount = url.searchParams.has("createdAccount");
    let hasCreatedAccountValue = url.searchParams.get("createdAccount");
    if (hasCreatedAccount && hasCreatedAccountValue === "false") {
        url.searchParams.delete("createdAccount");
    } else if (hasCreatedAccount) {
        window?.globalInsights?.app?.trackSignup();
    }
    if (hasRefreshToken || hasToken) {
        if (hasRefreshToken) {
            url.searchParams.delete("refreshToken");
        }
        if (hasToken) {
            url.searchParams.delete("token");
        }
        var result = {
            path: url.pathname, query: Object.fromEntries(url.searchParams), hash: url.hash, fullPath: url.pathname + url.search + url.hash
        };
        window?.globalInsights?.app?.trackLogin();

        return result;
    } else {
        return null;
    }
};
let checkUserAccess = async (to, user) => {
    delete to.currentRoles;
    if (requiresAuth(to)) {
        if (!user) {
            return false;
        }
        if (!(user.bearerToken && Date.parse(user.expires) > Date.now()))
            return false;
        else if (to.meta) {
            if (to.meta.requiresAuth instanceof Object) {
                //we only have to do a call if requires auth defines roles to check
                //Otherwise just accept that we are logged in.
                //TODO: remove deprecated role checking
                try {
                    var checks = [];
                    for (var type in to.meta.requiresAuth) {
                        var r = { type: type };
                        var setting = to.meta.requiresAuth[type];
                        if (Array.isArray(setting)) {
                            r.level = setting.join(",");
                        } else if (setting instanceof Object) {
                            var lvl = setting.level;
                            if (Array.isArray(lvl))
                                r.level = lvl.join(",");
                            else r.level = lvl;
                            let setfor = (variable) => {
                                let prop = "params";
                                let value = setting[variable];
                                if (setting[variable].prop)
                                    prop = setting[variable].prop;
                                if (setting[variable].value)
                                    value = setting[variable].value;
                                var entityId = to[prop][value];
                                if (entityId)
                                    r[variable] = entityId;
                            };
                            if (setting.entityId)
                                setfor('entityId');
                            if (setting.externalId)
                                setfor('externalId');
                            if (setting.externalType)
                                r.externalType = setting.externalType;
                        } else {
                            r.level = setting;
                        }
                        checks.push(r);
                    }
                    if (router.accountApi == null) {
                        let acApi = require("@/core/scoped/services/api/account.js").default;
                        router.accountApi = new acApi();
                    }
                    var info = await router.accountApi.checkAccess(checks);
                    if (info === "false" || info === false || !info || info == "null" || info.authorized == false)
                        return false;
                    to.currentRoles = info.roles;
                    return true;

                } catch (ex) {
                    console.error(ex);
                    return false;
                }
                return false;
            } else {
                return true;
            }
        }
        else return true;
    } else {
        return true;
    }
};
let requiresAuth = (to) => {
    if (to) {
        let meta = to.meta;
        if (meta.requiresAuth === false || meta.requiresAuth === 'false')
            return false;
        return true;
    }
    return true;
};

router.beforeEach(async (to, from) => {
    let ld = document.getElementById("jsonLDView");
    if (ld)
        ld.remove();
    if (requiresAuth(to) || to.query.token) {
        let query = Object.assign({}, to.query);
        if (!query.returnUrl) {
            query.returnUrl = to.fullPath;
        }
        let sesapi = require("@/core/scoped/services/api/Community/session.js").default;
        let sessionApi = new sesapi();
        if (state && state.user && !to.query.token && await checkUserAccess(to, state.user)) {
            try {
                await sessionApi.refreshIfNeeded();
                var to2 = replaceTokens(to);
                if (to2)
                    return to2;
                else return;
            } catch {
                state.deleteAll();
                return { name: "login", query: query };
            }

        } else {
            if (to.query.token) {
                state.registerStored("user", { bearerToken: to.query.token.replaceAll(" ", "+"), refreshToken: to.query.refreshToken.replaceAll(" ", "+") });
                state.registerSession('refreshedThisSession', '1');
                try {
                    document.body.innerHTML += "<h5 style=\"position:fixed;top:5px;left:5px;z-index:55\" id=\"loginhead\">Logging in...</h5>";
                    let sessionUpdate = sessionApi.refreshAccountInfo();
                    let memsapi = require("@/core/scoped/services/api/Community/membership.js").default;
                    let membershipApi = new memsapi();
                    let membershipUpdate = membershipApi.reloadMemberships();
                    let orgapi = require("@/core/scoped/services/api/Community/organization.js").default;
                    let organizationApi = new orgapi();
                    let organizationUpdate = organizationApi.getPersonal();
                    await sessionUpdate;
                    await membershipUpdate;
                    let organizations = await organizationUpdate;
                    if (organizations && organizations.length > 0) {
                        state.registerStored("userHasOrganizations", true);
                    } else {
                        state.registerStored("userHasOrganizations", false);
                    }
                    document.getElementById("loginhead").innerHTML = "Redirecting...";
                    var to2 = replaceTokens(to);
                    window.location.href = to2.fullPath;//For some reason the way to fix that the service worker works is by this actual reload. Otherwise it will register but not get used
                    return to2;
                }
                catch (ex) {
                    console.error(ex);
                    delete query.token;
                    return { name: "login", query: query };
                }
            } else {
                if (state.user && state.user.expires && Date.parse(state.user.expires) > new Date() && to.meta.requiresAuth && to.meta.requiresAuth instanceof Object && Object.keys(to.meta.requiresAuth).find(s => s == 'organization') && !state.organization) {
                    return {
                        name: "organizationSelect", query: query
                    };
                } else {
                    if (!state.user || (state.user && Date.parse(state.user.expires) < new Date())) {
                        state.deleteAll();
                        return {
                            name: "login", query: query
                        };
                    } else {
                        return {
                            name: "accessDenied", query: query
                        };
                    }
                }
            }
            // go to wherever I'm going
        }
    } else {
        replaceTokens(to);//do need to replace because of tracking
        return; //does not require auth
    }
});


export default router;
