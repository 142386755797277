import { HubConnectionBuilder } from "@microsoft/signalr";
import { Notification } from "@/core/scoped/entities/Root/Notification";
import state from "@/services/state/state";

const EventEmitter = require('events');


export default class extends EventEmitter {

    constructor() {
        super();
        this.hubName = "notifications";
    }

    registerHubConnection() {
        if (state.user?.bearerToken) {
            let hubConnection = new HubConnectionBuilder()
                .withUrl(window.apiSettings.URL + "/" + this.hubName + "?encodedToken=" + btoa(state.user.bearerToken), {
                    headers: { "Authorization": "Bearer " + state.user.bearerToken },
                })
                .withAutomaticReconnect()
                .build();
            hubConnection.on("NotificationReceived", (notificationData) => {
                if (!(notificationData instanceof Notification)) {
                    let hold = notificationData;
                    notificationData = new Notification();
                    notificationData.load(hold);
                }
                this.emit('notification', notificationData);
            });
            hubConnection.start();
            return hubConnection;
        }
        return null;
    }

    unregisterHubConnection(hubConnection) {
        hubConnection.stop();
    }

    encodeBase64StringForHub(base64String) {
        let decoded = decodeURIComponent(base64String);
        return decoded.replace('+', '.').replace('/', '_').replace('=', '-');
    }

}
