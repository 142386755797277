import { BaseModel } from "@/core/scoped/entities/BaseModel";
import { StepTaskProgress } from "@/core/scoped/entities/Root/Community/Journey/StepTaskProgress";
import { JourneyStep } from "@/core/scoped/entities/Root/Community/Journey/JourneyStep";
import { CommunityActivity } from "@/core/scoped/entities/Root/Community/Activity";
import { ActivityWithRoles } from "@/core/scoped/entities/Root/Community/ActivityWithRoles";

import JourneyApi from "@/core/scoped/services/api/Community/journey";

export class ProfileJourneyStep extends BaseModel { //Community because this will get your own profile only, so you require to be logged in
    static get JSONLDType() { return "ProfileJourneyStep"; }
    static journeyApi;

    #percentDone;
    #title;
    #description;
    #subtasks;
    #tasksDone;
    constructor() {
        super();
        this.makeTypeGetSet('activityProgress', StepTaskProgress);
        this.makeTypeGetSet('journeyStep', JourneyStep);
        this.makeTypeGetSet('childActivityProgress', Array.of(StepTaskProgress));
        this.makeTypeGetSet('tasksProgress', Array.of(StepTaskProgress));
        this.makeTypeGetSet('activityChoices', Array.of(ActivityWithRoles));
    }
    get activityOptions() {
        return this.activityChoices?.map(a => a.activity);
    }
    get action() {
        var activity = this.activityProgress?.activity;
        if (activity) {
            return {
                name: 'activityFullPage',
                params: {
                    type: activity.type,
                    id: activity.customRouterId ?? activity.id
                }
            };
        }

        //TODO: Implement more configurations here for custom actions.
        return null;
    }
    get id() {
        return this.journeyStepId;
    }
    get percentDone() {
        if (this.#percentDone != null)
            return this.#percentDone;
        if (this.activityProgress != null) {
            if (this.activityProgress.percentDone == 100) {
                this.#percentDone = 100;
                return this.#percentDone;
            }
            if (this.childActivityProgress != null && this.childActivityProgress.length > 0) {
                var percentage = 0;
                this.childActivityProgress.forEach(cp => {
                    percentage += cp.percentDone;
                });
                percentage += this.activityProgress.percentDone;
                this.#percentDone = percentage / (this.childActivityProgress.length + 1);
                return this.#percentDone;
            }
            this.#percentDone = this.activityProgress.percentDone;
            return this.#percentDone;
        }
        else if (this.tasksProgress != null && this.tasksProgress.length > 0) {
            var percentage = 0;
            this.tasksProgress.forEach(cp => {
                percentage += cp.percentDone;
            });

            this.#percentDone = percentage / this.tasksProgress.length;
            return this.#percentDone;
        }
        this.#percentDone = 0;
        return this.#percentDone;
    }

    get isClosed() {
        return this.status?.toLowerCase() == 'closed';
    }

    get canStart() {
        return this.status?.toLowerCase() == 'open';
    }

    get isActive() {
        return this.status?.toLowerCase() == 'ongoing';
    }

    get canChooseActivity() {
        return this.canStart && this.activityOptions != null && this.activityOptions.length > 0;
    }

    get tasksDone() {
        if (this.#tasksDone != null)
            return this.#tasksDone;
        let sub = this.subtasks;
        if (sub && sub.length) {
            this.#tasksDone = sub.filter(t => t.isComplete).length;
            return this.#tasksDone;
        }
        return 0;
    }
    get tasksPending() {
        let sub = this.subtasks;
        if (sub && sub.length) {
            return sub.length - this.tasksDone;
        }
        return 0;
    }
    get tasksString() {
        if (this.subtasks && this.subtasks.length) {
            return this.tasksDone + "/" + this.subtasks.length; //not +"Tasks" because elements should handle translations
        }
    }


    get title() {
        if (this.#title != null)
            return this.#title;
        if (this.journeyStep != null) {
            if (this.journeyStep.name != null && this.journeyStep.name.length > 0) {
                this.#title = this.journeyStep.name;
                return this.#title; //usuall we want either this, or the title of an activity
            } else {
                if (this.activityProgress != null) {
                    if (this.activityProgress.activity != null) {
                        this.#title = this.activityProgress.activity.typeName + ":" + this.activityProgress.activity.title;
                        return this.#title;
                    } else {
                        return "Do an activity"; //should not happen
                    }
                }
                else if (this.tasksProgress != null) {
                    return "Do some tasks";//Should not happen.
                }
            }
        }
        return "Task";
    }
    get description() {
        if (this.#description != null)
            return this.#description;
        if (this.journeyStep != null) {
            if (this.journeyStep.description != null && this.journeyStep.description.length > 0) {
                this.#description = this.journeyStep.description;
                return this.#description; //usuall we want either this, or the title of an activity
            } else {
                if (this.activityProgress != null) {
                    if (this.activityProgress.activity != null) {
                        if (this.activityProgress.subtitle?.length) {
                            this.#description = this.activityProgress.subtitle;
                            return this.#description;
                        }
                        return "Do the " + this.activityProgress.activity.title + " " + this.activityProgress.activity.typeName;
                    } else {
                        return "Do the activity"; //should not happen
                    }
                }
                else if (this.tasksProgress != null) {
                    return "Do some tasks";//Should not happen.
                }
            }
        }
    }
    get subtasks() {
        let reloadTasks = st => {
            st.mainActivityId = this.mainActivityId;
            st.stepId = this.journeyStepId;
            st.profileJourney = this.profileJourney;
            st.parent = this;
        };
        if (this.#subtasks != null) {
            this.#subtasks.forEach(reloadTasks);
            return this.#subtasks;
        }
        if (this.activityProgress != null || this.tasksProgress != null) {
            this.#subtasks = [];
        }
        if (this.activityProgress != null) {
            if (this.activityProgress.activity != null && this.activityProgress.activity.type == 'challenge') {
                let newTasks = ProfileJourneyStep.GetDefaultChallengeSteps(this.activityProgress.activity, this.activityProgress.relevantRoles);
                newTasks.forEach(t => this.#subtasks.push(t));
            }
            if (this.childActivityProgress != null && this.childActivityProgress.length) {
                this.#subtasks.push(...this.childActivityProgress);
            }
            this.#subtasks.sort((a, b) => (a.order ?? 99) - (b.order ?? 99));
        }
        if (this.tasksProgress != null) {
            this.#subtasks.push(...this.tasksProgress);
            this.#subtasks.sort((a, b) => (a.order ?? 99) - (b.order ?? 99));
            this.#subtasks.forEach(reloadTasks);
        }
        return this.#subtasks;
    }

    load(source, freeze = false) {
        super.load(source, freeze);
        if (this.activityProgress?.activity) {
            for (var n in this.activityProgress.activity) {
                var desc = Object.getOwnPropertyDescriptor(this, n);
                if (!desc) {
                    var desc2 = Object.getOwnPropertyDescriptor(Object.getPrototypeOf(this), n);
                    if (!desc2)
                        Object.defineProperty(this, n, {
                            get() {
                                return this.activityProgress.activity[n];
                            }
                        });
                }
            }
        }
    }

    async selectActivity(activityId) {
        if (ProfileJourneyStep.journeyApi == null)
            ProfileJourneyStep.journeyApi = new JourneyApi();
        //This is actually a whole new journey.
        var result = await ProfileJourneyStep.journeyApi.selectActivityForStep(this.mainActivityId, this.journeyStepId, activityId);
        if (this.profileJourney) {
            this.profileJourney.reload(result);
        }
        return result;
    }

    resetPercent() {
        this.#percentDone = null;
        this.#tasksDone = null;
        if (this.activityProgress?.activity) {
            if (this.subtasks?.every(st => st.isComplete)) {
                if (ProfileJourneyStep.journeyApi == null)
                    ProfileJourneyStep.journeyApi = new JourneyApi();
                var newThing = ProfileJourneyStep.journeyApi.getActiveJourney(this.mainActivityId, true);
                newThing.then((data) => {
                    this.profileJourney.reload(data);
                });
            }
        }
        var percentage = this.percentDone;
        this.emit('percentageUpdated', percentage);
        return percentage;
    }
    isMemberOfOptions() {
        if (this.activityChoices && this.activityChoices.length) {
            var options = this.activityChoices.filter(c => c.roles != null && c.roles.length && c.roles.hasLevel('member')).map(c => c.activity);
            return options;
        }
        return null;
    }

    static GetDefaultChallengeSteps(activity, relevantRoles) {
        var extraSteps = [];
        var nTask = new StepTaskProgress();
        nTask.title = "Joined " + activity.type;
        nTask.activity = activity;
        if (relevantRoles)
            nTask.relevantRoles = relevantRoles;
        nTask.dueDate = activity.subscribeDeadline;
        nTask.order = -2;
        if (nTask.relevantRoles?.find(r => r.hasLevel('member') || r.hasLevel('requested'))) {
            nTask.percentDone = 100;
        } else {
            nTask.percentDone = 0;
        }
        extraSteps.push(nTask);
        nTask = new StepTaskProgress();
        nTask.order = -1;
        nTask.title = "Accepted to " + activity.type;
        nTask.activity = activity;
        if (relevantRoles)
            nTask.relevantRoles = relevantRoles;
        if (nTask.relevantRoles?.find(r => r.hasLevel('member') && r.status == 'active')) {
            nTask.percentDone = 100;
        } else {
            nTask.percentDone = 0;
        }
        extraSteps.push(nTask);
        return extraSteps;
    }



    //AddProps
    //percentage complete.
    //can start.

    //Each task (subactivity) must define if it's completed.
    //define the fake tasks in here (joined challenge and accepted to challenge)
    //
    //make select activity (from options)
    //make that selected activities always return from backend, also if no longer a part of query.
    /*
    
        public StepStatus Status { get; set; }
     [TransformTo(typeof(StepTaskProgressDTO))]
        public IStepTaskProgress ActivityProgress { get; set; }
        [TransformTo(typeof(StepTaskProgressDTO[]))]
        public IStepTaskProgress[] ChildActivityProgress { get; set; }
        [TransformTo(typeof(StepTaskProgressDTO[]))]
        public IStepTaskProgress[] TasksProgress { get; set; }
    */

}