import { BaseModel } from "@/core/scoped/entities/BaseModel";

export class SkillNode extends BaseModel {
    static get JSONLDType() { return "SkillNode"; }
    #parent;
    #ancestry;
    #color;
    #radius;
    #totalChildren
    #badges;
    #skillValue;
    #certifiedSkill;
    #hasChildWithSkill;
    constructor() {
        super();

    }
    get color() { return this.#color; }
    set color(v) { this.#color = v; }
    get radius() { return this.#radius; }
    set radius(v) { this.#radius = v; }
    get totalChildren() { return this.#totalChildren; }
    set totalChildren(v) { this.#totalChildren = v; }
    get badges() { return this.#badges; }
    set badges(v) { this.#badges = v; }
    get skillValue() { return this.#skillValue; }
    set skillValue(v) { this.#skillValue = v; }
    get certifiedSkill() { return this.#certifiedSkill; }
    set certifiedSkill(v) { this.#certifiedSkill = v; }
    get hasChildWithSkill() { return this.#hasChildWithSkill; }
    set hasChildWithSkill(v) { this.#hasChildWithSkill = v; }
    get ancestry() {
        if (!this.#ancestry) {
            this.#ancestry = [];
            let parent = this.#parent;
            while (parent) {
                this.#ancestry.push(parent);
                parent = parent.#parent;
            }
        }
        return this.#ancestry;
    }

    load(source, freeze = true) {
        super.load(source, false);
        if (this.children?.length) {
            for (var i = 0; i < this.children.length; i++) {
                if (!(this.children[i] instanceof SkillNode)) {
                    let nNode = new SkillNode();
                    nNode.load(this.children[i]);
                    this.children[i] = nNode;
                }
                this.children[i].#parent = this;
            }
        }

        // Commented because watchers don't trigger for entities.
        //if (freeze)
        //    Object.preventExtensions(this);
    }

    traverseHierarchy(callback, children, parent, ancestors) {
        if (!children) {
            children = this.children;
        }
        if (!ancestors)
            ancestors = [];
        for (let i = 0; i < children.length; i++) {
            callback(children[i], i, parent, ancestors);
            if (children[i].children) {
                let an = [...ancestors];
                an.push(children[i]);
                this.traverseHierarchy(callback, children[i].children, children[i], an);
            }
        }
    }
    findNode(callback) {
        this.traverseHierarchy((node) => {
            if (callback(node))
                return node;
        });
    }
    findNodes(callback) {
        let result = [];
        this.traverseHierarchy((node) => {
            if (callback(node))
                result.push(node);
        });
        return result;
    }
}

export const skillTreeColors = {
    building: "#F7D002",
    soft: "#00CDD1",
    programming: "#5433FF",
    practice: "#EB306E",
    methods: "#00CB77",
};

export const skillTreeColorsSoft = {
    building: "rgba(247, 208, 2, 0.2)",
    soft: "rgba(0, 205, 209, 0.2)",
    programming: "rgba(140, 118, 255, 0.2)",
    practice: "rgba(253, 234, 241, 1)",
    methods: "rgba(230, 252, 243, 1)",
};

export const skillTreeBaseNames = {
    building: "Building AI",
    soft: "Soft skills",
    programming: "Programming",
    practice: "In Practice",
    methods: "AI Methods"
};

export const skillTreeBaseNodesWithColors =
    [
        {
            displayName: "Building AI",
            color: "#F7D002"
        },
        {
            displayName: "AI Methods",
            color: "#00CB77"
        },
        {
            displayName: "Programming",
            color: "#5433FF"
        },
        {
            displayName: "Soft skills",
            color: "#00CDD1"
        },
        {
            displayName: "In Practice",
            color: "#EB306E"
        },
    ];

export const skillTreeJustColors = Object.values(skillTreeColors);

export function getSkillTreeColor(skill) {
    if (!skill.base)
        return "#D8DDE1";
    var sk = Object.keys(skillTreeColors).find((r) => skill.base.toLowerCase().includes(r));
    if (sk != null && sk != undefined) {
        var color = skillTreeColors[sk];
        return color;
    }
}