import { Activity, ActivityTypes } from "@/core/scoped/entities/Root/Activity";
import { Role, RoleArray } from "@/core/scoped/entities/Root/Role";

import { BaseModel } from "@/core/scoped/entities/BaseModel";
//This class is intended to make entities of the objects that come back from the api/axios.
export default class {

    constructor(KnownEntities) {
        this.KnownEntities = KnownEntities;
    }
    getModel(obj) {
        return this.recursiveModel(null, obj);
    }
    getFPEntity(type) {
        let result = this.KnownEntities.find(t => t.getEntityName && t.getEntityName().toLowerCase() == type.toLowerCase());
        return result;
    }

    ///Load from basemodel is a new way of loading models.
    ///This should rely more on models defining their own children propperly, so using maketypegetset
    ///We should try to define our models well enough to use this.
    loadFromBaseModel(value) {
        if (value == null)
            return;
        if (typeof value === 'object' || value instanceof Object || typeof value == 'array' || value instanceof Array) {

            let type = this.getFPEntity(value['@type']);
            if (!type) {
                return this.loadModel({ ...value });
            }
            let newBase = new type();
            newBase.load(value);
            for (var n in newBase) {
                if (value[n] && value[n]['@type']) {
                    if (!(newBase[n] instanceof BaseModel)) {
                        newBase[n] = this.loadFromBaseModel(value[n]);
                    }
                }
            }
            return newBase;

        }
        return value;
    }

    loadModel(value) {
        if (value == null)
            return;
        if (typeof value === 'object' || value instanceof Object || typeof value == 'array' || value instanceof Array) {
            for (var n in value) {
                value[n] = this.loadModel(value[n]);
            }

            if (value['@type']) {
                let type = this.KnownEntities.find(t => t.getEntityName && t.getEntityName() == value['@type']);
                if (type && !(value instanceof type)) {
                    let pvalue = new type();
                    pvalue.load(value, true);
                    value = pvalue;
                }
            }
        }
        return value;
    }
    recursiveModel(propertyName, propertyValue) {
        var set = false;
        if (propertyValue === null)
            return null;
        if (typeof propertyValue === 'object' || propertyValue instanceof Object || typeof propertyValue == 'array' || propertyValue instanceof Array) {
            try {
                if (propertyValue['@type']) {
                    //This is the way we should work. 
                    //Role array is a bit of a weird beast but it works because Activity takes care of it itself
                    propertyValue = this.loadFromBaseModel(propertyValue);
                    set = true;
                }//The next else ifs are just backward compatibility stuff. we should remove it when possible
                else if ((propertyName != null && (propertyName == 'currentRoles' || propertyName == 'roles' || propertyName == 'role'))) {
                    if (propertyValue instanceof Array) {
                        if (!(propertyValue instanceof RoleArray))
                            propertyValue = new RoleArray(propertyValue);
                    }
                    else {
                        if (!(propertyValue instanceof Role)) {
                            let pvalue = new Role();
                            pvalue.load(propertyValue);
                            propertyValue = pvalue;
                        }
                    }
                    set = true;
                } else if ((propertyValue['type'] != undefined && propertyValue['title'] != undefined)) {
                    //remove next check when backward compatibility no longer needed
                    if (ActivityTypes.includes(propertyValue['type']) || propertyValue['@type'] == 'activity') {
                        if (!(propertyValue instanceof Activity)) {
                            let pvalue = new Activity();
                            pvalue.load(propertyValue);
                            propertyValue = pvalue;
                        }
                        set = true;
                    }
                } else if (propertyValue.isFolder !== undefined) {
                    propertyValue = this.loadModel(propertyValue);
                    set = true;
                }
            }
            catch (ex) {
                console.error(ex);
            }
        }
        if (!set && (typeof propertyValue === 'object' || propertyValue instanceof Object || typeof propertyValue == 'array' || propertyValue instanceof Array)) {
            if (!BaseModel.isPrototypeOf(propertyValue.constructor)) {
                for (var n in propertyValue) {
                    propertyValue[n] = this.recursiveModel(n, propertyValue[n]);
                }
            }
        }
        return propertyValue;
    }
}