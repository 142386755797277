import { BaseModel } from "@/core/scoped/entities/BaseModel";
import state from "@/services/state/state";
export class Resource extends BaseModel {
    static get JSONLDType() { return "Resource"; }

    #resourceLocation
    get resourceLocation() {
        return this.#resourceLocation;
    }
    set resourceLocation(value) {
        if (!(value instanceof URL)) {
            if (value.indexOf("http") == 0)
                value = new URL(value);
            else value = new URL(window.apiSettings.URL + value);
        }
        if (!("serviceWorker" in navigator) && value.toString().includes(window.apiSettings.URL)) {
            if (state && state.user && state.user.bearerToken) {
                fetch(this.value, {
                    headers: new Headers({
                        Authorization: "Bearer " + state.user?.bearerToken,
                    }),
                }).then((response) => {
                    response.text().then((text) => {
                        this.#resourceLocation = new URL(text);
                    });
                });
            }
            else
                value.searchParams.set("redirect", "1");
        }
        try {
            this.#resourceLocation = value;
        }
        catch (e) {
            console.error(e);
        }
    }
    get url() {
        return this.resourceLocation;
    }
    set url(value) {
        this.resourceLocation = value;
    }

    constructor() {
        super();
    }


    getIcon() {
        if (this.isFolder) return "folder";
        if (this.repository !== undefined && this.repository != "azureBlobs") {
            if (this.repository == "youtube")
                return "youtube";
            return "link-2";
        } else {
            this.mimeType = this.mimeType ?? "binary";
            if (this.mimeType.indexOf("video/") !== -1) return "video-square";
            if (this.mimeType.indexOf("image/") !== -1 || this.mimeType.indexOf("application/pdf") !== -1) return "gallery";
            return "document-text";
        }
    }
    getKind() {
        if (this.dataType) return this.dataType;
        if (this.repository) return this.repository;
        if (this.mimeType) {
            if (this.mimeType.indexOf("application/") != -1) {
                var l = this.name.split(".");
                if (l.length > 1) return l[l.length - 1];
                else return this.mimeType.split("/")[1];
            }
            return this.mimeType;
        }
        return "unknown";
    }
    getSize(decimals = 0) {
        if (this.isFolder) {
            return "";
        } else {
            var bytes = this.size;
            if (!bytes) return "Unknown";
            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
            const i = Math.floor(Math.log(bytes) / Math.log(k));
            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
        }
    }
}