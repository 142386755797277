import apiClient from "@/core/scoped/services/api/Community/api";
import baseApi from "../baseApi";
import state from "@/core/global/state/state";
import ProfileApi from "@/core/scoped/services/api/Community/profile";

export default class CommunitySessionApi extends baseApi {

	constructor() {
		super();
		this.subURL = "/session/";
	}

	async refreshIfNeeded() {
		if (state.refreshedThisSession) {
			return;
		} else {
			try {
				await this.refreshSession();
				return;
			} catch (error) {
				throw error;
			}
		}
	}

	async refreshSession() {
		let refreshToken = state.user.refreshToken;
		apiClient.onlineState = "loggingIn";
		try {
			const data = await apiClient.standardGet(this.subURL + "refresh/?refreshtoken=" + refreshToken + "&nocache=1");
			var newData = data;
			state.registerStored("user", newData);
			if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
				navigator.serviceWorker.controller.postMessage({ bearerToken: newData.bearerToken });
			} else if (window.activeWorker) {
				try {
					window.activeWorker.postMessage({ bearerToken: k.bearerToken });
				} catch { }
			}
			state.registerSession('refreshedThisSession', '1');
			let profileApi = new ProfileApi();
			profileApi.getProfile(true).then(() => { });
			return data;
		} catch (error) {
			throw error;
		}
	}

	async refreshAccountInfo() {
		try {
			let profileApi = new ProfileApi();
			let getdata = apiClient.get(this.subURL + "?nocache=1");
			let getProfile = profileApi.getProfile(true);
			let data = await getdata;
			if (data.data && data.data.bearerToken) {
				var k = data.data;
				k.refreshToken = state.user.refreshToken;
				state.registerSession('refreshedThisSession', '1');
				state.registerStored("user", k);
				try {
					//This also stores the profile wether or not he has a journey.
					await getProfile;
				} catch { }
				if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
					navigator.serviceWorker.controller.postMessage({ bearerToken: k.bearerToken });
				} else if (window.activeWorker) {
					try {
						window.activeWorker.postMessage({ bearerToken: k.bearerToken });
					} catch (ex) {
						console.error(ex);
					}
				}
				return data.data;
			} else {
				state.deleteAll();
				if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
					navigator.serviceWorker.controller.postMessage({ bearerToken: "" });
				}
				throw { error: "notfound" };
			}
		} catch (error) {
			throw error;
		}
	}
}
