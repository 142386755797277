<template>
	<overlay-modal :containerClass="mdFullScreen ? 'md-full-screen' : ''" ref="overlay" v-bind="$props"
		v-bind:attrs="$attrs">
		<div class="card" :class="(mdNoCard ? 'md-no-card ' : '') + (mdFullScreen ? 'md-full-screen' : '')"
			style="max-height:100%;">
			<div class="card-header bg-white p-3 d-flex flex-row justify-content-between w-100">
				<div style="width: calc(100% - 40px)" v-if="header || underHeader">
					<h5 class="fw-bold p-1 pb-0 mb-0" style="">{{ header }}</h5>
					<small v-if="underHeader" class="p-1 m-0">{{ underHeader }}</small>
				</div>
				<div v-else style="width: calc(100% - 40px)"></div>
				<div class="close-button" @click="close()"
					style="width: 40px !important; cursor: pointer; text-align: center;">
					<span icon="close-circle" class="fs-4" style="width: 100%;"></span>
				</div>

			</div>
			<div class="card-body" style="max-height:100%;">
				<slot></slot>
				<div class="col-12 d-flex" v-if="this.$attrs.onCancel || this.$attrs.onAccept">
					<button v-if="this.$attrs.onCancel && this.cancelString" @click="
						$refs.overlay.close();
					$emit('cancel', $event);" class="btn btn-light col me-1 ms-1 modal-button">
						{{ cancelString }}
					</button>
					<button v-if="this.$attrs.onAccept && this.acceptString" @click="
						$refs.overlay.close();
					$emit('accept', $event);" class="btn btn-primary col me-1 ms-1 modal-button">
						{{ acceptString }}
					</button>
				</div>
			</div>
		</div>
	</overlay-modal>
</template>
<script>
import OverlayModal from "@/components/misc/layout/overlay/overlay-modal";
export default {
	name: "overlay-card",
	// emits:["accept","cancel"] We do not state what it emits. This is because for some FUCKING reason, Vue no longer has the events set on this component as part of the $attrs when you do that
	//using $listeners is deprecated in vue 3 so that doesn't work either
	components: { OverlayModal },
	props: {
		mdNoCard: {
			required: false,
			type: Boolean,
			default: false
		}, mdFullScreen: {
			required: false,
			type: Boolean,
			default: false
		},
		header: {
			required: false,
			type: String,
		},
		underHeader: {
			required: false,
			type: String
		},
		acceptString: {
			type: String,
			default: "Accept",
		},
		cancelString: {
			type: String,
			default: "Cancel",
		},
		closeOnNavigate: {
			type: Boolean,
			default: false
		}
	},
	mounted() {
		this.$inheritMethods(this.$refs.overlay);
		window.addEventListener("popstate", this.closeNavigate);

	},
	unmounted() {
		window.removeEventListener("popstate", this.closeNavigate);
	},
	methods: {
		closeNavigate(event) {
			if (this.closeOnNavigate)
				this.close();
		}
	}
};
</script>

<style lang="scss" scoped>
.modal-button {
	min-width: 200px;
}

.card-header {
	position: relative;
}

.close-button {
	display: none;
}



@media (max-height: 800px) {
	.close-button {
		display: block
	}

	.overlay-modal-overlay:deep(.overlay-close-button) {
		display: none;
	}
}

@media print {
	.overlay-modal-overlay:deep(.overlay-close-button) {
		display: none !important;
	}
}
</style>
