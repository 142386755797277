<template>
    <div>
        <div class="col-12 mb-2">
            <div class="border border-gray-500 rounded-pill w-100 d-flex" style="padding:0.1875rem">
                <div v-for="item in children" role="button" @click="() => { activeChild = item; this.createList() }"
                    class="col p-2 text-center fs-sm fw-bold lh-1"
                    :class="(item == activeChild) ? 'bg-tertiary rounded-pill text-white' : 'text-body'">
                    {{ item.getAttribute('title') }}
                </div>
            </div>
        </div>
        <div class="col-12" ref="slotContainer">
            <slot>

            </slot>
        </div>
    </div>
</template>
<script>
export default {
	name: "dot-pagination",
	emits: [],
	data() {
		return {
			children: [],
			observer: null,
			activeChild: null
		};
	},
	mounted() {
		const targetNode = this.$refs.slotContainer;
		const config = { attributes: false, childList: true, subtree: false };
		const callback = (mutationList, observer) => {
			this.createList();
		};
		this.observer = new MutationObserver(callback);
		this.observer.observe(targetNode, config);
		this.createList();
	},
	beforeUnmount() {
		this.observer?.disconnect();
	},
	methods: {
		createList() {
			this.children = [...this.$refs.slotContainer.children];
			var ac = this.children?.find(e => e == this.activeChild);
			if (this.activeChild == null || !ac) {
				this.activeChild = this.children?.find(a => true);//effective "firstOrDefault"
			}
			this.children.forEach((e) => {
				if (e != this.activeChild) {
					e.style.display = "none";
				} else {
					e.style.display = "";
				}
			});
		}

	}
};
</script>