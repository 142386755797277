import Buttons from "./components/buttons";
import Pagination from "./components/pagination";
import Forms from "./forms";
import Cards from "./components/cards";
import Tooltip from "./components/tooltips";
import Content from "./content";
import Tabs from "./components/navs&tabs";


export default {
    install: (app, options) => {
        app.use(Buttons);
        app.use(Pagination);
        app.use(Forms);
        app.use(Cards);
        app.use(Tabs);
        app.use(Content);
        app.use(Tooltip);
    }
};