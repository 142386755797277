<template>
<slot></slot>
</template>
<script>
import { Tooltip } from "bootstrap";
export default {
    name: 'tooltip',
    props: {
        title: undefined,
        placement: undefined,
        offset: undefined,
        html: undefined,
        delay: undefined,
        container: undefined,


    },
    data() {
        return {
            tooltip: null
        }
    },
    mounted() {
        this.load();
    },
    beforeUpdate() {
        this.load();
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            if (this.$slots.default()?.length && this.$el) {
                let element = this.$el.nextSibling;
                if (element) {
                    let pr = {};
                    for (var n in this.$props)
                        if (this[n] !== undefined)
                            pr[n] = this[n];
                    if (!pr.offset && (pr.placement == 'right' || pr.placement == 'left')) {
                        pr.offset = [20, 10];
                    }
                    this.tooltip = new Tooltip(element, pr);
                } else {
                    this.tooltip = null;
                }
            } else this.tooltip = null;
        }
    }
}
</script>