import { Profile } from "@/core/scoped/entities/Root/Profile";
import { countries } from "countries-list";
import CommunityProfileApi from "@/core/scoped/services/api/Community/profile";
export class CommunityProfile extends Profile { //Community because this will get your own profile only, so you require to be logged in
    static get JSONLDType() { return "Profile"; }

    #workRegions;
    #canWorkInEU;
    #codeVisaCache = {};
    constructor() {
        super();
    }

    async hasCompletedOnboarding() {
        return await new CommunityProfileApi().hasCompletedOnboarding();
    }

    get workRegionCodes() {
        if (this.#workRegions == null) {
            let worker = (cnt) => {
                cnt.forEach(n => {
                    let found = false;
                    for (let k in countries) {
                        if (countries[k].name.toLowerCase().trim() == n.toLowerCase().trim()) {
                            this.#workRegions.push(k);
                            found = true;
                            break;
                        }
                    } if (!found) {
                        for (let k in countries) {
                            if (countries[k].name.toLowerCase().trim().includes(n.toLowerCase().trim()) && countries[k].name.lenght < (n.length + 5)) { // alow for "netherlands <> the netherlands"
                                this.#workRegions.push(k);
                                found = true;
                                break;
                            }
                        }
                    }
                });
            };
            this.#workRegions = [];
            if (this.workCountries) {
                worker(this.workCountries);
            }
            if (this.nationalities) {
                worker(this.nationalities);
            }
        }
        return this.#workRegions;
    }
    get canWorkInEU() {
        if (this.#canWorkInEU === true || this.#canWorkInEU === false)
            return this.#canWorkInEU;
        this.workRegionCodes.forEach(code => {
            if (window.schengenCountries[code.toUpperCase()] != undefined) {
                this.#canWorkInEU = true;
            }
        });
        if (this.#canWorkInEU !== true)
            this.#canWorkInEU = false;
        return this.#canWorkInEU;
    }

    requiresVisaFor(employmentPosition) {

        if (employmentPosition != null) {
            if (employmentPosition.locationCode) {
                if (this.#codeVisaCache[employmentPosition.locationCode.toUpperCase()] !== undefined)
                    return this.#codeVisaCache[employmentPosition.locationCode.toUpperCase()];
                let posIsEU = window.schengenCountries[employmentPosition.locationCode.toUpperCase()] != undefined;
                if (this.workRegionCodes) {
                    if (posIsEU) {
                        if (this.canWorkInEU) {
                            this.#codeVisaCache[employmentPosition.locationCode.toUpperCase()] = false;
                            return false;
                        }
                        this.#codeVisaCache[employmentPosition.locationCode.toUpperCase()] = true;
                        return true;
                    } else {
                        let result = this.workRegionCodes.find(r => r.toUpperCase() == employmentPosition.locationCode.toUpperCase()) == null;
                        this.#codeVisaCache[employmentPosition.locationCode.toUpperCase()] = result;
                        return result;
                    }
                }
            }
        }
        return undefined;
    }

}