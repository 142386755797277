import apiClient from "./api";
import baseApi from "./baseApi";
import state from "@/services/state/state";
export default class SkillTreeApi extends baseApi {

	constructor() {
		super();
		this.subURL = "/skilltree/";
	}

	async getAllSkills(ignoreCache) {
		if (!ignoreCache && SkillTreeApi.getSkillsPromise) {
			return SkillTreeApi.getSkillsPromise;
		}
      
		const promise = new Promise(async (resolve, reject) => {
			const skills = state.allskills;
			if (!skills || ignoreCache) {
				try {
					const response = await apiClient.standardGet(this.subURL + "skills");
					const value = JSON.parse(JSON.stringify(response));
					SkillTreeApi.cachedSkills = apiClient.modelBuilder.getModel(value);
					state.registerSession("allskills", value);
					SkillTreeApi.getSkillsPromise = null;
					resolve({ ...SkillTreeApi.cachedSkills });
				} catch (error) {
					reject(error);
				}
			} else {
				if (!SkillTreeApi.cachedSkills) {
					SkillTreeApi.cachedSkills = apiClient.modelBuilder.getModel(JSON.parse(JSON.stringify(skills)));
				}
				SkillTreeApi.getSkillsPromise = null;
                resolve({ ...SkillTreeApi.cachedSkills });
			}
		});
      
		if (!ignoreCache) {
			SkillTreeApi.getSkillsPromise = promise;
		}
      
		return promise;
	}

	async getIncludingSkills(ignoreCache) {
		return new Promise((resolve, reject) => {
			var skills = state.fulltree;
			if (!skills || ignoreCache) {
				apiClient
					.get(this.subURL + "?includeSkills=1")
					.then((data) => {
						state.registerSession("fulltree", JSON.parse(JSON.stringify(data.data)));
						resolve(data.data);
					}).catch((error) => {
						reject(error);
					});
			} else {
				resolve(apiClient.modelBuilder.getModel(JSON.parse(JSON.stringify(skills))));//You need to return the pure skills data object, not the state base object
			}
		});
	}
}
