import apiClient from "./api";

export default class {

	//Silent allows you to disable the default alerts on api errors for one call exactly
	//ite returns the object itself such that you can call api.function to show the error in an alert or api.silent.function to not show it
	get silent() {
		apiClient.silent = true;
		return this;
	}

	set noCache(v) {
		apiClient.noCache = v;
	}

	get noCache() {
		return apiClient.noCache;
	}
    
	constructor() {
	}
}