<template>
<div class="rounded-image-holder rounded-circle" ref="img" :style="{
    backgroundImage: dataSource ? dataSource : '',
    width: rWidth ? rWidth : '',
    height: rHeight ? rHeight : '',
    backgroundColor: '#fff',
}"></div>
</template>
<script>
import ProfileClient from "@/core/scoped/services/api/Community/profile";
import state from "@/services/state/state";
export default {
    name: "profile-picture",
    emits: [],
    props: {
        profileId: {
            type: String,
            required: false,
        },
        src: {
            required: false,
            type: String,
        },
        width: {
            required: false,
            type: String,
        },
        height: {
            required: false,
            type: String,
        },
        withSkills: {
            required: false,
            type: Boolean,
            default: false,
        },
    },
    watch: {
        src(val) {
            this.imageSource = val;
        },
        width(v) {
            this.rWidth = v;
            this.keepSquare();
        },
        height(v) {
            this.rHeight = v;
            this.keepSquare();
        },
        imageSource(v) {
            let header = {};

            if (v instanceof URL) v = v.toString();
            if (v.indexOf("data:image") === 0) {
                this.dataSource = "url('" + v + "')";
                return;
            }
            if (state.user && state.user.profileId && (v.indexOf("http") !== 0 || v.includes(window.apiSettings.URL)))
                header = { Authorization: "Bearer " + state.user.bearerToken };
            fetch(this.imageSource, { headers: header })
                .catch((er) => {
                    this.dataSource = "url('" + this.backupSource + "')";
                })
                .then((response) => {
                    if (response?.ok) {
                        this.$nextTick(() => {
                            response
                                .blob()
                                .then((imageBlob) => {
                                    if (imageBlob.type.includes("text/plain")) {
                                        imageBlob.text().then((text) => {
                                            fetch(text, { mode: "cors" }).then((response) => {
                                                response.blob().then((blob) => {
                                                    let objectURL = URL.createObjectURL(blob);
                                                    this.dataSource = "url('" + objectURL + "')";
                                                    if (this.imageSource.endsWith("/profile/picture")) {
                                                        state.profileImage = this.dataSource;
                                                    }
                                                });
                                            });
                                        });
                                    } else {
                                        let objectURL = URL.createObjectURL(imageBlob);
                                        this.dataSource = "url('" + objectURL + "')";
                                        if (this.imageSource.endsWith("/profile/picture")) {
                                            state.profileImage = this.dataSource;
                                        }
                                    }
                                })
                                .catch((er) => {
                                    this.dataSource = "url('" + this.backupSource + "')";
                                });
                        });
                    } else {
                        this.dataSource = "url('" + this.backupSource + "')";
                    }
                });
        },
    },
    data() {
        return {
            profileClient: new ProfileClient(),
            imageSource: "",
            rWidth: "",
            rHeight: "",
            apiUrl: window.apiSettings.URL,
            backupSource: require("@/assets/svgs/profilenotfound.svg"),
            dataSource: null,
        };
    },
    mounted() {
        if (!this.src && !this.profileId) {
            this.imageSource = window.apiSettings.URL + "/profile/picture";
            if (state.profileImage) this.dataSource = state.profileImage;
            this.$nextTick(() => {
                this.keepSquare();
            });
        } else if (this.src) {
            this.imageSource = this.src;
        } else if (this.profileId) {
            this.imageSource = window.apiSettings.URL + "/profile/" + this.profileId + "/picture";
        }
        this.keepSquare();
        this.$nextTick(() => {
            this.keepSquare();
        });
        window.addEventListener("safeResize", this.keepSquare);
    },
    beforeUnmount() {
        window.removeEventListener("safeResize", this.keepSquare);
    },
    methods: {
        reload() {
            if (this.src) this.imageSource = this.src + "?refresh=" + new Date().toISOString();
            else if (this.profileId) this.imageSource = window.apiSettings.URL + "/profile/" + this.profileId + "/picture?refresh=" + new Date().toISOString();
            else this.imageSource = window.apiSettings.URL + "/profile/picture?refresh=" + new Date().toISOString();
            this.$nextTick(() => {
                this.keepSquare();
            });
        },
        setBoth() {
            this.rWidth = this.width;
            this.rHeight = this.height;
            if (this.rHeight && !this.rWidth) {
                this.rWidth = this.rHeight;
            } else if (this.rWidth && !this.rHeight) {
                this.rHeight = this.rWidth;
            }
        },
        keepSquare() {
            if (!this.$refs.img) return;
            this.setBoth();
            let retick = 4;

            let func = () => {
                let w = this.$refs.img.offsetWidth;
                let h = this.$refs.img.offsetHeight;
                if (w > h + 3) {
                    this.rHeight = h + "px";
                    this.rWidth = h + "px";
                } else if (h > w + 3) {
                    this.rHeight = w + "px";
                    this.rWidth = w + "px";
                }
            };
            let dunc = () => {
                // no clue why but somehow we need more than 2 ticks before the div is loaded to be the correct size...
                this.$nextTick(() => {
                    retick--;
                    if (retick <= 0) func();
                    else dunc();
                });
            };
            dunc();
        },
    },
};
</script>
<style scoped lang="scss">
.rounded-image-holder {
    width: 40px;
    height: 40px;
    max-width: 100%;
    max-height: 100%;
    background-size: cover;
    background-position: center center;
    display: inline-block;
}
</style>
