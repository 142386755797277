import { BaseModel } from "@/core/scoped/entities/BaseModel";
import { RoleArray } from "@/core/scoped/entities/Root/Role";
import { AdminCreditSheet } from "@/core/scoped/entities/SysAdmin/Creditsheet";
export class Customer extends BaseModel {
    static get JSONLDType() { return "Customer"; }
    #activeMemberships;
    #activeExpires;
    #anyExpires;
    constructor() {
        super();

        this.makeTypeGetSet('creditSheets', Array.of(AdminCreditSheet));
    }
    get profileNameDifferent() {
        return this.profile != null && (this.name != `${this.profile.firstName} ${this.profile.lastName}`);
    }
    get profileEmailDifferent() {
        return this.profile != null && (!this.profile.emailAddresses?.find(e => e?.toLowerCase() == this.emailAddress?.toLowerCase()));
    }
    get subscriptionStatus() {
        if (this.activeSubscriptions?.length)
            return 'active';
        else return (this.subscriptions?.find(f => f))?.status ?? null;
    }
    get activeSubscriptions() {
        return this.subscriptions?.filter(f => f.status == 'active');
    }
    get activeMemberships() {
        this.#activeMemberships ??= this.membershipRoles?.filter(r => r.status == 'active' && (r.expires == null || r.expires > new Date()));
        return this.#activeMemberships;
    }
    get firstActiveExpires() {
        if (this.#anyExpires === undefined) {
            if (this.membershipRoles?.length && this.membershipRoles.find(f => f.expires))
                this.#anyExpires = new Date(Math.min.apply(null, this.membershipRoles.map(f => f.expires).filter(f => f)));
            else this.#anyExpires = null;
        }
        return this.#anyExpires;
    }
    get firstExpires() {
        if (this.#activeExpires === undefined)
            if (this.activeMemberships?.length && this.activeMemberships.find(f => f.expires))
                this.#activeExpires = new Date(Math.min.apply(null, this.activeMemberships.map(f => f.expires).filter(f => f)));
        this.#activeExpires = null;
        return this.#activeExpires;
    }
    get activeMemberRoles() {
        return this.activeMemberships?.map(r => r.level)?.join(',').split(',').map(r => r.replaceAll(" ", "")).distinct();
    }
    get membershipStatus() {
        if (this.membershipRoles && this.membershipRoles.length) {
            if (this.activeMemberships?.length > 0)
                return 'active';
            else return this.membershipRoles[0].status;
        }
        return null;
    }
    load(source) {
        this.makeTypeGetSet('membershipRoles', RoleArray);
        super.load(source, false);
    }
}