
export class SDGProvider {

	constructor() {
	}

	getSDGs() {
		return [
			{
				noPoverty: "Goal 1: No Poverty",
				id: 1
			},
			{
				zeroHunger: "Goal 2: Zero Hunger",
				id: 2
			},
			{
				goodHealthAndWellBeing: "Goal 3: Good Health And Well Being",
				id: 4
			},
			{
				qualityEducation: "Goal 4: Quality Education",
				id: 8
			},
			{
				genderEquality: "Goal 5: Gender Equality",
				id: 16
			},
			{
				cleanWaterAndSanitation: "Goal 6: Clean Water And Sanitation",
				id: 32
			},
			{
				affordableAndCleanEnergy: "Goal 7: Affordable And Clean Energy",
				id: 64
			},
			{
				decentWorkAndEconomicGrowth: "Goal 8: Decent Work And Economic Growth",
				id: 128
			},
			{
				industryInnovationAndInfrastructure: "Goal 9: Industry Innovation And Infrastructure",
				id: 256
			},
			{
				reducedInequalities: "Goal 10: Reduced Inequalities",
				id: 512
			},
			{
				sustainableCitiesAndCommunities: "Goal 11: Sustainable Cities And Communities",
				id: 1024
			},
			{
				responsibleConsumptionAndProduction: "Goal 12: Responsible Consumption And Production",
				id: 2048
			},
			{
				climateAction: "Goal 13: Climate Action",
				id: 4096
			},
			{
				lifeBelowWater: "Goal 14: Life Below Water",
				id: 8192
			},
			{
				lifeOnLand: "Goal 15: Life On Land",
				id: 16384
			},
			{
				peaceJusticeAndStrongInstitutions: "Goal 16: Peace Justice And Strong Institutions",
				id: 32768
			},
			{
				partnershipsForTheGoals: "Goal 17: Partnerships For The Goals",
				id: 65536
			},
		];
	}
}
export default {
	_sdgProvider: new SDGProvider(),
	computed: {
		//With the computed variables we expose part(s) of the state we know can be there by default
		$sdgProvider() {
			if (!this._sdgProvider);
			this._sdgProvider = new SDGProvider();
			return this._sdgProvider;
		},
	}
};