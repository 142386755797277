import { Converter } from "showdown";
import parrots from "./parrots.js";
import icons from "./icons.js";
export default class FPConverter extends Converter {
	constructor() {
		super();
		this.icons = parrots.filter(f => {
			var d = parrots.filter(k => { return k.name == f.name; });
			if (d.length > 1) {
				if (f.url.indexOf("/hd") !== -1) {
					return true;
				} return false;
			} return true;
		});
		this.icons = this.icons.concat(icons);
	}
	makeHtml() {
		var newText = super.makeHtml(...arguments);
		return this.getEmoticons(newText, true);
	}
	getEmoticons(text, keephtml) {
		if (text) {
			if (!keephtml) {
				text = text
					.replace(/&/g, "&amp;")
					.replace(/</g, "&lt;")
					.replace(/>/g, "&gt;")
					.replace(/"/g, "&quot;")
					.replace(/'/g, "&#039;");
			}
			for (var i = 0; i < this.icons.length; i++) {
				if (text.indexOf(':' + this.icons[i].name + ':') !== -1) {
					text = text.replaceAll(':' + this.icons[i].name + ':', '<img class="emoticon ' + this.icons[i].name + '" src="' + require('@/assets/' + this.icons[i].url) + '" />');
				}
			}
		}
		return text;
	}
}