import apiClient from "./api";
import baseApi from "@/core/scoped/services/api/baseApi";
export default class JourneyApi extends baseApi {

    constructor() {
        super();
        this.subURL = "/career/journey/";
    }

    async list(filter) {
        return await apiClient.standardGet(this.subURL + "list", { params: filter });
    }

    async getJourneyProducts() {
        return await apiClient.standardGet(this.subURL + "products");
    }

    async getProductsByJourneyId(id) {
        return await apiClient.standardGet(this.subURL + "products/" + id);
    }

    async getMentorshipsContent() {
        return await apiClient.standardGet("/frontend/content/mentorships");
    }

    async getMentorshipTracks() {
        let content = await import('@/assets/json/hcMentorshipTracks.js');
        let c = JSON.parse(JSON.stringify(content.default));
        let obj = apiClient.modelBuilder.getModel(c);
        return obj;
    }


}
