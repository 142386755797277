
import { defineAsyncComponent } from 'vue';
const DateTile = defineAsyncComponent(() => import("./molecules/header/date-tile/date-tile.vue"));
const ContentCard = defineAsyncComponent(() => import("./content-card.vue"));
const PopOver = defineAsyncComponent(() => import("./popover-card.vue"));
export default {
    install: (app, options) => {
        app.component('date-tile', DateTile);
        app.component('popover', PopOver);
        app.component('content-card', ContentCard);
    }
};
