import { RoleArray } from "@/core/scoped/entities/Root/Role";
import { CommunityActivity } from "@/core/scoped/entities/Root/Community/Activity";
import { BaseModel } from "@/core/scoped/entities/BaseModel";
export class ActivityWithRoles extends BaseModel { //Community because this will get your own profile only, so you require to be logged in
    static get JSONLDType() { return "activityWithRoles"; }

    constructor() {
        super();
        this.makeTypeGetSet('activity', CommunityActivity);
        this.makeTypeGetSet('roles', RoleArray);
    }



}