import EventEmitter from 'events';
import styles from '/src/styles/variables.module.scss';

import { reactive } from 'vue'
// This class adds the connection between css media breakpoints an javascript
// Since we use vue reactive, this class adds the possibility to do vue-if="$media.below.lg"
// it also adds the option to add lg-class="" to any html element causing that value to be added to the classlist when screensize goes above lg breakpoint
class MediaStateEmitter extends EventEmitter {

    constructor() {
        super();
        this.queryWatchers = {};
        this.below = {};
        this.above = {};
        this.below = reactive(this.below);
        this.above = reactive(this.above);
        this.activateMediaQuery();//put in method so you can turn it off if perfomance suffers too much        
    }
    activateMediaQuery() {
        this.changeDebounce = false;
        let order = [];
        for (var n in styles) {
            if (n.indexOf("breakpoint-") == 0) {
                this.intitializeQuery(n.replace("breakpoint-", ""), styles[n]);
                order.push({ name: n, value: Number.parseFloat(styles[n].replace("px", "")) });
            }
        }
        order.push({ name: "b", value: 0 }); //base class
        this.below.b = false;
        this.above.b = true;
        order.sort((a, b) => {
            return b.value - a.value;
        });
        order = order.map(s => s.name.replace("breakpoint-", ""));
        this.order = order;
        //  this.activateMediaClasses(); //deactivated for performance
    }
    activateMediaClasses() {

        let attrFilter = this.order.map(s => s + "-class");
        this.attributeObserver = new MutationObserver((m) => this.observationChange(m));
        this.attributeObserver.observe(document.querySelector("body"), {
            subtree: true,
            childList: true,
            attributeOldValue: true,
            attributeFilter: attrFilter,
        });
        this.querySelector = "";
        this.order.forEach(n => {
            this.querySelector += "[" + n + "-class],"
        });
        this.querySelector = this.querySelector.substring(0, this.querySelector.length - 1)
        this.doDocumentChange();
    }
    observationChange(mutationList) {
        let shouldStage = false;
        mutationList.forEach(e => {
            if (e.type == "childList") {
                shouldStage = true;
            }
            else if (e.type == "attributes") {
                if (e.target) {
                    if (e.oldValue)
                        e.target.classList.remove(e.oldValue);
                    this.checkAttributeStatus(e.target, e);
                }
            }
        });
        if (shouldStage) this.stageDocumentChange();
    }
    stageDocumentChange() {
        if (this.changeDebounce)
            clearTimeout(this.changeDebounce);
        this.changeDebounce = setTimeout(() => {
            this.doDocumentChange();
        }, 50);
    }
    doDocumentChange() {
        let nodes = [...document.querySelectorAll(this.querySelector)];
        nodes.filter((value, index, array) => array.indexOf(value) === index
        ).forEach(n => this.checkAttributeStatus(n));
    }
    checkAttributeStatus(node, e) {
        let startedSetting = false;
        for (let i in this.order) {
            let n = this.order[i];
            if (node.getAttribute) {
                let attr = node.getAttribute(n + "-class");
                if (attr) {
                    if (this.above[n] && !startedSetting) {
                        node.classList.add(...attr.split(" "));
                        startedSetting = true;
                    }
                    else node.classList.remove(...attr.split(" "));
                }
            }
        }
    }
    intitializeQuery(breakpoint, screenWidth) {
        const mql = window.matchMedia(`(max-width: ${screenWidth})`);
        if (mql.matches) {
            this.below[breakpoint] = true;
        } else {
            this.below[breakpoint] = false;
        }
        this.above[breakpoint] = !this.below[breakpoint];
        mql.onchange = (e) => {
            if (e.matches) {
                this.below[breakpoint] = true;
            } else {
                this.below[breakpoint] = false;
            }
            this.above[breakpoint] = !this.below[breakpoint];
            this.emit('breakpointChanged', breakpoint, e.matches, e);
            if (this.attributeObserver)
                this.doDocumentChange();
        };
        this.queryWatchers[breakpoint] = mql;
    }



}
export default {
    install: (app, options) => {
        app.config.globalProperties.$media = new MediaStateEmitter();
    }
};