import apiClient from "./api";
import baseApi from "./baseApi";

export default class ActivityApi extends baseApi {

    constructor() {
        super();
        this.subURL = "/activity/";
        this.uploadProgress = -1;
    }

    async overviewList(queryvars) {
        let pp;
        if (queryvars) {
            pp = { params: queryvars };
        }
        return await apiClient.standardGet(this.subURL + "list", pp);
    }

    async getMentors(id) {
        return await apiClient.standardGet(this.subURL + id + "/mentors");
    }

    async requestEnterprise(id, enterpriseData) {
        return await apiClient.standardPost(this.subURL + id + "/enterprise/request", enterpriseData);
    }

    async getActivityFromMagicLink(magicLink) {
        return await apiClient.standardGet(this.subURL + "decodeInviteMagicLink?magicLink=" + magicLink);
    }

    async searchActivities(title, moreVars = null) {
        let pars = {
            filter: title,
            includeInformation: 0,
            countRoles: 0,
            quick: 1
        }
        if (moreVars)
            for (var n in moreVars)
                pars[n] = moreVars[n];
        return await apiClient.standardGet(this.subURL + "list", { params: pars });
    }

    async get(id, extracallback) {
        return await this.getChallenge(id, extracallback);
    }


    async getChallenge(id, extracallback) {
        return await apiClient.standardGet(this.subURL + id, { params: { partners: 1, posts: 1, reviews: 1, rating: 1, results: 1, nocache: 1 }, extracallback });
    }

    async getSummary(id, extracallback) {
        return await apiClient.standardGet(this.subURL + id + "/summary", { params: {}, extracallback });
    }

    async getChallengeNoCache(id) {
        return await apiClient.standardGet(this.subURL + id, { params: { partners: 1, posts: 1, reviews: 1, rating: 1, results: 1, nocache: 1 } });
    }

    async getUpcommingActivity(id) {
        return await apiClient.standardGet(this.subURL + id + "/related/upcomming");
    }

    async getRelatedActivitySummaries(activityId) {
        return await apiClient.standardGet(this.subURL + activityId + "/related/summaries");
    }

    async getActivitySummariesBulk(activityIds) {
        return await apiClient.standardPost(this.subURL + "summaries/bulk", activityIds);
    }
}
