<template>
  <div class="loader-line">
    <div
      v-for="i in number"
      :key="i"
      :style="{ animationDelay: (0.75 / number) * (i / 2) + 's', backgroundImage:image?'url(\''+image+'\')':'' }"
      :class="'me-1 ' + (image ? 'image-blob' : '') + ' spinner-grow ' + (size ? 'spinner-grow-' + size : '') + ' text-' + ($attrs.class ? $attrs.class : 'fp-pink')"
      role="status"
    >
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>
<script>
export default {
	name: "loader-line",
	props: {
		number: {
			type: Number,
			default: 4,
		},
		size: {
			type: String,
			default: "",
		},
		image: {
			type: String,
			default: null,
			required: false,
		},
	},
	mounted() {},
};
</script>
<style scoped lang="scss">
.loader-line {
  display: inline-block;
}

.loader-line > .spinner-grow {
  display: inline-block;
}

.image-blob.spinner-grow {
  background-color: transparent;
  border: none;
  background-size: contain;
  background-repeat: no-repeat;
}
</style>
