<template>
	<overlay-card ref="loginCard" :header="header" :underHeader="underHeader">
		<div class="p-3 signin-container">
			<login @navigated="this.close()" @loggedin="loggedIn" :cardMode="true" :overrideReturnUrl="overrideReturnUrl">
			</login>
		</div>
	</overlay-card>
</template>
<script>
import OverlayCard from "@/components/misc/layout/overlay/overlay-card";
import Login from "@/components/account/login";
export default {
	name: "one-click-signin",
	components: { OverlayCard, Login },
	mounted() {
		this.$inheritMethods(this.$refs.loginCard);
	},
	props: {
		overrideReturnUrl: {
			default: null,
		},
		header: {
			type: String,
			required: false,
			default: "Sign-in required",
		},
		underHeader: {
			type: String,
			required: false,
			default: "You need to sign up or sign in to perform this action.",
		}
	},
	data() {
		return {};
	},
	methods: {
		loggedIn() {
			this.close();
			document.location.reload();
		},
	},
};
</script>
<style scoped>
.signin-container {
	min-width: 430px;
}

@media (max-width: 900px) {
	.signin-container {
		min-width: 30px;
	}
}

@media (max-height: 800px) {
	.signin-container:deep(.fp-text-logo) {
		display: none;
	}
}
</style>
