import { BaseModel } from "@/core/scoped/entities/BaseModel";
import SkillTreeApi from "@/core/scoped/services/api/skilltree";
import router from "@/core/global/router";

export class Post extends BaseModel {
    static get JSONLDType() { return "Post"; }

    #updatedContent;
    #showingCommentSection;
    #headerImageUrl;
    #relatedSkills;

    static skillApi = new SkillTreeApi();
    get showingCommentSection() {
        return this.#showingCommentSection;
    }

    set showingCommentSection(value) {
        this.#showingCommentSection = value;
    }

    get updatedContent() {
        return this.#updatedContent;
    }

    set updatedContent(v) {
        this.#updatedContent = v;
    }

    get shortenedContent() {
        return this.content?.substring(0, 1000);
    }

    get hasRequestedPublishment() {
        return this.requestedPublishDateTime != null;
    }

    get hasRequestedUnpublishment() {
        return this.requestedUnpublishDateTime != null;
    }
    get toRoute() {
        if (this.customId) {
            var date = new Date(this.createdAt);
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            var day = date.getDate()
            if (month < 10) month = "0" + month;
            if (day < 10) day = "0" + day;
            return {
                name: 'articleFullPage',
                params: {
                    year: year,
                    month: month,
                    day: day,
                    customId: this.customId
                }
            }
        } else {
            return {
                path: `/article/${this.id}`
            }
        }
    }

    get canonicalUrl() {
        const route = router.resolve(this.toRoute);
        return new URL(route.href, window.location.origin).href;
    }

    get headerImageUrl() {
        if (this.#headerImageUrl == null) {
            this.#headerImageUrl = window.apiSettings.URL + "/post/" + this.id + "/resource?locationIdentifier=attachment&redirect=1";
        }
        return this.#headerImageUrl;
    }

    isAuthorOfPost(profileId) {
        return this.createdByProfileId == profileId;
    }

    async getRelatedSkills() {
        if (this.relatedSkills)
            if (this.#relatedSkills == null) {
                let allSkills = await Post.skillApi.getAllSkills();
                this.#relatedSkills = Object.values(allSkills).filter(v => this.relatedSkills.find(r => r == v.id) !== undefined);
            }
        return this.#relatedSkills;
    }

    constructor() {
        super();
    }
}