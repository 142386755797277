import { BaseModel } from "@/core/scoped/entities/BaseModel";
export class MembershipPricing extends BaseModel {
    static get JSONLDType() { return "membershipPricing"; }

    get singleYearPrice() {
        if (!this.recurringPriceSettings)
            return null;
        let int = this.recurringPriceSettings.interval;
        let len = this.recurringPriceSettings.intervalCount;
        let price = this.priceInCents / len;
        if (int == "month")
            price *= 12;
        if (int == "day")
            price *= 365;
        if (int == "week")
            price *= 52;
        return price;
    }
    get priceString() {
        return new Intl.NumberFormat("en-US", {
            style: 'currency', currency: this.currency?.toUpperCase(),
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format((this.priceInCents / 100),);
    }

    get recurringString() {
        if (!this.recurringPriceSettings?.interval)
            return null;
        let obj = {
            year: 'y',
            month: 'mo',
            week: "w",
            day: "d"
        };

        return "/" + (this.recurringPriceSettings.intervalCount > 1 ? (this.recurringPriceSettings.intervalCount + " ") : "") + obj[this.recurringPriceSettings.interval];
    }

    constructor() {
        super();
    }
}