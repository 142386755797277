import { BaseModel } from "@/core/scoped/entities/BaseModel";
import { Role, RoleArray } from "@/core/scoped/entities/Root/Role";
export class Organization extends BaseModel {
    static get JSONLDType() { return "Organization"; }

    constructor() {
        super();
    }

    get imageUrl() {
        return window.apiUrl + '/organization/' + this.id + '/resource/?locationIdentifier=logo';
    }

    load(source, freeze = true) {
        super.load(source, false);
        this.makeTypeGetSet("roles", RoleArray);
    }

}