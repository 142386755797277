import { CommunityActivity } from "@/core/scoped/entities/Root/Community/Activity";

import { JourneyTemplate } from "@/core/scoped/entities/Root/Community/Journey/JourneyTemplate";
export class JourneyActivity extends CommunityActivity { //Community because this will get your own profile only, so you require to be logged in
    static get JSONLDType() { return "Activity"; }

    constructor() {
        super();
        this.makeTypeGetSet('journey', JourneyTemplate);
    }



}