import OrganizationApi from "@/core/scoped/services/api/organization";
import apiClient from "./api";
import state from "@/core/global/state/state";

export default class CommunityOrganizationApi extends OrganizationApi {

	constructor() {
		super();
	}

	async getPersonal() {
		return await apiClient.standardGet(this.subURL, { params: { personalOnly: true, start: 0, count: null } });
	}

	async search(filter = undefined, start = 0, count = 10, orderBy = 'name', orderByAscending = 0, personalOnly = 0) {
		var search = {};
		if (filter != undefined)
			search.filter = filter;
		search.start = start;
		search.count = count;
		search.orderBy = orderBy;
		search.orderByAscending = orderByAscending;
		search.personalOnly = personalOnly;
		return await apiClient.standardGet(this.subURL, { params: search });
	}

	async getInvitations() {
		return await apiClient.standardGet(this.subURL + "invitations", { params: { nocache: true } });
	}

	async acceptInvitation(id) {
		apiClient.clearCache(this.subURL);
		return await apiClient.standardGet(this.subURL + id + "/accept");
	}

	async denyInvitation(id) {
		return await apiClient.standardGet(this.subURL + id + "/deny");
	}

	async logout() {
		apiClient.clearCache();
		state.unregisterSession("accessChecked");
		state.unregisterStored("organization");
	}

}

