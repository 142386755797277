import ActivityApi from "@/core/scoped/services/api/activity.js";
import CommunityCommentApi from "../Community/comment";
import apiClient from "./api";

export default class CommunityActivityApi extends ActivityApi {

    constructor() {
        super();
        this.comments = new CommunityCommentApi();
    }

    async checkConflicts(activityId) {
        return await apiClient.standardGet(this.subURL + activityId + "/checkconflicts/");
    }

    async getRecommendedActivity(skills) {
        skills = skills?.filter(f => f != null) ?? [];
        return await apiClient.standardPost(this.subURL + "recommended", skills);
    }

    async getComments(guid, childguid, updateguid) {
        return await this.comments.getComments(updateguid ? 'post' : 'activity', updateguid ? updateguid : (childguid ? childguid : guid));
    }

    async comment(guid, content, childguid, updateguid, parentCommentId) {
        return await this.comments.addComment(childguid ? childguid : guid, updateguid ? 'post' : 'activity', content, updateguid, parentCommentId);
    }

    async like(guid, childguid, updateguid) {
        return await this.comments.like(childguid ? childguid : guid, updateguid ? 'post' : 'activity', updateguid);
    }

    async likeComment(guid, commentId, childguid, updateguid) {
        return await this.comments.like(childguid ? childguid : guid, updateguid ? 'post' : 'activity', updateguid, commentId);
    }

    async replyToComment(guid, commentId, content, childguid, updateguid) {
        return await this.comments.addComment(childguid ? childguid : guid, updateguid ? 'post' : 'activity', content, updateguid, commentId);
    }

    async getSharedResources(parentId, ignoreCache) {
        return await apiClient.standardGet(this.subURL + parentId + "/sharedresources" + (ignoreCache ? "?nocache=1" : ""));
    }

    async getJoinActivityQuestions(activityId) {
        return await apiClient.standardGet(this.subURL + activityId + "/join/questions");
    }

    async join(id, joinActivityDTO) {
        apiClient.clearCache(this.subURL + id);
        return await apiClient.standardPost(this.subURL + id + "/join", joinActivityDTO);
    }

    async unjoin(id) {
        try {
            const data = await apiClient.delete(this.subURL + id + "/unjoin");
            apiClient.clearCache(this.subURL + id);
            return data.data;
        } catch (error) {
            throw error;
        }
    }

    async follow(id) {
        apiClient.clearCache(this.subURL + id);
        apiClient.clearCache("/profile/activities/following");
        return await apiClient.standardPost(this.subURL + id + "/follow");
    }

    async complete(id, unset) {
        apiClient.clearCache(this.subURL + id);
        return await apiClient.standardPost(this.subURL + id + "/complete", null, { params: unset ? { unset: 1 } : {} });
    }

    async unfollow(id) {
        apiClient.clearCache(this.subURL + id);
        apiClient.clearCache("/profile/activities/following");
        return await apiClient.standardPost(this.subURL + id + "/unfollow");
    }

    async getAgreements(activityId) {
        return await apiClient.standardGet(this.subURL + activityId + "/agreements", { params: { nocache: 1 } });
    }

    async getSignedAgreementVersion(activityId) {
        return await apiClient.standardGet(this.subURL + activityId + "/agreements/signedbyme", { params: { nocache: 1 } });
    }

    async getTestSignAgreement(activityId, documentId, signatureBlob, signingPlace, signingCountry) {
        const file = new File([signatureBlob], "signature", { type: "image/png" });
        var formData = new FormData();
        formData.append("signature", file, "signature.png");
        formData.append("signingPlace", signingPlace + ", " + signingCountry);
        return new Promise((resolve, reject) => {
            this.uploadProgress = 0;
            apiClient
                .post(this.subURL + activityId + "/agreements/testsign/" + documentId, formData, {
                    onUploadProgress: (e) => {
                        this.uploadProgress = (e.loaded / e.total) * 100;
                    }
                })
                .then((data) => {
                    this.uploadProgress = -1;
                    resolve(data.data);
                }).catch((error) => {
                    reject(error);
                });
        });
    }

    async signAgreement(activityId, documentId, signatureBlob, signingPlace, signingCountry) {
        const file = new File([signatureBlob], "signature", { type: "image/png" });
        var formData = new FormData();
        formData.append("signature", file, "signature.png");
        formData.append("signingPlace", signingPlace + ", " + signingCountry);
        return new Promise((resolve, reject) => {
            this.uploadProgress = 0;
            apiClient
                .post(this.subURL + activityId + "/agreements/sign/" + documentId, formData, {
                    onUploadProgress: (e) => {
                        this.uploadProgress = (e.loaded / e.total) * 100;
                    }
                })
                .then((data) => {
                    this.uploadProgress = -1;
                    resolve(data.data);
                }).catch((error) => {
                    reject(error);
                });
        });
    }

    /* Posts */
    async addPost(id, post) {
        return await apiClient.standardPost(this.subURL + id + "/post", post);
    }

    async requestPublishPost(id, postId, requestedPublishDateTime) {
        return await apiClient.patch(this.subURL + id + "/post/" + postId + "/publish/request?requestedPublishDateTime=" + requestedPublishDateTime);
    }

    async requestUnpublishPost(id, postId) {
        return await apiClient.patch(this.subURL + id + "/post/" + postId + "/unpublish/request");
    }

    async overridePostProperties(id, postId, namesWithValues) {
        namesWithValues.forEach(nameWithValue => {
            nameWithValue.name = nameWithValue.name.replaceAll(".", "/");
        });

        var override = namesWithValues.map((nameWithValue) => ({
            op: "replace",
            path: "/" + nameWithValue.name,
            value: nameWithValue.value
        }));

        return await apiClient.genericPatch(this.subURL + id + "/post/" + postId, override);
    }

    async addResource(id, postId, file, locationIdentifier) {
        return await apiClient.standardPost(this.subURL + id + "/post/" + postId + "/resource?locationIdentifier=" + locationIdentifier, file);
    }

    async rate(id, value, withMessage = null) {
        return await apiClient.standardPost(`/comment/rate/${id}`, {
            targetType: 'activity',
            rating: value,
            content: withMessage
        });
    }
}
