import { EventEmitter } from 'events';
export class BaseModel extends EventEmitter {
    #source;
    #typeVars;
    #events;
    #eventsCount;
    #maxListeners;
    get source() {
        return this.#source;
    } set source(v) {
        this.#source = source;
    }
    constructor() {
        super();
        //The whole next bit is to prevent the serialization of the events into json
        this.#events = this._events;
        delete this._events;
        this.#eventsCount = this._eventsCount;
        delete this._eventsCount;
        Object.defineProperty(this, '_events', {
            get() {
                return this.#events;
            }, set(value) {
                this.#events = value;
            }
        });
        Object.defineProperty(this, '_eventsCount', {
            get() {
                return this.#eventsCount;
            }, set(value) {
                this.#eventsCount = value;
            }
        });
        Object.defineProperty(this, '_maxListeners', {
            get() {
                return this.#maxListeners;
            }, set(value) {
                this.#maxListeners = value;
            }
        });
    }
    getChanges(from = this.#source) {
        if (from) {
            var changes = [];
            for (var k in JSON.parse(JSON.stringify(this))) {
                if (!from[k] || from[k] != this[k]) {
                    changes.push(k);
                }

            }
            return changes;
        }
        return null;
    }

    load(source, freeze = true) {
        for (var n in source) {
            if (n == 'state')
                continue;
            var desc = Object.getOwnPropertyDescriptor(this, n);

            try {
                if (!desc || desc.writable || desc.set)
                    this[n] = source[n];

            } catch (ex) {

            }
        }
        this.#source = source;
        this.makeDateGetSet('createdAt');
        this.makeDateGetSet('updatedAt');

        // Commented because watchers don't trigger for entities.
        //if (freeze)
        //    Object.preventExtensions(this);
    }

    static getEntityName() {
        if (this.JSONLDType)
            return this.JSONLDType[0].toLowerCase() + this.JSONLDType.substring(1);
        return this.name[0].toLowerCase() + this.name.substring(1);//WATCH OUT: this doesn't work on production build because it renames classes.
    }
    makeDateGetSet(prop) {
        this.makeTypeGetSet(prop, Date);
    }
    makeTypeGetSet(prop, type) {
        if (!this.#typeVars)
            this.#typeVars = {};
        if (this.#typeVars['_' + prop] !== undefined) {
            return;//property already made.
        }
        this.#typeVars['_' + prop] = this[prop];
        try {
            delete (this[prop]);
        } catch { }
        let descriptor1 = Object.getOwnPropertyDescriptor(this, prop);
        if (descriptor1 == null || !(descriptor1.get || descriptor1.set))
            Object.defineProperty(this, prop, {
                enumerable: true,
                get() {
                    let v = this.#typeVars['_' + prop];
                    if (v != undefined && v != null) {
                        if ((!Array.isArray(type) || (type.name && type.name == 'RoleArray')) && v instanceof type)
                            return v;
                        try {
                            if (Array.isArray(type) && !(type.name && type.name == 'RoleArray')) {
                                if (Array.isArray(v)) {
                                    if (!v.every(inst => (inst instanceof type[0]))) {
                                        let result = v.map(vInstance => {
                                            var obj = new type[0]();
                                            obj.load(vInstance);
                                            return obj;
                                        });
                                        this.#typeVars['_' + prop] = result;
                                        return result;
                                    }
                                    return v;
                                }
                                else {
                                    let holder = new type[0]();
                                    holder.load(v);
                                    this.#typeVars['_' + prop] = [holder];
                                    return this.#typeVars['_' + prop];
                                }
                            } else {
                                let holder = new type();
                                if (holder.load) {
                                    holder.load(v);
                                }
                                else holder = new type(v);
                                this.#typeVars['_' + prop] = holder;
                                return this.#typeVars['_' + prop];

                            }
                        } catch (ex) {
                            return null;
                        }
                    }
                    return null;
                }, set(value) {
                    if (!(value == null || value == undefined)) {
                        if (Array.isArray(type) && !(type.name && type.name == 'RoleArray')) {
                            if (Array.isArray(value)) {
                                if (!value.every(inst => (inst instanceof type[0]))) {
                                    value = value.map(vInstance => {
                                        var obj = new type[0]();
                                        if (obj.load)
                                            obj.load(vInstance);
                                        else obj = new type[0](vInstance);
                                        return obj;
                                    });
                                }
                            }
                            else {
                                let holder = new type[0]();
                                if (holder.load)
                                    holder.load(value);
                                else holder = new type[0](value);
                                value = [holder];
                            }
                        }
                        else if (!(value instanceof type)) {
                            try {
                                let holder = new type();
                                if (holder.load)
                                    holder.load(value);
                                else try {
                                    holder = new type(value)
                                } catch {
                                    holder = null;
                                }
                                value = holder;
                            } catch { }
                        }
                    }
                    this.#typeVars['_' + prop] = value;
                }
            });
    }
    clearTypeVars() {
        this.#typeVars = {};
    }

    static generateGUID() {
        let
            d = new Date().getTime(),
            d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
            let r = Math.random() * 16;
            if (d > 0) {
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else {
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
        });
    };
}