import i18n from "@/core/global/UI/i18n";

export default function Translations(app, options) {
	app.use(i18n);
	app.config.globalProperties.$t = i18n.global.t;
	app.config.globalProperties.$tc = i18n.tc;

	/**
     * A translation function that can capitalize translations.
     *
     * If the the first argument is a boolean this boolean will be used to determine if the
     * returned capitalisation only needs the first letter capitalized or of all words needs to be capitalized.
     * By default all words will be capitalized.
     *
     * @param boolOrPath If this is a boolean it will be interpreted as the firstWordOnlyCapitalCapital value, otherwise it will
     * use the default values and simply return capitalized path
     * @param pathOrLocale Either the path or the locale that needs to be used for translation
     * @param localeOrObjects Either the locale or list of objects that need to be used for translation
     * @param objects The objects that need to be used for translation
     */
	app.config.globalProperties.$ct = (
		boolOrPath,
		pathOrLocale,
		localeOrObjects,
		objects
	) => {
		let firstWordOnlyCapital = false;
		let result;
		if (typeof boolOrPath === "boolean") {
			result = i18n.global.t(pathOrLocale, localeOrObjects, objects);
			firstWordOnlyCapital = boolOrPath;
		} else {
			result = i18n.global.t(boolOrPath, pathOrLocale, localeOrObjects);
		}
		if (firstWordOnlyCapital) {
			return result.charAt(0).toUpperCase() + result.slice(1);
		}
		return result.replace(/\w\S*/g, function (txt) {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		});
	};

	/**
     * A translation function that can capitalize translations with pluralisation's.
     *
     * If the the first argument is a boolean this boolean will be used to determine if the
     * returned capitalisation only needs the first letter capitalized or of all words needs to be capitalized.
     * By default all words will be capitalized.
     *
     * @param boolOrPath If this is a boolean it will be interpreted as the firstWordOnlyCapitalCapital value, otherwise it will
     * use the default values and simply return capitalized path
     * @param pathOrLocale Either the path or the locale that needs to be used for translation
     * @param localeOrObjects Either the locale or list of objects that need to be used for translation
     * @param objects The objects that need to be used for translation
     */
	app.config.globalProperties.$tct = (
		boolOrPath,
		pathOrLocale,
		localeOrObjects,
		objects
	) => {
		let firstWordOnlyCapital = false;
		let result;
		if (typeof boolOrPath === "boolean") {
			result = this.global.tc(pathOrLocale, localeOrObjects, objects);
			firstWordOnlyCapital = boolOrPath;
		} else {
			result = this.global.tc(boolOrPath, pathOrLocale, localeOrObjects);
		}
		if (firstWordOnlyCapital) {
			return result.charAt(0).toUpperCase() + result.slice(1);
		}
		return result.replace(/\w\S*/g, function (txt) {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		});
	};
	return app;
}