import { BaseModel } from "@/core/scoped/entities/BaseModel";

export class Profile extends BaseModel {
    static get JSONLDType() { return "Profile"; }
    #isOtherProfile;
    get isOtherProfile() {
        return this.#isOtherProfile;
    }
    set isOtherProfile(v) {
        this.#isOtherProfile = v;
    }
    constructor() {
        super();
        this.careerIntent = undefined;
    }

    hasBucketProperties(properties) {
        var parts = this.analyticsBucket?.length > 0 ? this.analyticsBucket.replaceAll(' ', '').toLowerCase().split(",") : [];
        return properties.every(property => parts.includes(property.toLowerCase()));
    }
}