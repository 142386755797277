import { BaseModel } from "@/core/scoped/entities/BaseModel";
import { Organization } from "@/core/scoped/entities/Root/Organization";
import { skillTreeBaseNames } from "@/core/scoped/entities/Root/Accreditation/SkillNode";

export class EmploymentPosition extends BaseModel {
    static get JSONLDType() { return "EmploymentPosition"; }
    static baseNodesNames = Object.values(skillTreeBaseNames);

    constructor() {
        super();
    }

    load(source, freeze = true) {
        super.load(source, false);
        this.makeTypeGetSet('organization', Organization);
    }

    getFullSkillModelsBySkillIds(allSkills, skillIds) {
        var foundSkills = [];
        for (var skillId of skillIds) {
            var foundSkill = allSkills[skillId];
            if (foundSkill != null && foundSkill != undefined)
                foundSkills.push(foundSkill);
        }
        return foundSkills.sort((a, b) => {
            const nameA = a.base;
            const nameB = b.base;

            if (nameA != null && nameA != undefined) {
                if (nameB != null && nameB != undefined) {
                    return nameA.localeCompare(nameB); // Sort alphabetically
                } else {
                    return -1;
                }
            } else {
                return 1;
            }
        });
    }

    getMatchResult(allSkills, personalSkillIds, includeRootNodeLevelCalculation) {

        var requiredSkillIds = this.requiredSkills.map(x => x.skillId);

        //Determine the missing and matching skills
        var matchingSkillIds = [];
        var missingSkillIds = [];

        if (personalSkillIds != null && personalSkillIds != undefined && requiredSkillIds != null && requiredSkillIds != undefined) {
            requiredSkillIds.forEach(requiredSkillId => {
                if (personalSkillIds.find(x => x == requiredSkillId) != null) {
                    matchingSkillIds.push(requiredSkillId);
                } else {
                    missingSkillIds.push(requiredSkillId);
                }
            });
        }

        var matchingSkills = this.getFullSkillModelsBySkillIds(allSkills, matchingSkillIds);
        var missingSkills = this.getFullSkillModelsBySkillIds(allSkills, missingSkillIds);

        var totalSkillCount = matchingSkills.length + missingSkills.length;

        var matchResult = {
            missingSkills: missingSkills,
            matchingSkills: matchingSkills,
            matchPercentage: totalSkillCount == 0 ? null : Math.round((matchingSkills.length / totalSkillCount) * 100)
        };

        //Determine the match-percentage and skillcount per root node
        if (includeRootNodeLevelCalculation == true) {
            var missingSkillsPerBaseNode = {};
            var matchingSkillsPerBaseNode = {};
            EmploymentPosition.baseNodesNames.forEach(baseNode => {
                missingSkillsPerBaseNode[baseNode] = missingSkills.filter(x => x.base == baseNode);
                matchingSkillsPerBaseNode[baseNode] = matchingSkills.filter(x => x.base == baseNode);
            });

            matchResult["resultPerBaseNode"] = EmploymentPosition.baseNodesNames.map(baseNode => {
                var matchingSkillsForBaseNode = matchingSkillsPerBaseNode[baseNode];
                var missingSkillsForBaseNode = missingSkillsPerBaseNode[baseNode];
                var totalSkillCountForBaseNode = matchingSkillsForBaseNode.length + missingSkillsForBaseNode.length;

                return {
                    base: baseNode,
                    matchPercentage: totalSkillCountForBaseNode == 0 ? null : Math.round((matchingSkillsForBaseNode.length / totalSkillCountForBaseNode) * 100),
                    skillCount: totalSkillCountForBaseNode
                };
            });
        }
        return matchResult;
    }
}