import { BaseModel } from "@/core/scoped/entities/BaseModel";

const isLevel = function (str) {
    return typeof str == "string" && (window.roleLevelNames.includes(str) || str == "");
};
const getStatus = function (arr) {
    return window.statusNames.filter(s => arr.includes(s));
};
export class Role extends BaseModel {
    static get JSONLDType() { return "Role"; }
    constructor() {
        super();
    }
    load(source, freeze = true) {
        this.makeDateGetSet('expires');
        this.makeDateGetSet('lastChanged');
        super.load(source, false);
        if (this.level != null) {
            //The next bit is purely backward compatibility
            if (this.status == 'requested' || this.level.includes('deprecatedAccessRequested')) {
                this.level = 'accessRequested';
            }
            if (this.status == 'denied' || this.level.includes('deprecatedAccessDenied')) {
                this.level = 'accessDenied';
            }
            if (this.status == 'blocked' || this.level.includes('deprecatedBlocked')) {
                this.level = 'blocked';
            }
            this.level = this.levelsFromValue(this.level);
        }

        // Commented because watchers don't trigger for entities.
        //if (freeze)
        //    Object.preventExtensions(this);
    }
    hasLevel() {
        let checkLevel = this.levelsFromArray([...arguments]);
        var statuses = getStatus(checkLevel);
        if (statuses && statuses.length) {
            checkLevel = checkLevel.filter(c => !statuses.includes(c));
            if (!(checkLevel && checkLevel.length)) {
                return this.status?.split(",").find(s => statuses.includes(s)) != undefined;
            }
            return checkLevel.find(c => this.hasSingleLevel(c, statuses)) != undefined;
        } else {
            return checkLevel.find(c => this.hasSingleLevel(c)) != undefined;
        }
    }
    hasStatus(status) {
        return this.status.includes(status);
    }
    hasSingleLevel(str, statuses) {
        if (this.level != null && this.level.length > 0) {
            if (statuses == null || statuses.length == 0)
                return this.level.find(r => r.trim().toLowerCase() == str.trim().toLowerCase()) != undefined;
            else {
                var haslevel = this.level.find(r => r.trim().toLowerCase() == str.trim().toLowerCase()) != undefined;
                var hasStatus = this.status.split(",").find(s => statuses.includes(s)) != undefined;
                return (haslevel && hasStatus);
            }
        }
    }
    levelsFromArray(checkLevel) {
        let allLevels = [];
        checkLevel.forEach(l => {
            this.levelsFromValue(l).forEach(k => {
                allLevels.push(k);
            });
        });
        return allLevels;
    }
    levelsFromValue(stringOrArray) {
        let levelsCheck = [];
        if (stringOrArray instanceof String || typeof stringOrArray == 'string') {
            if (stringOrArray.includes(",") || stringOrArray.includes("|"))
                levelsCheck = stringOrArray.split(/,|\|/).map(l => { return l.trim(); });
            else
                levelsCheck = [stringOrArray];
        } else levelsCheck = stringOrArray;
        let levels = levelsCheck.filter((l) => { return isLevel(l); });
        if (levels.length != levelsCheck.length) {
            throw new Error("String contains non-level values");
        }
        if (levels.length == 0)
            throw new Error("The string you're trying to compare does not include role levels");
        return levelsCheck;
    }
    //Function to check if a string contains a certain role level
    //All compare roles have to be part of the role string for this function to return true.
    //Input variables can be arrays, or comma or pipe seperated string.
    //When the string isn't a rolelevel at all this should throw an error
    //If the string is empty it could still be a role so it should not return an error
    //If the string is empty and the compare string is also empty it should return true
    hasAllLevels() {
        let checkLevel = this.levelsFromArray([...arguments]);
        return checkLevel.every(c => this.hasSingleLevel(c));
    }
}
export class RoleArray extends Array {
    constructor(source) {
        super();
        if (source && source.length) {
            this.load(source);
        }
    }
    load(source) {
        var roles = [];
        if (source && source.length) {
            for (var i = 0; i < source.length; i++) {
                if (source[i] instanceof Role)
                    roles.push(source[i]);
                else {
                    let r = new Role();
                    r.load(source[i]);
                    roles.push(r);
                }
            }
        }
        roles.forEach(r => {
            super.push(r);
        });
    }
    push(role) {
        if (role instanceof Role)
            super.push(role);
        else {
            let r = new Role();
            r.load(role);
            super.push(r);
        }

    }
    ///Checks if any of the roles within this role array has any of the arguments passed as a level
    hasLevel() {
        return this.find(r => r.hasLevel([...arguments])) != undefined;
    }
    hasStatus(status) {

        return this.find(r => r.hasStatus(status)) != undefined;
    }
    //Checks if any of the roles within the role array has the exact matching levels as passed.
    //So all strings passed to ALL be in at least one of the roles level.
    hasMatch() {
        return this.find(r => r.hasAllLevels([...arguments])) != undefined;
    }
}