<template>
    <nav>
        <ul class="dot-pagination dot-pagination-primary">
            <li class="page-item previous">
                <button :disabled="currentPage == 0" @click="previous" class="btn" :class="previousButtonClass">
                    {{ previousText }}</button>
            </li>
            <li class="spacer"></li>
            <li v-for="(num, index) in dots" :key="index" @click="dotClick(index)"
                :class="currentDot == index ? 'active' : ''" class="page-item">
                <div class="page-link"></div>
            </li>
            <li class="spacer"></li>
            <li class="page-item next">
                <button class="btn" :class="nextButtonClass" @click="next" :disabled="currentPage == (pages - 1)">
                    {{ nextText }}</button>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
	name: "dot-pagination",
	emits: ['next', 'previous', 'navigate', 'update:current'],
	props: {
		pages: {
			required: true,
			type: Number,
			default: 1
		},
		dots: {
			type: Number,
			required: false,
			default: (props) => props.pages,
		},
		current: {
			type: Number,
			default: 0
		},
		nextText: {
			type: String,
			default: 'Next'
		},
		previousText: {
			type: String,
			default: 'Previous'
		},
		previousButtonClass: {
			type: String,
			default: 'btn-underline-primary'
		},
		nextButtonClass: {
			type: String,
			default: 'btn-primary'
		},

	},
	data() {
		return {
			currentPage: this.current
		};
	},
	watch: {
		currentPage() {
			this.$emit('update:current', this.currentPage);
		}
	},
	computed: {
		currentDot() {
			let current = Math.ceil((this.dots / this.pages) * (this.currentPage));
			if (current + 1 >= this.dots && this.currentPage < this.pages - 1 && this.dots > 2) {
				return this.dots - 2;
			}
			return Math.min(current, (this.dots - 1));
		}
	},
	methods: {
		dotClick(n) {

			if (this.dots == this.pages)
				this.currentPage = n;
			else {
				if (n == 0)
					this.currentPage = 0;
				else if (n == this.dots - 1)
					this.currentPage = this.pages - 1;
				else {
					let perc = (n / (this.dots - 1));
					this.currentPage = Math.floor(this.pages * perc);
				}
			}

		},
		next(e) {
			this.$emit('next', e);
			this.currentPage = (this.currentPage + 1) < this.pages ? this.currentPage + 1 : 0;
			this.$emit('navigate', this.currentPage, e);
		},
		previous(e) {
			this.$emit('previous', e);
			this.currentPage = (this.currentPage - 1) >= 0 ? this.currentPage - 1 : (this.pages - 1);
			this.$emit('navigate', this.currentPage, e);
		},
	}
};
</script>

