import { BaseModel } from "@/core/scoped/entities/BaseModel";
import { EditJourneyTask } from "./EditJourneyTask";
export class EditJourneyStep extends BaseModel {
    static get JSONLDType() { return "EditJourneyStep"; }
    constructor() {
        super();
    }
    load(source) {
        super.load(source, false);

        super.makeTypeGetSet('journeyTasks', Array.of(EditJourneyTask));
    }
}