import { BaseModel } from "@/core/scoped/entities/BaseModel";
import { PopupGenerator } from '@/core/global/UI/Popups';
import router from "@/core/global/router";
import NotificationApi from "@/core/scoped/services/api/notification";
import PostApi from "@/core/scoped/services/api/posts";
import CommentApi from "@/core/scoped/services/api/comment";

export class Notification extends BaseModel {
    static get JSONLDType() { return "Notification"; }
    static notificationHandler;

    #navigating = false;

    constructor() {
        super();
        this.openedAt = undefined;

        if (!this.notificationHandler) {
            this.notificationHandler = new NotificationHandler(new NotificationApi(), new CommentApi(), new PostApi());
        }
    }

    readAndNavigate() {
        return this.notificationHandler.readAndNavigate(this);
    }

    read() {
        this.openedAt = new Date();
        return this.notificationHandler.read(this);
    }

    isNavigating() {
        return this.#navigating;
    }

    setNavigating(navigating) {
        this.#navigating = navigating;
    }
}

class NotificationHandler {

    constructor(notificationApi, commentApi, postApi) {
        this.commentApi = commentApi;
        this.postApi = postApi;
        this.notificationApi = notificationApi;
    }
    read(notification) {
        return new Promise(function (resolve, reject) {
            if (notification.openedAt == null) {
                this.notificationApi.readNotification(notification.id).then(() => {
                    resolve();
                });
            } else {
                resolve();
            }
        });
    }
    readAndNavigate(notification) {
        var self = this;
        return new Promise(function (resolve, reject) {
            notification.setNavigating(true);
            return self.read(notification).finally(() => {
                navigate(notification, resolve);
            });
        });

        function navigate(notification, resolve) {
            if (notification.targetEntityType === "comment") {
                self.commentApi
                    .getTarget(notification.targetEntityId)
                    .then((target) => {
                        var url = "/" + target.mainEntityType + "/" + target.mainEntityId; //this is coincidentally correct as long as we keep naming pages this way.
                        let query = {};
                        if (target.subEntityId) {
                            query[target.subEntityType] = target.subEntityId;
                        }
                        router.push({ path: url, query: query });
                    })
                    .catch(() => {
                        notification.setNavigating(false);
                    })
                    .finally(() => {
                        notification.setNavigating(false);
                        resolve();
                    });
            } else if (notification.targetEntityType === "post") {
                self.postApi
                    .getMeta(notification.targetEntityId)
                    .then((meta) => {

                        var url = "/" + meta.targetType + "/" + meta.parentId;
                        router.push({ path: url, query: { post: meta.id } });
                    })
                    .catch(() => {
                        if (!this.popup)
                            this.popup = new PopupGenerator();
                        this.popup.btAlert("This post no longer exists", "danger", 3000);
                    })
                    .finally(() => {
                        notification.setNavigating(false);
                        resolve();
                    });
            } else if (notification.targetEntityType === "activity") {
                if (notification.metaDataSubject?.toLowerCase()?.trim() === ("SignAgreementRequiredMemberReminder").toLowerCase()) {
                    router.push({ path: "/activity/" + notification.targetEntityId + "/agreement" });
                } else {
                    router.push({ path: "/activity/" + notification.targetEntityId });
                }
                resolve();
            } else if (notification.type === "reminder" && notification.metaDataSubject.toLowerCase() === "onboarding") {
                router.push({ name: "onboarding", query: { flow: "joinActivity" } });
                notification.setNavigating(false);
                resolve();
            }
            else {
                resolve();
            }
        }
    }
}