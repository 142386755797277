import Insights from './Insights';
import state from "@/core/global/state/state";
export default class HotjarInsights extends Insights {
    constructor(app, state) {
        super(app, state);
        if (!process.env.VUE_APP_ENV_USE_HOTJAR)
            return;

        //pre init even if not cookie consent
        window.hj = window.hj || function () { (window.hj.q = window.hj.q || []).push(arguments) };
        const hotjarFunc = (function (h, o, t, j, a, r) {
            h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
            h._hjSettings = { hjid: 3818997, hjsv: 6 };
            a = o.getElementsByTagName('head')[0];
            r = o.createElement('script'); r.async = 1;
            r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
            a.appendChild(r);
        });
        window.addEventListener("cookiesAcceptanceChanged", (e) => {
            if (e.detail?.cookiesAllowed && !window.hj)
                hotjarFunc(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
        });
        let pc = process.env.VUE_APP_ENV_USE_COOKIE_WALL === true || process.env.VUE_APP_ENV_USE_COOKIE_WALL === 'true' || process.env.VUE_APP_ENV_USE_COOKIE_WALL === '1';
        if (!pc)
            hotjarFunc(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
        if (app.config.globalProperties.$getCookie) {
            let c = app.config.globalProperties.$getCookie('ac');
            if (c == true || c == 'true' || c == 1 || c == '1') {
                hotjarFunc(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
            }
        }
    }
    startTrackPage(route) {

    }
    stopTrackPage(route, envelope) {
    }

    getProperties(envelope) {

    }


    trackEvent(name, envelope) {
        try {
            window.hj('event', name); //only possibility
        } catch (e) { }
    }
}
