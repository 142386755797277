
import GoogleInsights from './GoogleInsights';
import HotjarInsights from './HotjarInsights';
import FruitPunchInsights from "./FruitPunchInsights";
import InsightsEnvelope from "./InsightsEnvelope";
import state from "@/core/global/state/state";
import ProfileApi from "@/core/scoped/services/api/Community/profile";
import { BaseModel } from "@/core/scoped/entities/BaseModel";
import { nextTick } from 'vue';
export default {
	install: (app, options) => {
		app.config.globalProperties.$insights = new InsightsHub(app);
		window.globalInsights = app.config.globalProperties.$insights;
	}
};
//We don't have to disable FruitPunchInsights when analytics cookies are disabled because all info goes to our own server on our own domain.
class InsightsHub {

	#googleInsights;
	#fruitPunchInsights;
	#hotjarInsights
	#insights;
	#app;
	#router;
	#profileApi;

	#baseEvents = { 'trackCookiesAccepted': 'change_cookie_accepted' };

	#notificationProxy;
	#notificationEvents = { 'trackMailUnsubscribe': 'notification_mail_unsub' }
	#activityProxy;
	#activityEvents = { 'trackFollow': 'activity_follow', 'trackApply': 'activity_apply' }
	#careerProxy;
	#careerEvents = { 'trackMatchCheck': 'career_match_check' }; //With this you can automagically create functions to track events.
	#matchingProxy; //TODO: maybe split matching and career
	#matchingEvents = { 'trackTalentView': 'talent_view', 'trackProfileMatch': 'talent_match_request', 'trackTalentSearch': 'talent_search' };
	#appProxy;
	#appEvents = {
		"trackOnboardingComplete": "onboarding_complete", "trackOnboardingStart": "onboarding_start", "trackSkillOnboardingComplete": "skill_onboarding_complete", "trackSkillOnboardingStart": "skill_onboarding_start",
		"trackLogin": "account_login", "trackSignup": "account_signup", "trackMembershipSelectCanceled": "membership_select_canceled"
	}
	#productProxy;
	#productEvents = { "trackCheckoutStarted": "begin_checkout", "trackCheckoutCompleted": "purchase", "trackCheckoutFailed": "checkout_failed", "trackAddToCart": "add_to_cart", "trackProductView": "view_item" }
	get career() {
		return this.#careerProxy;
	}
	get matching() {
		return this.#matchingProxy;
	}
	get notification() {
		return this.#notificationProxy;
	}
	get activity() {
		return this.#activityProxy;
	}
	get app() {
		return this.#appProxy;
	}
	get product() {
		return this.#productProxy;
	}
	constructor(app) {
		if (!state.currentProfile && state.user?.profileId) { //state.currentProfile is not a Profile model, as in, it's not extending BaseModel. If that's needed we heed to add profile to globalProperties.
			this.#profileApi = new ProfileApi();
			this.#profileApi.getProfile(); //no need to wait. Just want to make sure that you always set the profile, even on first page visit.
		}

		let nouse = process.env.VUE_APP_ENV_CLARITY_IGNORE;
		nouse = nouse?.split(",");
		if (state.user?.profileId) {
			if (!(nouse && nouse.length && nouse.find(u => u.trim() == state.user.profileId))) {
				this.#googleInsights = new GoogleInsights(app, state);
				this.#fruitPunchInsights = new FruitPunchInsights(app, state);
				this.#hotjarInsights = new HotjarInsights(app, state);
				this.#insights = [this.#googleInsights, this.#fruitPunchInsights, this.#hotjarInsights];
			} else {
				this.#insights = []; //empty for our own accounts
			}
		} else {
			this.#googleInsights = new GoogleInsights(app, state);
			this.#fruitPunchInsights = new FruitPunchInsights(app, state);
			this.#hotjarInsights = new HotjarInsights(app, state);
			this.#insights = [this.#googleInsights, this.#fruitPunchInsights, this.#hotjarInsights];
		}

		this.#app = app;
		this.#router = this.#app.config.globalProperties.$router;
		this.#router.afterEach((to, from) => {
			nextTick(async () => {
				await this.trackPage(to);
			});
		});
		//We make special objects that have generic methods based of the event names we give them.
		this.#careerProxy = this.#buildProxy(this.#careerEvents);
		this.#matchingProxy = this.#buildProxy(this.#matchingEvents);
		this.#notificationProxy = this.#buildProxy(this.#notificationEvents);
		this.#activityProxy = this.#buildProxy(this.#activityEvents);
		this.#appProxy = this.#buildProxy(this.#appEvents);
		this.#productProxy = this.#buildProxy(this.#productEvents);
		this.#buildProxy(this.#baseEvents, this);
	}

	#buildProxy(events, baseObj) {
		let obj = baseObj ?? {}; //change this to a real class instance if you want to make custom events
		obj.parent = this;
		for (let n in events) {
			let uppert = n.charAt(0).toUpperCase() + n.slice(1);
			let start = "start" + uppert;
			let end = "end" + uppert;
			obj[n] = function () {
				try {
					let envelope = this.parent.#buildEnvelope([...arguments]);
					this.parent.#insights.forEach((i) => {
						if (i.trackEvent) {
							i.trackEvent(events[n], envelope);
						} else if (i.startTrackEvent && i.stopTrackEvent) {
							i.startTrackEvent(events[n], envelope);
							i.stopTrackEvent(events[n], envelope);
						}
					});
				} catch (exc) {
					console.error(exc);
				}
			};
			obj[start] = function () {
				let envelope = this.parent.#buildEnvelope([...arguments]);
				this.parent.#insights.forEach((i) => {
					if (i.startTrackEvent) {
						i.startTrackEvent(events[n], envelope);
					}
				});
			};
			obj[end] = function () {
				let envelope = this.parent.#buildEnvelope([...arguments]);
				this.parent.#insights.forEach((i) => {
					if (i.stopTrackEvent) {
						i.stopTrackEvent(events[n], envelope);
					}
				});
			};
		}
		let prox = new Proxy(obj, {
			get(target, name, receiver) {
				if (target[name])
					return target[name];
				else { //never under any circumstances can insights calling kill the app.
					console.error("unable to track " + name);
					return () => { };
				}
			},
			set(target, name, value, receiver) {
				target[name] = value;
			}
		}
		);
		return prox;
	}


	async trackPage(route) {
		if (route == null)
			route = this.#app.config.globalProperties.$route;
		this.#runOnAll('startTrackPage', [route]);
		let envelope = await this.getEnvelope();
		this.#runOnAll('stopTrackPage', [route, envelope]);
	}
	async getEnvelope() {
		let envelope = new InsightsEnvelope();
		await this.#getRouteProperties(envelope);
		return envelope;
	}

	#buildEnvelope(args) { //This method is used, VS code just doens't understand that.
		if (args && args.length && args[0] instanceof InsightsEnvelope)
			return args[0];
		else {
			if (args && args.length) {
				let bases = args.filter(a => a instanceof BaseModel);
				let obj = args.filter(a => !(a instanceof BaseModel));
				let env = new InsightsEnvelope(bases);
				obj.forEach(a => {
					if (a instanceof Boolean || typeof (a) == "boolean")
						env.success = a;
					else if (a instanceof String || typeof (a) == "string")
						env.error = a;
					else throw new Error("You can only use BaseModels or Boolean or String as input. type is " + typeof (a), a);
				});
				return env;
			}
			return new InsightsEnvelope(args);
		}
	}

	#runOnAll(method, args) {
		this.#insights.forEach((i) => {
			if (i[method]) {
				i[method](...args);
			}
		});
	}

	//this function finds the fillAnalyticsEnvelope on the components in a route.
	//this function should be part of the main structure of the page to be used.
	//the function has to accept one InsightsEnvelope parameter and can freely modify it
	// see activityFullPage page for a neat example
	async #getRouteProperties(envelope) {
		let envelopeFillMethod = 'fillAnalyticsEnvelope';
		let components = await this.#getComponentsWithMethod(envelopeFillMethod);
		let tasks = [];
		for (var c of components) {
			let method = c.component[envelopeFillMethod];
			tasks.push(method.call(c.instance, envelope));
		}
		try {
			await Promise.all(tasks);
		} catch (ex) {
			if (ex)
				console.error(ex); //It's possible that some promisses simply don't complete. We log the error but don't care (yet)
		}
	}
	#getComponentsWithMethod(methodName) {
		let route = this.#app.config.globalProperties.$route;
		let matchingComponents = [];
		if (route && route.matched) {
			route.matched.forEach(match => { //matched is the page, and the layout
				if (match.instances) { //components usually only contains
					for (var n in match.components) {
						if (match.components[n][methodName] && typeof (match.components[n][methodName]) == 'function') {
							if (match.instances[n])
								matchingComponents.push(
									{
										component: match.components[n],
										instance: match.instances[n]
									}
								);
						}
					}
				}
			});
		}
		return matchingComponents;
	}


}