import { BaseModel } from "@/core/scoped/entities/BaseModel";
import { RoleArray } from "@/core/scoped/entities/Root/Role";
import { JourneyTask } from "@/core/scoped/entities/Root/Community/Journey/JourneyTask";
import JourneyApi from "@/core/scoped/services/api/Community/journey";
import CommunityActivityApi from "@/core/scoped/services/api/Community/activity";
import { CommunityActivity } from "@/core/scoped/entities/Root/Community/Activity";
export class StepTaskProgress extends BaseModel {
    static get JSONLDType() { return "StepTaskProgress"; }
    static journeyApi;
    static activityApi;
    #title;
    #description;
    #duedate;
    #hasduedateset = false;
    #isSaving = false;
    #action;
    constructor() {
        super();
        this.makeTypeGetSet('activity', CommunityActivity);
        this.makeTypeGetSet('relevantRoles', RoleArray);
        this.makeTypeGetSet('task', JourneyTask);
    }

    get dueDate() {
        if (!this.#hasduedateset) {
            if (this.activity?.start) {
                this.#duedate = this.activity.start;
            }
            this.#hasduedateset = true;
        }
        return this.#duedate;
    }
    set dueDate(v) {
        this.#hasduedateset = true;
        this.#duedate = v;
    }
    get manuallyCompletable() {
        if (this.activity != null)
            return this.activity.manuallyCompletable;
        if (this.task)
            return this.task.allowCompleteOverride;
        else return false;
    }
    get isComplete() {
        return this.percentDone >= 100;
    }

    get isSaving() {
        return this.#isSaving;
    }

    set isSaving(v) {
        this.#isSaving = v;
    }
    get icon() {
        var taskIdentifier = this.task.taskIdentifier;

        if (taskIdentifier == 'start_bootcamp') return null;
        if (taskIdentifier == 'start_microchallenge') return null;
        if (taskIdentifier == 'start_challenge_based_learning_lesson') return { type: 'emoji', value: '📑' };
        //TODO: Replace this with own hosted image

        if (taskIdentifier == 'onboarding_questionaire') return { type: "icon", value: "user"};
        if (taskIdentifier == 'join_slack') return { type: "icon", value: "slack" };
        if (taskIdentifier == 'start_event') return { type: 'emoji', value: '🌍' };
        if (taskIdentifier == 'start_challenge' || taskIdentifier == 'browse_challenges') return { type: 'emoji', value: '🧭' };
    }

    get thumbnail() {
        var taskIdentifier = this.task.taskIdentifier;
        if (taskIdentifier == 'basic_membership')
            return { url: require('@/assets/img/artwork/questionair.webp'), backclass: 'gradient-hero' }
        if (taskIdentifier == 'start_challenge') return { url: require('@/assets/img/artwork/learn.webp'), backclass: 'gradient-challenge' };
        if (taskIdentifier == 'premium_membership') return {
            url: require('@/assets/img/artwork/micro.webp'), backclass: 'gradient-green'
        };
    }

    // Specify a normal router object if you want to navigate internally. Otherwise, use the format:
    // {
    //  external: true,
    //  href: <url>
    // }
    get action() {
        if (this.activity) {
            return {
                name: 'activityFullPage',
                params: {
                    type: this.activity.type,
                    id: this.activity.customRouterId ?? this.activity.id
                }
            };
        } else {
            var taskIdentifier = this.task.taskIdentifier;
            if (taskIdentifier == 'join_slack') {
                return {
                    external: true,
                    href: 'https://join.slack.com/t/fruitpunchai/shared_invite/zt-1fm6014md-O2xdrh5i3DLSETHhHKpsRw'
                };
            }
            if (taskIdentifier == 'start_challenge_based_learning_lesson') {
                return {
                    path: '/lesson/challenge-based-learning'
                };
            }
            if (taskIdentifier == "start_bootcamp") {
                return { path: '/education' };
            }
            if (taskIdentifier == "start_event") {
                return { path: '/events' };
            }
            if (taskIdentifier == "start_microchallenge") {
                return { path: '/education' };
            }
            if (taskIdentifier == 'start_challenge' || taskIdentifier == 'browse_challenges') {
                return { path: '/challenges' };
            }
            if (taskIdentifier == 'basic_membership' || taskIdentifier == 'premium_membership') {
                return { path: '/journey/membership/select'}
            }
            if (taskIdentifier == 'onboarding_questionaire') { return { name: 'onboarding', query: { flow: 'joinActivity' } }; }
            if (taskIdentifier == 'skill_onboarding_questionaire') { return { name: 'skillonboarding' }; }
        }

        //TODO: Implement more configurations here for custom actions.
        return null;
    }

    get title() {
        if (this.#title != null)
            return this.#title;
        if (this.activity != null)
            this.#title = this.activity.title;
        if (this.task != null)
            this.#title = this.task.title;
        return this.#title;
    }
    set title(v) {
        this.#title = v;
    }
    get description() {
        if (this.#description != null)
            return this.#description;
        if (this.activity != null)
            this.#description = this.activity.subtitle;
        if (this.task != null)
            this.#description = this.task.description;
        return this.#description;
    }
    set description(v) {
        this.#description = v;
    }

    async finish(doReload = true) {
        if (this.task != null) {
            if (StepTaskProgress.journeyApi == null)
                StepTaskProgress.journeyApi = new JourneyApi();
            this.#isSaving = true;
            var result = await StepTaskProgress.journeyApi.manuallyCompleteTask(this.mainActivityId, this.stepId, this.task.taskIdentifier);
            if (result && result != 'false') {
                this.percentDone = 100;
                if (this.parent && doReload) {
                    var percent = this.parent.resetPercent();
                    if (percent >= 100) {
                        var newThing = await StepTaskProgress.journeyApi.getActiveJourney(this.mainActivityId, true);
                        if (newThing) {
                            this.parent.profileJourney.reload(newThing);
                        }
                    }
                }
            }
            this.#isSaving = false;
            return result;
        } else if (this.activity != null) {
            if (StepTaskProgress.activityApi == null)
                StepTaskProgress.activityApi = new CommunityActivityApi();
            this.#isSaving = true;
            var result = await StepTaskProgress.activityApi.complete(this.activity.id, false);

            this.percentDone = 100;
            if (this.parent) {
                this.parent.resetPercent();
            }
            this.#isSaving = false;
            return result;
        }
    }

}