
import { PopupGenerator } from "@/core/global/UI/Popups";
export default function AppUtilities(app, options) {

    app.config.globalProperties.$mailTo = function (to, sub) {
        let url = "mailto:" + to + "?subject=" + sub;
        var win = window.open(url, "__blank");
        setTimeout(() => {
            try {
                let result = win.location.href;
                if (result !== undefined || !win.closed) {
                    win.close();
                    let p = new PopupGenerator();
                    p.btAlert("You do not have a default mail client set<br/>Please email us at " + to + "<br/> Using \"" + sub + "\" as subject line to contact us", "warning", 12000);
                }
            } catch (e) {
                //opening client worked in browser, not allowed to access window.href
            }
        }, 500);
    };

    app.config.globalProperties.$apiUrl = window.apiSettings.URL;

    //This "new" forceUpdate function allows you to force updates of things that slide or fade into view.
    //It's mainly usefull when using the tabs component because some elements in there don't fully render when showing them.
    //The result may be that if you v-bind some styling of an element to some properties of another element, they don't properly render
    app.config.globalProperties.forceUpdate = function (count, interval) {

        this.$forceUpdate();
        if (count) {
            if (!interval) interval = 50;
            var timeout = interval;
            for (let i = 0; i < count; i++) {
                setTimeout(() => {
                    this.$forceUpdate();
                }, timeout);
                timeout += interval;
            }
        }
    };

    app.config.globalProperties.$setCookie = function (cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    };
    app.config.globalProperties.$getCookie = function (cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return null;
    };



    app.config.globalProperties.$inheritMethods = function (target) {
        //Vue says you should not itterate over a components keys because they're empty.
        //This is only partially true, the methods of a component will still be part of it's keys.
        //So it's kinda bull that Vue gives a warning
        //if not bull use target.$options.methods
        let oldError = window.console.warn; //Disable warning, we don't care
        window.console.warn = () => { };
        for (let n in target) {
            if (n.indexOf("$") !== 0 && n.indexOf("_") !== 0) {
                if (target[n] instanceof Function || typeof (target[n]) === 'function') {
                    if (this[n] === undefined)
                        this[n] = function () {
                            target[n]();
                        };
                }
            }
        }
        window.console.warn = oldError;//reenable
    };
    return app;
}