import "@/styles/main.scss";

import * as needToImport from "@/core/global/window-helpers.js";
import App from "./App.vue";
import AppUtilities from "@/core/global/AppUtilities";
import Insights from '@/core/global/insights/InsightsHub';
import Clarity from '@/core/global/insights/ClarityInsights';
import Popups from '@/core/global/UI/Popups';
import { RegisterServiceWorker } from "./registerServiceWorker";
import State from '@/core/global/State';
import state from "@/services/state/state";
import Translations from "@/core/global/UI/Translations";
import { createApp } from "vue";
import router from "@/core/global/router";
import seoLink from '@/components/misc/seo-link.vue';
import SDGProvider from "@/core/global/SDGProvider";
import Fruitionary from "@/components/fruitionary";
import mediaState from "@/core/global/UI/mediaState";

//The main.js file should basically be a mixin point. All stuff we extend on vue should be done here.
let finalCall = () => {
    const app = createApp(App);
    app.mixin(Popups);

    app.mixin(State);
    app.mixin(SDGProvider);
    app.config.globalProperties.window = window;
    app.component('seo-link', seoLink);
    app.use(AppUtilities).use(Translations).use(router);
    app.use(Insights);
    app.use(Clarity);
    app.use(Fruitionary);
    app.use(mediaState);
    window.globalApp = app;
    window.appContext = (app._instance?.appContext ?? app._context);

    app.mount("#app");
};
if (("serviceWorker" in navigator)) {

    var register = RegisterServiceWorker();

    navigator.serviceWorker.getRegistration().then(function (reg) {

        // There's an active SW, but no controller for this tab.
        if (reg && reg.active && !navigator.serviceWorker.controller) {

            // Perform a soft reload to load everything from the SW and get
            // a consistent set of resources.
            // we use the session storage to prevent infinite reloads.
            var reloaded = sessionStorage.getItem("sw");
            if (!reloaded) {
                window.location.reload();
                sessionStorage.setItem("sw", "1");
            }
            else
                sessionStorage.removeItem("sw");
        }
    });
    register.then((reg) => {
        reg.pushManager.getSubscription().then(subscription => {
            //Function for formatting the auth credentials for webpush notifications in base64.
            function arrayBufferToBase64(buffer) {
                let binary = '';
                const bytes = new Uint8Array(buffer);
                for (let i = 0; i < bytes.byteLength; i++) {
                    binary += String.fromCharCode(bytes[i]);
                }
                return btoa(binary);
            }

            if (subscription) {
                var subscriptionObject = {
                    endpoint: subscription.endpoint,
                    p256dh: arrayBufferToBase64(subscription.getKey('p256dh')),
                    auth: arrayBufferToBase64(subscription.getKey('auth'))
                };
                state.registerStored("webPushRegistration", subscriptionObject);
            }
        });
        //Do something with sw
    }).catch(() => {
        //do nothing with sw
    }).finally(() => {
        finalCall();
    });
} else {
    finalCall();
}