<template>
  <router-link ref="link" v-bind="$props" :rel="nfString">
    <slot></slot>
  </router-link>
</template>
<script>
export default {
	name: "seo-link",
	data() {
		return {
			nofollow: true,
			nfString: "nofollow",
		};
	},
	watch: {
		nofollow(val) {
			this.nfString = val ? "nofollow" : null;
		},
	},
	mounted() {
		let r = this.$router.resolve(this.$refs.link.to);
		if (r && r.meta) {
			if (r.meta.requiresAuth === false || r.meta.requiresAuth === "false") {
				this.nofollow = false;
			}
		}
	},
};
</script>