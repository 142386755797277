import CommunityActivityApi from "@/core/scoped/services/api/Community/activity";
import apiClient from "../api";

export default class MembershipActivityApi extends CommunityActivityApi {

	constructor() {
		super();
		this.membershipSubUrl = "membership/activity/";
	}

	async joinViaMembership(id, joinActivityDTO) {
		apiClient.clearCache(this.membershipSubUrl + id);
		return await apiClient.standardPost(this.membershipSubUrl + id + "/join", joinActivityDTO);
	}
}