import apiClient from "./api";
import baseApi from "./baseApi";

export default class PostApi extends baseApi {

	constructor() {
		super();
		this.subURL = "/post";
	}

	async getMeta(id) {
		return apiClient.standardGet('/post/' + id);
	}
}
