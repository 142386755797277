import { BaseModel } from "@/core/scoped/entities/BaseModel";
import { JourneyActivity } from "@/core/scoped/entities/Root/Community/Journey/JourneyActivity";
import { ProfileJourneyStatus } from "@/core/scoped/entities/Root/Community/Journey/ProfileJourneyStatus";
import { ProfileJourneyStep } from "@/core/scoped/entities/Root/Community/Journey/ProfileJourneyStep";
export class ProfileJourney extends BaseModel { //Community because this will get your own profile only, so you require to be logged in
    static get JSONLDType() { return "ProfileJourney"; }
    constructor() {
        super();
        this.makeTypeGetSet('journeyActivity', JourneyActivity);
        this.makeTypeGetSet('journeyStatus', ProfileJourneyStatus);
        this.makeTypeGetSet('journeySteps', Array.of(ProfileJourneyStep));
    }

    #percentDone;

    load(source, freeze = true) {
        super.load(source, freeze);
        var nextSteps = this.journeyActivity?.journey?.firstStep?.getDescendantSteps();
        if (nextSteps) {
            nextSteps.unshift(this.journeyActivity.journey.firstStep);
            this.journeySteps.forEach(js => {
                js.journeyStep = nextSteps.find(k => js.journeyStepId == k.id);
                js.mainActivityId = this.journeyActivity.id;
                js.profileJourney = this;
            });
        }
    }
    reload(newObject) {
        this.emit('reloaded', newObject);
    }

    get percentDone() {

        if (this.#percentDone != null)
            return this.#percentDone;

        const sum = this.journeySteps
            .map(obj => obj.percentDone || 0) // Map to an array of percentDone values or 0 if undefined
            .reduce((acc, val) => acc + val, 0); // Calculate the sum

        const count = this.journeySteps.length; // Get the count of objects
        const roundedAverage = Math.round(sum / count); // Round the average

        this.#percentDone = roundedAverage;
        return this.#percentDone;
    }
}