import apiClient from "./api";
import axios from "axios";
import baseApi from "./baseApi";
import state from "@/core/global/state/state";
import { RoleArray } from "@/core/scoped/entities/Root/Role";

export default class AccountApi extends baseApi {

	constructor() {
		super();
		this.subURL = "/account/";
	}

	async getAllRoleLevels() {
		return apiClient.standardGet(this.subURL + "roles/levels");
	}

	async signin(username, password) {
		let signinResult = await apiClient.post(this.subURL + "signin", {
			username: username,
			password: password,
		});
		state.registerSession("refreshedThisSession", '1');
		return signinResult;
	}

	async signinWithActivationKey(activationKey, acceptTerms = null, newsletter = null) {
		let data = { activationKey: activationKey };
		if (acceptTerms !== null)
			data.acceptTerms = acceptTerms;
		if (newsletter !== null)
			data.newsletter = newsletter;
		return await apiClient.standardPost(this.subURL + "signinWithActivationKey", data);
	}

	async register(email, password, acceptTerms, newsletter, profileData) {
		return await apiClient.standardPost(this.subURL + "signup", {
			username: email,
			password: password,
			profileData: profileData,
			acceptTerms: acceptTerms,
			newsletter: newsletter
		});
	}

	async sendPasswordReset(email) {
		return await apiClient.standardGet(this.subURL + "sendpasswordreset?email=" + email);
	}

	async sendActivateAccount(email) {
		return await apiClient.standardGet(this.subURL + "resendactivation?email=" + email);
	}

	async resetPassword(password, verificationCode) {
		return await apiClient.standardPost(this.subURL + "resetpassword/" + verificationCode, {
			password: password
		});
	}

	async checkAdminAccess() {
		const accesses = await this.checkAccess([
			{
				type: "system",
				level: "superAdmin,admin"
			},
			{
				type: "organization",
				level: "superAdmin,admin,owner"
			}
		]);
		if (accesses.roles.find(r => r.type == "system"))
			state.user.isSystemAdmin = true;
		else state.user.isSystemAdmin = false;
		if (accesses.roles.find(r => r.type == "organization"))
			state.user.hasOrganization = true;
		else state.user.hasOrganization = false;
	}

	async checkAccess(roles) {
		//By sorting the objects and using the hashes of the resulting object we can know that we've checked the exact same set of roles already.
		//This way we don't overcheck the api for roles.
		//It doesn't actually matter that this may give access when you shouldn't. The data a page may show is still protected by the API.
		//The page will just look a bit weird.
		var checked = this.hashRoles(roles);
		if (!state.accessChecked)
			state.registerSession("accessChecked", {});
		if (state.accessChecked[checked]) {
			var k = { ...state.accessChecked[checked] };
			k.roles = new RoleArray(k.roles);
			return k;
		}

		var info = await apiClient.standardPost(this.subURL + "checkaccess?nocache=1", roles);
		if (info && (info.authorized === 'true' || info.authorized === true || info.authorized === 1 || info.authorized === '1'))
			state.accessChecked[checked] = info;
		var k = { ...info };
		k.roles = new RoleArray(k.roles);
		return k;
	}

	signout() {
		state.deleteAll();
		document.location.href = axios.defaults.baseURL + this.subURL + "signout?returnUrl=" + process.env.VUE_APP_ENV_BASE_URL;
	}

	sortObject(unordered) {
		return Object.keys(unordered).sort().reduce(
			(obj, key) => {
				if (unordered[key] instanceof Object)
					obj[key] = this.sortObject(unordered[key]);
				else
					obj[key] = unordered[key];
				return obj;
			},
			{}
		);
	}

	hashRoles(roles) {
		var hash = JSON.stringify(roles).hashCode();
		return hash;
	}
}

String.prototype.hashCode = function () {
	var hash = 0, i, chr;
	if (this.length === 0) return hash;
	for (i = 0; i < this.length; i++) {
		chr = this.charCodeAt(i);
		hash = ((hash << 5) - hash) + chr;
		hash |= 0; // Convert to 32bit integer
	}
	return hash;
};
