<template>
<div class="row">
    <div class="fp-text-logo" style="height: 40px; margin-bottom: 40px"></div>
</div>
<div class="row">
    <div class="col-12 col-md py-0 py-md-2 pb-3">
        <logo-button @click="externalLogin(linkedInUrl)" class="btn-light w-100" logo="linkedin">
            {{ $ct(true, "c_login.loginLinkedIn") }}
        </logo-button>
    </div>
    <div class="col-12 col-md py-0 py-md-2">
        <logo-button @click="externalLogin(googleUrl)" class="btn-light w-100" logo="google">
            {{ $ct(true, "c_login.loginGoogle") }}
        </logo-button>
    </div>
</div>
<div class="row" style="margin-bottom: -12px">
    <div class="col" style="line-height: 75px">
        <div class="strike-line"></div>
        <div class="or-word">{{ $ct("c_base.or") }}</div>
        <div class="strike-line"></div>
    </div>
</div>
<div class="row">
    <div class="col">
        <form ref="loginForm">
            <div class="form-group" style="min-height: 75px">
                <label for="emailInput">
                    {{ $ct("c_login.emailAddress") }}
                </label>
                <input ref="email" v-model="email" type="email" class="form-control" id="emailInput"
                    aria-describedby="emailHelp" />
                <small class="invalid-feedback">
                    {{ $ct(true, "c_login.invalidEmail") }}
                </small>
            </div>
            <div class="form-group mb-1" style="min-height: 100px">
                <label for="passwordInput">
                    {{ $ct("c_login.password") }}
                </label>
                <a class="fp" v-on:click="navigateToPasswordForgot()">{{ $ct(true, "c_login.forgotPassword") }}?</a>
                <input ref="password" v-model="password" type="password" class="form-control" id="passwordInput" />
                <small id="passwordFailState" class="invalid-feedback">
                    {{ $ct(true, "c_login.wrongPassword") }}
                </small>
            </div>
        </form>
    </div>
</div>
<div class="row mb-1" v-if="couldBeSocial">
    <div class="col">
        <small class="text-danger" v-if="!couldBeSocial.length">
            This emailadres is linked to either a LinkedIn or Google account, please log in with either of those.
        </small>
        <small class="text-danger" v-else>
            This emailadres is linked to a {{ couldBeSocial }} account, please log in with that.
        </small>
    </div>
</div>
<div class="row">
    <div class="col">
        <button v-on:click="login" class="btn btn-primary w-100" ref="loginSpinner">
            {{ $ct("c_login.signIn") }}
        </button>
    </div>
</div>
<div class="row">
    <div class="col text-center no-member-bottom">
        {{ $ct(true, "c_login.notAMember") }}?
        <a class="register-text" v-on:click="
            this.$emit('navigated');
        this.$router.push({ name: 'registerForPlatformPage', query: { returnUrl: returnUrl } });
        ">
            {{ $ct(true, "c_login.signUpHere") }}!
        </a>
    </div>
</div>
</template>
<script>
import AccountApi from "@/core/scoped/services/api/account";
import ProfileApi from "@/core/scoped/services/api/Community/profile";
import MembershipApi from "@/core/scoped/services/api/Community/membership";
import { RegisterServiceWorker } from "@/registerServiceWorker";

export default {
    name: "login",
    emits: ["navigated", "loggedin"],
    props: {
        cardMode: {
            type: Boolean,
            default: false,
        },
        overrideReturnUrl: {
            type: String,
            default: null,
            required: false,
        },
    },
    data() {
        return {
            email: "",
            password: "",
            accountApi: null,
            couldBeSocial: false,
            membershipApi: new MembershipApi(),
            returnUrl: process.env.VUE_APP_ENV_BASE_URL,
            // In the onboarding page we check if they have completed the onboarding already, and if so redirect to the feed overview anyways.
            linkedInUrl: window.apiSettings.URL + "/account/signin/LinkedIn?returnUrl=" + encodeURIComponent(process.env.VUE_APP_ENV_BASE_URL),
            googleUrl: window.apiSettings.URL + "/account/signin/Google?returnUrl=" + encodeURIComponent(process.env.VUE_APP_ENV_BASE_URL),
        };
    },
    watch: {
        returnUrl() {
            if (this.returnUrl) {
                this.linkedInUrl = window.apiSettings.URL + "/account/signin/LinkedIn?returnUrl=" + encodeURIComponent(this.returnUrl);
                this.googleUrl = window.apiSettings.URL + "/account/signin/Google?returnUrl=" + encodeURIComponent(this.returnUrl);
                if (window.apiSettings?.isLive && window.apiSettings?.canSwitch) {
                    this.googleUrl = "https://api.fruitpunch.ai/account/signin/Google?returnUrl=" + encodeURIComponent("http://localhost:8080/");
                }
            }
        },
        overrideReturnUrl() {
            if (this.overrideReturnUrl) {
                this.returnUrl = this.overrideReturnUrl;
            }
        },
    },
    mounted() {
        this.accountApi = new AccountApi();
        this.passwordError = this.$ct(true, "c_login.wrongPassword");
        if (this.overrideReturnUrl) {
            this.returnUrl = this.overrideReturnUrl;
        } else if (this.$route.query && this.$route.query.returnUrl) {
            let r = new RegExp('^(?:[a-z+]+:)?//', 'i');
            let decoded = decodeURIComponent(this.$route.query.returnUrl);
            if (r.test(decoded)) //regex check if url is absolute or relative.
                this.returnUrl = decoded;
            else {
                if (decoded == "/")
                    decoded = "";
                while (decoded.startsWith("/"))
                    decoded = decoded.substring(1);
                this.returnUrl = (process.env.VUE_APP_ENV_BASE_URL + "/" + decoded);
            }
        } else {
            this.returnUrl = process.env.VUE_APP_ENV_BASE_URL;
        }
    },
    methods: {
        login() {
            this.couldBeSocial = false;
            this.$refs.loginSpinner.setAttribute('icon', 'spinner-sm');
            this.clearState().finally(() => {
                //Remove all from the current state, if you're not logged in we should start a "session" with a clean state
                this.accountApi.silent
                    .signin(this.email, this.password)
                    .then((session) => {
                        if (session.data && session.data.bearerToken) {
                            this.$state.registerStored("user", session.data);
                            this.accountApi.checkAdminAccess();
                            let profileApi = new ProfileApi();
                            let profilePromise = profileApi.getProfile(true);
                            this.$insights?.app?.trackLogin();
                            this.membershipApi.reloadMemberships();
                            //Regardless whether the service worker could be registered, continue the signin process.
                            RegisterServiceWorker().finally(() => {
                                profilePromise.finally(() => {
                                    this.$emit("loggedin");
                                    if (session.data.hasOrganizationRoles) {
                                        this.$state.registerStored("userHasOrganizations", true);
                                    } else {
                                        this.$state.registerStored("userHasOrganizations", false);
                                    }
                                    if (this.cardMode) {
                                        //no need to do anything yet. we listen to loggedin and then close the card
                                    } else {
                                        window.location.assign(this.returnUrl ?? '/');
                                    }
                                });
                            });
                        }
                    })
                    .catch((error) => {
                        if (error.response.status === 409) {
                            this.$router.push({ name: "activateAccount", query: { email: this.email } });
                            this.$emit("navigated");
                        }
                        else if (error.response.status === 406) {
                            if (error.response.data?.detail) {
                                this.couldBeSocial = error.response.data?.detail;
                            } else
                                this.couldBeSocial = true;
                        }
                        else {
                            this.$refs.password.setCustomValidity("error");
                        }
                    })
                    .finally(() => {
                        this.$refs.loginSpinner.removeAttribute('icon');
                    });
            });
        },
        async unregisterAndClearCaches() {
            const registrations = await navigator.serviceWorker.getRegistrations();

            const allCaches = await caches.keys();
            const cacheDeletionPromises = allCaches.map((cache) => caches.delete(cache));

            const updatePromises = registrations.map((registration) => registration.update());
            await Promise.all([...updatePromises, ...cacheDeletionPromises]);

            // Previously we used to unregister and reregister the complete serviceworker on every login to ensure that a user
            // starts with a completely clean installation. Because we have implemented WebPush now, we need the service worker
            // instance to persist so that it can still send notifications even if the user is logged out and relogged in.
            // We replaced the unregister with an update of the serviceWorker, if it turns out this causes any issues (which we don't) expect
            // based on research, you could enable the 2 lines below, and comment the 2 lines above this comment.
            //const unregisterPromises = registrations.map((registration) => registration.unregister());
            //await Promise.all([...unregisterPromises, ...cacheDeletionPromises]);


        },
        clearState() {
            return new Promise((resolve, reject) => {
                try {
                    this.$state.deleteAll();
                    if ("serviceWorker" in navigator) {
                        this.unregisterAndClearCaches().finally(() => {
                            resolve();
                        });
                    } else {
                        resolve();
                    }
                } catch {
                    resolve();
                }
            });
        },
        externalLogin(url) {
            this.clearState().finally(() => {
                document.location.href = url;
            });
        },
        navigateToPasswordForgot() {
            this.clearState().finally(() => {
                this.$emit("navigated");
                this.$router.push({ name: "passwordForgotPage", query: { email: this.email } });
            });
        },
    },
};
</script>
<style scoped lang="scss">
@import "/src/styles/bootstrap/fp_colors.scss";

.register-text {
    color: $primary;
    text-decoration: none;
    cursor: pointer
}

.col-12.col-md:first-child {
    padding-left: 0;
    padding-right: 9.5px;
}

.col-12.col-md:last-child {
    padding-right: 0;
    padding-left: 9.5px;
}

form {
    text-align: left;
}

form .form-group {
    margin-bottom: 16px;
}

form .form-group label {
    margin-bottom: 8px;
    color: black;
    font-size: 16px;
    line-height: 18px;
}

.fp {
    text-decoration-line: underline;
    color: #4a4a4a;
    font-size: 12px;
    line-height: 14px;
    margin-top: 7px;
    float: right;
}

.fp:hover {
    cursor: pointer;
}

#login {
    height: 100%;
    width: 100%;
}

#login .col {
    padding-left: 0;
    padding-right: 0;
}

.strike-line {
    height: 0.5px;
    background-color: black;
    display: inline-block;
    width: calc(50% - 20px);
    margin-bottom: 5px;
}

.or-word {
    display: inline-block;
    width: 40px;
    font-size: 14px;
    letter-spacing: 0.015em;
    text-align: center;
}

.no-member-bottom {
    color: black;
    line-height: 50px;
    font-size: 16px;
}

@media (max-width: 900px) {
    .col-12.col-md:first-child {
        padding: 1em 0;
    }

    .col-12.col-md:last-child {
        padding: 1em 0;
    }

    .row {
        padding: 0 1em;
    }
}

@media (max-height: 800px) {
    .no-member-bottom {
        line-height: 25px;
    }

    .col-12.col-md:first-child {
        padding: 0.3em 0;
    }

    .col-12.col-md:last-child {
        padding: 0.3em 0;
    }
}
</style>
