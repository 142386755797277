import apiClient from "./api";
import baseApi from "./baseApi";

export default class NotificationApi extends baseApi {
  
	constructor() {
		super();
		this.subURL = "/notification/";
	}

	async getPersonalNotificationById(notificationId) {
		return await apiClient.standardGet(this.subURL + notificationId);
	}

	async getNotificationReceiveRules() {
		return await apiClient.standardGet(this.subURL + "receiverules");
	}

	async setNotificationReceiveRule(changeDTO) {
		return await apiClient.patch(this.subURL + "receiverule", changeDTO);
	}

	async setNotificationReceiveRules(changeArrayDTO) {
		return await apiClient.patch(this.subURL + "receiverules", changeArrayDTO);
	}

	async getPersonalNotifications() {
		return await apiClient.standardGet(this.subURL);
	}

	async readNotification(id) {
		return await apiClient.put(this.subURL + id);
	}

	async readAllNotifications() {
		return await apiClient.put(this.subURL + 'readall');
	}

	async triggerNotificationEmailAggregation() {
		return await apiClient.standardPost(this.subURL + "aggregate/notificationemails");
	}

	async getWhenLastAggregatedNotificationEmails() {
		return await apiClient.standardGet(this.subURL + "aggregate/notificationemails/last");
	}

	async registerWebPushDeviceToProfile(subscription) {
		await apiClient.put(this.subURL + "webpush/register", subscription);
	}

	async unregisterWebPushDeviceFromProfile(endpoint) {
		return await apiClient.put(this.subURL + "webpush/unregister", {
			endpoint: endpoint
		});
	}

	async unregisterWebPushDevicesFromProfile() {
		return await apiClient.put(this.subURL + "webpush/unregister/all");
	}
}
