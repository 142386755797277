<template>
<div @mousedown="overlayMouseDown($event)" @mouseup="overlayMouseUp($event)" ref="overlay" :style="overlayStyle"
    :class="'overlay-modal-overlay ' + (isShown ? 'show' : 'fade')">
    <div @click="close()" ref="closeButton" class="overlay-close-button" style="text-shadow: 2px 2px 4px #000000">
        <span icon="close-circle"></span>
    </div>
    <div class="overlay-modal-container" data-clarity-unmask="true" :class="containerClass" :style="containerStyleC">
        <div @mousedown="(e) => { lastOverlayMouseDown = false; }" :class="'overlay-modal ' + sclass" :style="style"
            v-bind="$attrs">
            <slot></slot>
        </div>
    </div>
</div>
</template>
<script>
export default {
    name: "overlay-modal",
    emits: ['loaded'],
    props: {
        position: {
            type: String,
            validator(val) {
                return ["top", "bottom", "center", "left", "right", "top left", "top right", "bottom left", "bottom right"].includes(val);
            },
            default: "center",
        },
        showOnInit: {
            default: false,
            type: Boolean,
        },
        style: {
            //this is a neat way to prevent style overriding from parent
            type: Object,
            default: {},
        },
        class: {
            type: String,
            default: "",
        },
        containerClass: {
            type: String,
            default: "",
        },

    },
    computed: {
        containerAS() {
            switch (this.position) {
                default:
                    return "center";
                case "left":
                case "top left":
                case "bottom left":
                    return "flex-start";
                case "right":
                case "top right":
                case "bottom right":
                    return "flex-end";
            }
        },
    },
    data() {
        return {
            overlayStyle: { justifyContent: this.overlayJC() },

            containerStyle: {},
            containerStyleC: {},
            isShown: false,
            sclass: this.class,
            lastOverlayMouseDown: false
        };
    },
    mounted() {
        this.isShown = this.showOnInit;
        this.$emit('loaded');
    },
    watch: {
        containerStyle: {
            immediate: true,
            handler() {

                var obj = { ...this.containerStyle }
                obj.alignSelf = this.overlayJC();
                this.containerStyleC = obj;
            }
        }
    },
    methods: {
        overlayJC() {
            switch (this.position) {
                default:
                    return "center";
                case "top":
                case "top left":
                case "top right":
                    return "flex-start";
                case "bottom":
                case "bottom left":
                case "bottom right":
                    return "flex-end";
            }
        },
        overlayMouseUp(e) {
            //this all can't be done with "click" as a click event is also triggered when you mousedown the card, move to the overlay, and release
            if (e.target == this.$refs.overlay)
                this.lastOverlayMouseDown = true;
            else
                this.lastOverlayMouseDown = false;

        },
        overlayMouseDown(e) {
            if (e.target == this.$refs.overlay && this.lastOverlayMouseDown)
                this.close();
            else
                this.lastOverlayMouseDown = false;
        },
        show() {
            this.isShown = true;
            var obj = { ...this.containerStyle }
            obj.alignSelf = this.overlayJC();
            this.containerStyleC = obj;
            this.$forceUpdate();
        },
        close() {
            this.isShown = false;
        },
    },
};
</script>
<style scoped lang="scss">
@import "/src/styles/bootstrap/_global_variables.scss";

.overlay-close-button {
    position: fixed;
    right: 10px;
    top: 10px;
    color: white;
    font-size: 35px;
    cursor: pointer;
    z-index: 10;
}

@include media-breakpoint-down(md) {
    :deep(.md-full-screen) {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
    }
}

@media print {
    .overlay-close-button {
        display: none !important;
    }
}
</style>
