import apiClient from "./api";
import baseApi from "../baseApi";
import state from "@/services/state/state";

export default class MembershipApi extends baseApi {

    constructor() {
        super();
        this.subURL = "/membership/";
    }

    async getActiveMemberships() {
        return await apiClient.standardGet(this.subURL);
    }

    async reloadMemberships() {
        var memberships = await this.getActiveMemberships();
        state.registerStored("activeMemberships", memberships);
        return memberships;
    }

}