import { Skill } from "@/core/scoped/entities/Root/Accreditation/Skill.js";
import { SkillNode } from "@/core/scoped/entities/Root/Accreditation/SkillNode.js";
import { Activity } from "@/core/scoped/entities/Root/Activity.js";
import { ActivityMetaData } from "@/core/scoped/entities/Root/ActivityMetaData.js";
import { EmploymentPosition } from "@/core/scoped/entities/Root/EmploymentPosition.js";
import { Organization } from "@/core/scoped/entities/Root/Organization.js";
import { TalentMatch } from "@/core/scoped/entities/Root/Community/Backoffice/TalentMatch.js";
import { Product } from "@/core/scoped/entities/Root/Product.js";
import { Notification } from "@/core/scoped/entities/Root/Notification.js";
import { Resource } from "@/core/scoped/entities/Root/Resource.js";
import { Role, RoleArray } from "@/core/scoped/entities/Root/Role.js";
export default class InsightsEnvelope {

    #activity;
    #employmentPosition;
    #talentMatch;
    #organization;
    #error;
    #success;
    #product;
    get activity() {
    	return this.#activity;
    }
    set activity(a) {
    	if (a instanceof Activity)
    		this.#activity = a;
    	else this.throw();
    }
    get employmentPosition() {
    	return this.#employmentPosition;
    }
    set employmentPosition(a) {
    	if (a instanceof EmploymentPosition)
    		this.#employmentPosition = a;
    	else this.throw();
    }
    get organization() {
    	return this.#organization;
    }
    set organization(a) {
    	if (a instanceof Organization)
    		this.#organization = a;
    	else this.throw();
    }
    get talentMatch() {
    	return this.#talentMatch;
    }
    set talentMatch(a) {
    	if (a instanceof TalentMatch)
    		this.#talentMatch = a;
    	else this.throw();
    }
    get error() {
    	return this.#error;
    }
    set error(v) {
    	if (v instanceof String || typeof (a) == "string") {
    		this.#error = v;
    	}
    }
    get success() {
    	return this.#success;
    }
    set success(v) {
    	if (v instanceof Boolean || typeof (a) == "boolean") {
    		this.#success = v;
    	}
    }
    set product(a) {
    	if (a instanceof Product)
    		this.#product = a;
    	else this.throw();
    }
    get product() {
    	return this.#product;
    }

    //This class is used to be the default insights envelope.
    //so this will be a base sturcture used by all insights loggers (google/azure) to create their own insights envelope from.
    constructor(args) {
    	if (args && args.length) {
    		for (var e of args) {
    			if (e instanceof Activity) {
    				this.activity = e;
    			} else if (e instanceof EmploymentPosition) {
    				this.employmentPosition = e;
    			} else if (e instanceof Organization) {
    				this.organization = e;
    			} else if (e instanceof TalentMatch) {
    				this.talentMatch = e;
    			} else if (e instanceof Product) {
    				this.product = e;
    			}
    			else {
    				this.throw();
    			}
    		}
    	}
    }
    throw() {
    	throw new Error("You can only use objects that extend BaseModel to make InsightsEnvelopes");
    }



}