import state from "@/core/global/state/state";
import ProfileApi from "@/core/scoped/services/api/Community/profile";
const stateStoring = function (me) {
    if (me.stateStored) {
        let name = stateName(me);
        if (!state['ips'])
            state['ips'] = {};
        state['ips'][name] = JSON.parse(JSON.stringify(me.modelValue));
    }
};
const stateName = function (me) {
    let name = undefined;
    if (me.stateStored.length) {
        name = me.stateStored;
    } else {
        if (me.$attrs.id)
            name = me.$attrs.id;
        else if (me.name)
            name = me.name;
        else if (me.$el && me.$el.getAttribute("id"))
            name = me.$el.getAttribute("id");
    }
    if (!name)
        throw new Error("You have to set an id or name or set stateStored to a string to store the state of your element value");
    return name;
};
export default {
    computed: {
        //With the computed variables we expose part(s) of the state we know can be there by default
        /*   $user() {
               return state.user;
           },
           $state() {
               return state;
           }*/
    },
    data() {
        return {
            $user: state.user,
            $state: state,
            $currentProfile: null
        };
    },
    beforeCreate() {
        this.$state = state;
        this.$user = state.user;
        if (this.$user) {
            new ProfileApi().getProfile().then(profile => {
                this.$currentProfile = profile;
            });
        }
    },
    watch: {
        //The state stored property allows for a generic way of keeping the "value" property of a component stored in the state.
        //Simply set "stateStored" to a identifying string and the value will be stored (default in the session storage)
        stateStored: {
            immediate: true,
            handler(v) {
                if (v) {
                    if (!this.un1) {
                        //not set yet so first read
                        let name = stateName(this);
                        if (state.ips && state.ips[name]) {
                            let k = JSON.parse(JSON.stringify(state.ips[name])); //parse/stringify to "remove" state proxy stuff.
                            if (this.stateStoredType) {
                                if (Array.isArray(k)) {
                                    k = k.map(item => {
                                        return Object.assign(new this.stateStoredType, item);
                                    });
                                } else {
                                    k = Object.assign(new this.stateStoredType, k);
                                }
                            }
                            this.value = k;
                            this.$emit('update:modelValue', k);
                        }
                    }
                    let watchLength = () => {
                        if (!this.un2 && this.modelValue && this.modelValue.length != undefined) {
                            this.un2 = this.$watch("modelValue.length", (d) => {
                                stateStoring(this);
                            });
                        }
                    };
                    watchLength();
                    this.un1 = this.$watch("modelValue", (d) => {
                        watchLength();
                        stateStoring(this);
                    });

                } else {
                    if (this.un1)
                        this.un1();
                    if (this.un2)
                        this.un2();
                }
            }
        }
    },
    props: {
        stateStored: {
            required: false,
            default: null,
            type: [String, Boolean]
        },
        stateStoredType: {
            required: false,
            default: null,
            type: Function
        }
    }
};

//The next bit of code is to allow the "stateStored" attribute to also work on normal html inputs.
const ichange = function () {
    let n = this.getAttribute("stateStored");
    if (!state['ips'])
        state['ips'] = {};
    if (this.checked != undefined && this.getAttribute('type') == 'checkbox') //checkboxes
        state['ips'][n] = JSON.parse(JSON.stringify(this.checked));
    else
        state['ips'][n] = JSON.parse(JSON.stringify(this.value ?? 'off'));
};
let fnall = function (mutationList) {
    let statecall = (e) => {
        let n = e.getAttribute("stateStored");
        if (!e.storing || e.storing != n) {
            if (e.storing) {
                e.removeEventListener('input', ichange);
                if (state['ips']) {
                    delete state['ips'][e.storing];
                    state.stateChanged(false);
                }
            }
            if (n) {
                e.addEventListener('input', ichange);
                e.storing = n;
                if (state['ips'] && state['ips'][n] !== undefined) {
                    const input = new Event('input')
                    const change = new Event('change')
                    if (e.getAttribute('type') == 'checkbox') {
                        if (state['ips'][n] == 'on' || state['ips'][n] === true || state['ips'][n] == 'true') {
                            e.checked = true;
                        }
                        else {
                            e.checked = false;
                        }
                    }
                    e.value = state['ips'][n];
                    e.dispatchEvent(input);
                    e.dispatchEvent(change);
                }
            } else {
                e.removeEventListener('input', ichange);
                if (state['ips']) {
                    delete state['ips'][e.storing];

                    state.stateChanged(false);
                }
                e.storing = false;
            }
        }
    };
    mutationList.forEach((mutation) => {
        switch (mutation.type) {
            case "attributes":
                switch (mutation.attributeName) {
                    case "statestored":
                        statecall(mutation.target);
                        break;
                }
                break;
            case "childList":
                if (window.mscall)
                    clearTimeout(window.mscall);
                window.mscall = setTimeout(() => {
                    document.querySelectorAll("[stateStored]").forEach(e => {
                        statecall(e);
                    }), 50;
                });
                break;
        }
    });
};
let allObserver = new MutationObserver((e) => {
    fnall(e);
});
if (!window.obs) {
    allObserver.observe(document, {
        attributes: true,
        subtree: true,
        childList: true,
        attributeFilter: ["stateStored", "statestored"]
    });
    window.obs = allObserver;
}
