<template>
<div id="toastContainer" class="m-2">
    <div ref="notificationToast" v-for="n in pushNotifications" :key="n.id" aria-atomic="true" aria-live="assertive"
        @click="clickNotification(n)" style="cursor: pointer;" class="mt-3" role="alert">
        <transition name="fade" mode="in-out">
            <notifier class="notifier" :show="true" :status="getRequiredPopupStatusType(n, statusType)"
                :closeable="true" :title="n.content?.header">
                <template v-if="n.originProfileId" v-slot:prependIconSection>
                    <profile-picture :profileId="n.originProfileId" :width="'30px'" :height="'30px'"
                        class="me-3 mb-auto"></profile-picture>
                </template>
                <template v-slot:content>
                    <div ref="loginSpinner" :style="[n.isNavigating() ? 'display:inline-block' : 'display:none']"
                        class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="card card-body" v-if="n.originProfileId && n.content?.body">
                        <small>"{{ n.content.body.substring(0, 60) }} {{ n.content.body.length > 63 ? '...' : ''
                            }}"</small>
                    </div>
                </template>
            </notifier>
        </transition>
    </div>
</div>
</template>
<script>
import { Toast } from "bootstrap";
import NotificationHubConnector from "@/core/scoped/services/hubs/hubconnector";
import Converter from "@/core/scoped/UI/markdown/converter";

import profilePicture from "@/components/profile/picture/profile-picture.vue";
import Notifier from "@/components/misc/notifiers/notifier.vue";

export default {
    components: { profilePicture, Notifier },
    emits: ["notificationReceived", "notificationClicked"],
    name: "realtime-notification-container",
    props: {
        statusType: {
            required: false,
            default: null,
            type: String
        }
    },
    data() {
        return {
            converter: new Converter(),
            pushNotifications: [],
            toasts: [],
        };
    },
    mounted() {
        if (this.$state.user && this.$state.user.bearerToken) {
            if (!window.apiSettings?.isLive || !window.apiSettings?.canSwitch) {
                this.notificationHubConnector = new NotificationHubConnector();
                this.notificationHubConnector.on("notification", (notification) => {
                    if ("serviceWorker" in navigator) {
                        try {
                            navigator.serviceWorker.controller.postMessage({ clearCacheEntry: window.apiSettings.URL + "/notification/" });
                        } catch { console.warn('failed to clear notification cache, refresh page twice to get correct notification list'); }
                    }
                    this.$emit("notificationReceived", notification);
                    notification.setNavigating(false);
                    this.pushNotifications.push(notification);
                    this.$nextTick(() => {
                        var elm = this.$refs.notificationToast;
                        if (Array.isArray(elm)) elm = elm[elm.length - 1];
                        let myToast = new Toast(elm, {
                            animation: true,
                            delay: 4000,
                        });
                        this.toasts.push(myToast);
                        myToast.show();
                        setTimeout(() => {
                            this.pushNotifications = this.pushNotifications.filter((r) => r != notification);
                        }, 5000);
                    });
                });
                this.notificationHubConnector.registerHubConnection();
            }
        }
    },
    methods: {
        clickNotification(notification) {
            if (notification.id) {
                this.$emit("notificationClicked", notification);
            }
            this.pushNotifications = this.pushNotifications.filter((r) => r != notification);
        },

        getRequiredPopupStatusType(notification, overrideStatus) {
            if (overrideStatus && overrideStatus.length) {
                return overrideStatus;
            }
            if (notification.type === "update") {
                if (notification.content?.header?.includes("denied")) {
                    return "warning";
                }
                else if (notification.content?.header?.includes("accepted")) {
                    return "success";
                } else {
                    return "info";
                }
            } else {
                return "info";
            }
        }
    },
};
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
