import apiClient from "./api";
import JourneyApi from "@/core/scoped/services/api/journey";
export default class CommunityJourneyApi extends JourneyApi {

    constructor() {
        super();
        this.subURL = "/career/journey/";
    }

    async startWithMagicLink(magicLink) {
        return await apiClient.standardGet(this.subURL + "start?magicLink=" + magicLink);
    }
    async getActiveJourney(id, ignoreCache = false) {
        let journey = null;
        let exception = null;
        let url = this.subURL + "active/" + id;
        if (id.toLowerCase() == 'base') {
            id = 'base';
            url = this.subURL + "base/";
        }
        try {
            if (ignoreCache) {
                url = url + '?nocache=1';
                this.clearCache(id);
                journey = await apiClient.standardGet(url);
            }
            else {
                url = url + '?cacheonly=1&forcecache=1&cachetime=300';
                journey = await apiClient.standardGet(url);
            }
        } catch (e) {
            exception = e;
        }
        if (!journey) {
            apiClient.clearCache(this.subURL + "active/" + id);
            apiClient.clearCache(url);
        }
        if (exception)
            throw exception;
        return journey;

    }
    clearCache(id = null) {
        if (id == null) {
            apiClient.clearCache(this.subURL);
            return;
        }
        apiClient.clearCache(this.subURL + "active/" + id);
        apiClient.clearCache(this.subURL + "active/" + id + '?cacheonly=1&forcecache=1&cachetime=300');//we don't cache with the cache parameters anymore so this one shouldn't be required
    }
    async startJourney(id) {
        let result = await this.apiClient.standardGet(this.subURL + "start/" + id);
        this.clearCache();
        return result;
    }
    async getBaseJourney(ignoreCache = false) {
        return await this.getActiveJourney('base', ignoreCache);
    }

    async selectMentor(journeyActivityId, journeyStatusId, mentorProfileId) {
        return await apiClient.put(this.subURL + journeyActivityId + "/" + journeyStatusId + "/mentor/select/" + mentorProfileId);
    }

    async getAvailableMentors(journeyId) {
        return await apiClient.standardGet(this.subURL + journeyId + "/mentors");
    }

    async selectActivityForStep(journeyId, stepId, activityId) {
        this.clearCache();
        return await apiClient.standardGet(this.subURL + "selectactivity/" + journeyId + "/" + stepId + "/" + activityId);
    }

    async manuallyCompleteTask(journeyId, stepId, taskIdentifier) {
        this.clearCache();
        return await apiClient.standardGet(this.subURL + "finishtask/" + journeyId + "/" + stepId + "/" + taskIdentifier);
    }

    async getProductCheckoutUrl(productIdentifier) {
        this.clearCache();
        return await apiClient.standardGet(this.subURL + "order/product/" + productIdentifier);
    }
}
