let arr = [{
    "@type": "mentorshipTrack",
    "title": "Custom Mentorship Track",
    "subtitle": "Experience a transformative learning journey tailored to your individual goals and current skill level.",
    "headerImage": {
        "@type": "resource",
        "resourceLocation": process.env.VUE_APP_ENV_BASE_URL + require("@/assets/img/artwork/questionair.webp")
    },
}, {
    "@type": "mentorshipTrack",
    "title": "Become a Data Engineer",
    "subtitle": "Architect robust data pipelines, optimize data workflows and drive impactful insights.",
    "headerImage": {
        "@type": "resource",
        "resourceLocation": process.env.VUE_APP_ENV_BASE_URL + require("@/assets/img/artwork/learn.webp")
    },
}, {
    "@type": "mentorshipTrack",
    "title": "Become a ML Engineer",
    "subtitle": "Leverage cutting-edge algorithms, frameworks, and tools to drive transformative solutions.",
    "headerImage": {
        "@type": "resource",
        "resourceLocation": process.env.VUE_APP_ENV_BASE_URL + require("@/assets/img/artwork/micro.webp")
    }
}, {
    "@type": "mentorshipTrack",
    "title": "Become a Product Owner",
    "subtitle": "Explore skills to prioritize features, align stakeholder expectations, and deliver value-driven solutions.",
    "headerImage": {
        "@type": "resource",
        "resourceLocation": process.env.VUE_APP_ENV_BASE_URL + require("@/assets/img/artwork/learn.webp")
    }
}, {
    "@type": "mentorshipTrack",
    "title": "Become a Scrum Master",
    "subtitle": "Navigate the intricacies of Scrum, lead teams effectively, and drive successful project outcomes.",
    "headerImage": {
        "@type": "resource",
        "resourceLocation": process.env.VUE_APP_ENV_BASE_URL + require("@/assets/img/artwork/questionair.webp")
    }
}, {
    "@type": "mentorshipTrack",
    "title": "Become MLOps Engineer",
    "subtitle": "Master encompassing model development, deployment orchestration, CI/CD implementation,  infrastructure scalability, and effective collaboration.",
    "headerImage": {
        "@type": "resource",
        "resourceLocation": process.env.VUE_APP_ENV_BASE_URL + require("@/assets/img/artwork/micro.webp")
    }
}, {
    "@type": "mentorshipTrack",
    "title": "Become a Storyteller",
    "subtitle": "Learn to captivate audiences and craft compelling narratives that resonate with authenticity.",
    "headerImage": {
        "@type": "resource",
        "resourceLocation": process.env.VUE_APP_ENV_BASE_URL + require("@/assets/img/artwork/learn.webp")
    }
}]

for (let i = 0; i < arr.length; i++) {
    arr[i].products =
        [
            {
                "@type": "membershipProduct",
                "identifier": "fakeproduct1",
                "name": "hc",
                "pricings": [
                    {
                        "@type": "pricing",
                        "name": "hcBaseMonthly",
                        "currency": "EUR",
                        "priceInCents": "15900",
                        "productIdentifier": "fakeproduct1",
                        "isActive": true,
                        "type": "recurring",
                        "recurringPriceSettings": {
                            "interval": "month",
                            "intervalCount": 1
                        }
                    }
                ]
            }
        ];

}

export default arr;