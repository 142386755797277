import { BaseModel } from "@/core/scoped/entities/BaseModel";
import { Role } from "@/core/scoped/entities/Root/Role";
export class CreditSheet extends BaseModel { //Community because this will get your own profile only, so you require to be logged in
    static get JSONLDType() { return "creditSheet"; }
    #creditSummary;
    get creditSummary() {
        if (this.#creditSummary == null) {
            if (this.activePeriod?.activityCredit != null) {
                this.#creditSummary = {};
                for (var n in this.activePeriod.activityCredit) {
                    let cred = this.activePeriod.activityCredit[n];
                    let rcred = this.activePeriod?.remainingActivityCredit[n]
                    this.#creditSummary[n] = ((rcred !== null && rcred !== undefined) ? rcred : '?') + '/' + cred;
                }
            }
        }
        return this.#creditSummary;
    }
    constructor() {
        super();
    }
    load(source, freeze = true) {
        super.load(source, freeze);
        this.makeTypeGetSet('role', Role);
        this.makeDateGetSet('sheetExpires');
    }
}