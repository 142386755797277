import ModelBuilder from "@/core/scoped/entities/ModelBuilder";
import { PopupGenerator } from "@/core/global/UI/Popups";
import axios from "axios";
import state from "@/core/global/state/state";
import { AutoImportedEntities } from "@/core/scoped/entities/Root/EntityImporter.js";

let cancelToken = axios.CancelToken;
let source = cancelToken.source();
let baseUrl = window.apiSettings.URL;
const clearCacheOnPatch = process.env.VUE_APP_ENV_CLEAR_CACHE_ON_PATCH !== undefined ? process.env.VUE_APP_ENV_CLEAR_CACHE_ON_PATCH : true;
const clearFullCacheOnPatch = process.env.VUE_APP_ENV_CLEAR_CACHE_ON_PATCH !== undefined ? process.env.VUE_APP_ENV_CLEAR_CACHE_ON_PATCH : false;
const popupGenerator = new PopupGenerator();

function createClient() {
    const apiClient = axios.create({
        baseURL: baseUrl,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        withCredentials: true,
    });
    Object.defineProperty(apiClient, 'onlineState', {
        get: function () {
            return apiClient.online;
        }, set: function (value) {
            var change = apiClient.online != value;
            apiClient.online = value;
            if (apiClient.onlineStateChanged && change)
                apiClient.onlineStateChanged(apiClient.online);
        }
    });
    Object.defineProperty(apiClient, 'modelBuilder', {
        get: function () {
            if (!apiClient._modelBuilder)
                apiClient._modelBuilder = new ModelBuilder(apiClient.knownEntities);
            return apiClient._modelBuilder;
        }
    });
    apiClient.knownEntities = [];
    apiClient.setKnownEntities = function (entities) {
        let newents = entities.filter(c => !apiClient.knownEntities.find(c2 => c2.prototype instanceof c && c.JSONLDType == c2.JSONLDType)); //don't try to import an entity of which a more extended version is already imported
        let newknown = apiClient.knownEntities.filter(c => !newents.find(c2 => (c2.prototype instanceof c || c2 == c) && c.JSONLDType == c2.JSONLDType)); //remove all entities of which we're going to import a more extended version
        let intermediate = [...newknown];
        entities.forEach(e => {
            intermediate.push(e);
        });
        apiClient.knownEntities = [...intermediate];
        apiClient._modelBuilder = null;
        if (apiClient.entitiesUpdated != null)
            apiClient.entitiesUpdated();
        //Known entities is now the deepest level implementation entity available.
    };
    apiClient.setKnownEntities(AutoImportedEntities);
    apiClient.secondCallbacks = [];
    apiClient.online = "online";
    apiClient.serviceWorkerActive = false;
    if (!apiClient.clearer) {
        apiClient.clearer = setInterval(() => {
            apiClient.secondCallbacks = apiClient.secondCallbacks.filter((elm) => {
                return new Date().getTime() - elm.added.getTime() < 3000;
            });
        }, 3000);
    }
    apiClient.oldGet = apiClient.get;
    apiClient.get = function () {
        var args = [...arguments];
        if (apiClient.silent) {
            let config = args[1] ?? {};
            config.silent = 1;
            args[1] = config;
            apiClient.silent = false;
            return new Promise((resolve, reject) => {
                apiClient.oldGet(...args).then((r) => resolve(r)).catch((err) => { reject(err) });
            })
        }
        return apiClient.oldGet(...args);
    };
    apiClient.oldPost = apiClient.post;
    apiClient.post = function () {
        var args = [...arguments];
        if (apiClient.silent) {
            let config = args[2] ?? {};
            config.silent = 2;
            apiClient.silent = false;
            try {
                args[2] = config;
            } catch { }
            return new Promise((resolve, reject) => {
                apiClient.oldPost(...args).then((r) => resolve(r)).catch((err) => { reject(err) });
            })
        }
        return apiClient.oldPost(...args);
    };
    apiClient.interceptors.request.use((config, f) => {
        config.cancelToken = source.token; //Used to add call cancel capability at later point
        config.withCredentials = true; //Seems to be needed to specify again
        if (apiClient.silent || config.silent) {
            config.silent = true;
            apiClient.silent = false;
        }
        if (apiClient.noCache)
            config.params = { ...config.params, nocache: '1' };
        if ("serviceWorker" in navigator && !apiClient.serviceWorkerActive) { //used for the double callback to get new data after cached return.
            navigator.serviceWorker.addEventListener('message', event => {
                if (event.data && event.data.newdata) {
                    let request = event.data.request;
                    let cb = apiClient.secondCallbacks.find((elm) => {
                        return elm.url == request;
                    });
                    if (cb) {
                        var builder = new ModelBuilder(apiClient.knownEntities);
                        let newdata = builder.getModel(event.data.newdata);
                        cb.callback(newdata);
                    }
                }
            });
            apiClient.serviceWorkerActive = true;
        }
        if (state && state.user && state.user.bearerToken) { //set bearertoken if available
            if (
                !state.user.sessionExpires ||
                Date.parse(state.user.sessionExpires) > Date.now()
            ) {
                config.headers.Authorization = "Bearer " + state.user.bearerToken.replaceAll(" ", "+");
                if (state.organization) { // set organization if available
                    config.headers.Organization = state.organization.id;
                }
            }
        }
        if (clearCacheOnPatch == '1' && (config.method === "patch" || config.method == "put" || config.method == "post")) {
            try {
                if (clearFullCacheOnPatch && clearFullCacheOnPatch !== '0' && clearFullCacheOnPatch !== 'false') {
                    apiClient.clearCache(); //always clear the api cache after a patch call to force the updates
                }
                else apiClient.clearCache(config.url);
            }
            catch (e) { console.warn(e); }
        }
        return config;
    });
    apiClient.interceptors.response.use((response) => {
        apiClient.onlineState = "online";
        var builder = new ModelBuilder(apiClient.knownEntities); //has to be a new one each time
        response.data = builder.getModel(response.data);
        return response;
    }, (error) => {
        if (error.response) {
            if (error.response.headers && error.response.headers.reason) {
                let reason = error.response.headers.reason;
                if (reason == "expired") {
                    localStorage.clear();
                    sessionStorage.clear();
                    try {
                        if ("serviceWorker" in navigator && navigator.serviceWorker.controller) {
                            navigator.serviceWorker.controller.postMessage({ clearAll: true });
                        }
                    } catch { }
                    document.location.href = "/";
                    return;
                }
            }
            if (error.response.data && !error.config.silent) {
                if (error.response.data.errors) {
                    let ers = error.response.data.errors;
                    if (ers.filter || ers[""] || Array.isArray(ers)) {
                        var filterErrors = ers.filter != null ? ers.filter : (Array.isArray(ers) ? ers : ers[""]);
                        var str = "";
                        for (let i = 0; i < filterErrors.length; i++) {
                            str += (i > 0 ? '<br/>' : '') + filterErrors[i];
                        }
                        popupGenerator.btAlert(str ? str : 'Something about your input was wrong', "warning", 5000);
                    }
                }
                else if ((error.response.data.Title || error.response.data.title)) {
                    popupGenerator.btAlert(error.response.data.Title ?? error.response.data.title, "warning", 5000);
                }
                else if (typeof error.response.data === 'string' || error.response.data instanceof String) {
                    popupGenerator.btAlert(error.response.data, "danger", 5000);
                }
            }
            apiClient.onlineState = "online"; //No matter the error, we're online!
        }
        if (!error.response && error.request) {

            //We ping the server once again. If it really doens't work the server is down.
            //Otherwise it's just that single endpoint that is the problem.
            fetch(baseUrl + "/systemInfo/live").then(() => {
                apiClient.onlineState = "online";
            }).catch(() => {
                //This means the request was made but we didn't get a result
                apiClient.onlineState = "offline";
            });
            if (popupGenerator.btAlert)
                popupGenerator.btAlert("Something went wrong with the API", "danger", 5000);

        }
        throw error;
    });

    apiClient.genericPatch = function (patchUrl, patchStruct) {
        return new Promise((resolve, reject) => {
            this.patch(patchUrl, patchStruct).then(data => {
                resolve(data.data);
            }).catch(err => {
                if (err.response != null && err.response.data != null) {
                    reject(err.response.data);
                }
                else reject(err);
            });
        });
    };
    apiClient.clearCache = function (item) {
        try {
            if (window.navigator?.serviceWorker?.controller)
                window.navigator.serviceWorker.controller.postMessage({ action: "clearCache", target: "api", item: item });
        } catch { console.warn("clearing cache after update failed"); }
    };
    apiClient.setResource = function (file, resourceUrl, progressCallback) {
        var formData = new FormData();

        formData.append("file", file);
        return new Promise((resolve, reject) => {
            this.post(resourceUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: progressCallback
            }).then(data => {
                resolve(data.data);
            }).catch(() => {

                reject();
            });
        });
    };
    apiClient.standardGet = function () {
        return new Promise(async (resolve, reject) => {
            var fn = null;
            var newArgs = [...arguments];
            if (newArgs[1] && typeof newArgs[1] === 'function') {
                fn = newArgs[1];
                newArgs.splice(1, 1);
            }
            else if (newArgs[2] && typeof newArgs[2] === 'function') {
                fn = newArgs[2];
                newArgs.splice(2, 1);
            }
            if (fn) {
                var createUrl = new URL(baseUrl + newArgs[0]);
                if (newArgs[1] && newArgs[1].params) {
                    createUrl = addSearchParams(createUrl, new URLSearchParams(newArgs[1].params));
                }
                apiClient.secondCallbacks.push({ args: arguments, url: createUrl.toString(), added: new Date(), callback: fn });
            }
            try {
                let data = await this.get(...newArgs);
                resolve(data.data);
            } catch (error) {
                reject(error);
            }
        });
    };
    apiClient.standardPost = function () {
        return new Promise((resolve, reject) => {
            this.post(...arguments).then(data => {
                resolve(data.data);
            }).catch(error => {
                reject(error);
            });
        });
    };

    apiClient.standardQuery = function () {
        return new Promise((resolve, reject) => {
            this.query(...arguments).then(data => {
                resolve(data.data);
            }).catch(error => {
                reject(error);
            });
        });
    };
    apiClient.query = function (url, data, moreconfig) {
        return new Promise((resolve, reject) => {
            moreconfig ??= {};
            moreconfig.data = data;
            moreconfig.url = url;
            moreconfig.method = "QUERY"
            this(moreconfig).then(data => {
                resolve(data);
            }).catch(error => {
                reject(error);
            });
        });
    };
    const addSearchParams = (url, params = {}) =>
        new URL(
            `${url.origin}${url.pathname}?${new URLSearchParams([
                ...Array.from(url.searchParams.entries()),
                ...Object.entries(params),
            ]).toString()}`
        );
    return apiClient;
}
if (window.staticClient == null)
    window.staticClient = createClient();
export default window.staticClient; //Needs to be a static export because all services should use same apiclient
export function newClient() {
    return createClient();
}