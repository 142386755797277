import { Activity } from "./Activity";
import { MembershipProduct } from "./MembershipProduct";
export class MentorshipTrack extends Activity {
    static get JSONLDType() { return "MentorshipTrack"; }
    load(source, freeze = true) {
        super.load(source, false);
        super.makeTypeGetSet('products', Array.of(MembershipProduct));
    }
    get cheapestProduct() {
        if (!this.products?.length)
            return null;
        let max = Math.min(...this.products.map(o => o.cheapestPrice?.singleYearPrice).filter(f => f !== null))
        return this.products.find(f => f.cheapestPrice.singleYearPrice == max);
    }

    get toRoute() {
        return { name: 'mentorshipPage' }
    }
}