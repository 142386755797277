import { BaseModel } from "@/core/scoped/entities/BaseModel";

export class Skill extends BaseModel {
    static get JSONLDType() { return "Skill"; }
    #color;
    get color() { return this.#color; }
    set color(v) { this.#color = v; }
    constructor() {
        super();
    }
}
