import { SDGProvider } from "@/core/global/SDGProvider";
import apiClient from "./api";
import baseApi from "./baseApi";
import state from "@/core/global/state/state";
import { Activity } from "@/core/scoped/entities/Root/Activity";

export default class ProfileApi extends baseApi {

    constructor() {
        super();
        this.subURL = "/profile/";
        this.sdgProvider = new SDGProvider();
    }

    async getAgenda() {
        let result = await apiClient.standardGet(this.subURL + "agenda");

        if (result.items && result.items.length)
            result.items.forEach(r => { r.childActivities = r.childActivities ?? r.items?.map(i => { let k = new Activity(); k.load(i); return k; }); });
        return result;
    }

    async setTestimonialPublicity(postId, isPublished) {
        return await apiClient.patch(this.subURL + 'testimonials/' + postId + '?publish=' + isPublished);
    }

    async setTestimonialPublicityBulk(postIds, isPublished) {
        return await apiClient.patch(this.subURL + "testimonials/bulk?publish=" + isPublished, postIds);
    }

    async deleteTestimonial(postId) {
        return await apiClient.delete(this.subURL + 'testimonials/' + postId);
    }

    async getActiveChallenges() {
        return await apiClient.standardGet(this.subURL + "activechallenges");
    }

    async listProfileActivities(profileId, anonymousIdentifier, onlyNotFinished, skip, take) {
        var url;
        if (anonymousIdentifier && anonymousIdentifier.length) {
            url = this.subURL + "anonymous/" + profileId + "/activities" + "?anonymousIdentifier=" + anonymousIdentifier + "&onlyNotFinished=" + onlyNotFinished + "&skip=" + skip + "&take=" + take;
        } else {
            url = this.subURL + profileId + "/activities?onlyNotFinished=" + onlyNotFinished + "&skip=" + skip + "&take=" + take;
        }
        return await apiClient.standardGet(url);
    }

    async getActivityTitlesThatProfilesAreMemberOf(profileIds, currentlyEnlistedOnly) {
        return await apiClient.standardPost(this.subURL + "activities/bulk?currentlyEnlistedOnly=" + currentlyEnlistedOnly, profileIds);
    }


    async addEmail(email) {
        return await apiClient.standardPost(this.subURL + "email", { email: email });
    }

    async getPendingAddresses() {
        return await apiClient.standardGet(this.subURL + "email/pending");
    }

    async deletePendingAddress(item) {
        return await apiClient.delete(this.subURL + "email/pending?pendingEmailAddress=" + item);
    }

    async validateEmail(token) {
        return await apiClient.standardGet(this.subURL + "email/validate/" + token + "?nocache=1");
    }

    async setProfileImage(file, progressCallback) {
        return await apiClient.setResource(file, this.subURL + 'picture', progressCallback);
    }

    async deleteProfile() {
        return await apiClient.delete(this.subURL);
    }

    async getTestimonialsForProfile(profileId, anonymousIdentifier, skip, take, unassigned) {
        var url;
        if (anonymousIdentifier && anonymousIdentifier.length) {
            url = this.subURL + "anonymous/" + profileId + "/testimonials?skip=" + skip + "&take=" + take + "&unassigned=" + unassigned + "&anonymousIdentifier=" + anonymousIdentifier;
        } else {
            url = this.subURL + profileId + "/testimonials?skip=" + skip + "&take=" + take + "&unassigned=" + unassigned;
        }
        return await apiClient.standardGet(url);
    }

    async generateAnonymousLinkForProfile() {
        var url = this.subURL + "anonymous/key";
        return await apiClient.standardPost(url, {
            displayName: "PrimaryKey",
            enabled: true
        });
    }

    async getProfileViaAnonymousLink(profileId, anonymousIdentifier) {
        return await apiClient.standardGet(this.subURL + "anonymous/" + profileId + "?identifier=" + anonymousIdentifier);
    }

    async updateSlackId() {
        return await apiClient.standardPost("/slack/updateid", {});
    }

    async overrideProfileProperties(namesWithValues) {
        namesWithValues.forEach(nameWithValue => {
            nameWithValue.name = nameWithValue.name.replaceAll(".", "/");
            //This is the mapping between enums that we have on the backend, and SDGs we store on the frontend.
            if (nameWithValue.name.toLowerCase() === "sdgs") {
                let idList = [];
                var allSDGs = this.sdgProvider.getSDGs();
                allSDGs.forEach(sdg => {
                    var sdgName = Object.keys(sdg)[0];
                    var sdgId = sdg.id;
                    nameWithValue.value.forEach(valueName => {
                        if (valueName == sdgName || valueName.value == sdgName) {
                            idList.push(sdgId);
                        }
                    });
                });
                nameWithValue.value = idList;
            }
        });

        var override = namesWithValues.map((nameWithValue) => ({
            op: "replace",
            path: "/" + nameWithValue.name,
            value: nameWithValue.value
        }));

        return await this.jsonPatchProfile(override);
    }

    async setResidence(residenceObject = {
        latitude: undefined,
        longitude: undefined,
        description: undefined,
        region: undefined,
        subregion: undefined,
        city: undefined
    }) {
        return await apiClient.patch(this.subURL + "residence", residenceObject);
    }

    async jsonPatchProfile(patchStruct) {
        const data = await apiClient.patch(this.subURL, patchStruct);
        const changes = data.data;
        for (let n in changes) {
            try {
                state.currentProfile[n] = changes[n];
            } catch { }
        }
        state.unregisterStored('currentProfile');
        return data.data;
    }

    async getStatistics(profileId, anonymousIdentifier) {
        var url;
        if (anonymousIdentifier && anonymousIdentifier.length) {
            url = this.subURL + "anonymous/" + profileId + "/statistics?anonymousIdentifier=" + anonymousIdentifier;
        } else {
            url = this.subURL + profileId + "/statistics";
        }

        return await apiClient.standardGet(url);
    }

    async getTasks(group = "", task = "") {
        let tasks = state.tasks;
        if (!tasks || !tasks.lastCheck || new Date().getTime() - new Date(tasks.lastCheck).getTime() > 60 * 1000) {
            let cont = true;
            if (tasks) {
                let unfinfound = false;
                for (var n in tasks) {
                    if (Array.isArray(tasks[n]) && tasks[n].find(t => t.status != 'finished')) {
                        unfinfound = true; break;
                    }
                }
                if (!unfinfound)
                    cont = false;
            }
            if (cont) {
                const subURL = `${this.subURL}tasks/${group.length > 0 ? group + `${task.length > 0 ? '/' + task : ''}` : ''}`;
                if (state.tasks && !group && !task)
                    state.tasks.lastCheck = new Date().getTime();//set before call to prevent multiple calls
                let result = await apiClient.standardGet(subURL);
                if (result) {
                    for (var n in result) {
                        let group = result[n];
                        if (group && group.length && Array.isArray(group)) {
                            for (var i = 0; i < group.length; i++) {
                                let item = group[i];
                                if (item.id) {
                                    switch (item.id) {
                                        case "community":
                                            item.target = { action: 'firework' };
                                            break;
                                        case "profile":
                                            item.target = { url: '/profile/onboarding' };
                                            break;
                                        case "skilltree_onboarding":
                                            item.target = { url: "/profile/onboarding" };
                                            break;
                                        case "slack":
                                            item.target = { url: "https://join.slack.com/t/fruitpunchai/shared_invite/zt-1fm6014md-O2xdrh5i3DLSETHhHKpsRw", target: "__blank" };
                                            break;
                                        case "skilltree_tutorial":
                                            item.target = { url: "/skilltree/viewer" };
                                            break;
                                        case "bootcamp":
                                            item.target = { url: "/education" };
                                            break;
                                        case "challenge":
                                            item.target = { url: "/challenges" };
                                            break;
                                        case "sign_contract":
                                            item.target = { url: "/activity/" + item.subtitle + "/agreement" };
                                            break;
                                    }
                                }
                            }
                        }
                    }
                }
                result.lastCheck = new Date().getTime();
                tasks = result;
                if (!group.length && !task.length) {
                    state.registerSession('tasks', tasks);
                    let step = tasks.onboarding.find((step, index) => {
                        return step.status.toLowerCase() === "open" || step.status.toLowerCase() === "ongoing";
                    });
                    if (!step) {
                        state.registerStored('onboardingComplete', true);
                    }
                }
            }
        }
        if (!group.length && !task.length) {
            return tasks;
        }
        else {
            if (tasks.id)
                return tasks;
            return tasks[group]?.find(i => i.id == task);
        }
    }
}
